.profile-tabs {
  ul {
    li {
      a {
        &:after {
          bottom: -25px !important;
        }
      }
    }
  }
}

.account-profile-wrapper {
  .action-text {
    font-size: var(--fs-base__four);
    color: var(--unnamed-color-1b62ab);
    text-decoration: underline;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    &.sub-text {
      margin-top: 5px;
    }
  }

  &.verification-modal {
    .modal-content {
      min-height: 300px;

      .formik-form {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        .helper-text {
          text-align: center;
          font-size: var(--fs-base__three);
          letter-spacing: 0;
          color: var(--unnamed-color-00bfa5);
          opacity: 1;
        }
        .form-btns {
          margin-top: 20px;
        }

        .formik-otp-field {
          padding: 20px;
          .otp-input-container {
            display: flex;
            justify-content: space-between;

            .otp-input {
              border-radius: 20%;
              border: 1px solid var(--unnamed-color-707070) !important;
              color: var(--unnamed-color-00bfa5);
              padding: 5px 10px;
              width: 50px !important;
              height: 40px !important;
            }
          }
        }

        .otp-timer-box {
          .otp-timer {
            text-align: center;
            .otp-clock-container {
              color: var(--unnamed-color-1b62ab);
            }
            .otp-resend-btn {
              margin-left: 5px;
              text-decoration: underline;
              cursor: pointer;
              &.disabled {
                text-decoration: none;
                color: var(--unnamed-color-00bfa5);
                pointer-events: none;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-form {
    position: fixed;
    // padding-top: 65px;
    // height: 100vh;
    right: 0;
    top: 70px !important;
    bottom: 0;
    overflow: hidden;
    z-index: 101;
    border-radius: 7px !important;
    box-shadow: -5px 0px 15px #00000033 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    .bfc-modalform {
      position: static !important;
      top: auto !important;
      height: calc(100vh - 65px);
      overflow: hidden !important;
      // height: 100%;
      .bfc-modalform-header {
        position: absolute !important;
        width: 100% !important;
        top: 0 !important;
        background: #ffffff;
        z-index: 1;
      }
      .modalform-body {
        padding-top: 80px !important;
        height: 100% !important;
        overflow: hidden !important;
        overflow-y: auto !important;

        .addanother-farm {
          margin: 0 -55px !important;
          margin-bottom: 15px !important;
          .icon {
            cursor: pointer;
          }
        }
      }

      .react-datepicker-wrapper {
        input {
          height: 45px;
          padding: 15px 10px;
        }
      }
    }
  }
}

.confirm-delete-modal {
  .modal-dialog--header {
    .modal__title {
      color: var(--unnamed-color-ffffff)!important;
      font-weight: 600;
    }
  }
}
