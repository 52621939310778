//=======================* Google Font Mixin Start *=======================

// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
// @mixin font-face($font-weight, $font-style) {
// 	font-family: 'Raleway', sans-serif;
// 	font-weight: $font-weight;
// 	font-style: $font-style;
// 	font-display: swap;
// }

// @mixin Font-Bold {
// 	@include font-face(700, normal);
// }

// @mixin Font-Semibold {
// 	@include font-face(600, normal);
// }

// @mixin Font-Medium {
// 	@include font-face(500, normal);
// }

// @mixin Font-Regular {
// 	@include font-face(400, normal);
// }

// @mixin Font-Light {
// 	@include font-face(300, normal);
// }

.account-profile-wrapper {
  // .sub-section {
  // 	background: lighten(var(--unnamed-color-1b62ab), 30);
  // 	margin-bottom: 20px;
  // }

  .form-group-label {
    font-size: var(--fs-base__three);
    color: var(--unnamed-color-000);
    // @include Font-Semibold;
    // margin-bottom: 6px;
  }

  .action-text {
    font-size: var(--fs-base__five);
    color: var(--unnamed-color-1b62ab);
    text-decoration: underline;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    &.sub-text {
      margin-top: 5px;
    }
  }

  .bfc-tabs {
    padding: 5px 25px;
    &.tabs-primary {
      background: #ffffff !important;
      padding-left: 0 !important;
      // &:after {
      // 	position: absolute;
      // 	bottom: 0;
      // 	left: 0;
      // 	right: 0;
      // 	content: '';
      // 	width: 100%;
      // 	height: 2px;
      // 	background: red;
      // }
      ul {
        list-style: none;
        display: flex;
        // justify-content: space-around;

        li {
          padding: 15px !important;
          a {
            color: var(--unnamed-color-1b62ab);
            padding-bottom: 5px !important;
            font-size: var(--fs-base__two);
            white-space: nowrap;
            &:after {
              bottom: -15px !important;
            }
          }
        }
      }
    }
    &.tabs-secondary {
      background: var(--unnamed-color-ffffff) !important;
      padding-left: 0 !important;
      // &:after {
      // 	position: absolute;
      // 	bottom: 0;
      // 	left: 0;
      // 	right: 0;
      // 	content: '';
      // 	width: 100%;
      // 	height: 2px;
      // 	background: red;
      // }
      ul {
        list-style: none;
        display: flex;
        // justify-content: space-around;

        li {
          padding: 15px !important;
          a {
            color: #0dc41a !important;
            padding-bottom: 5px !important;
            white-space: nowrap;
            font-size: var(--fs-base__two);
            &::after {
              bottom: -15px !important;
              background-color: #0dc41a!important;
            }
          }
        }
      }
    }
  }

  .bfc-form {
    border-radius: unset !important;
    .form-container {
      padding: 15px !important;
      .quality-paramter-wrapper {
        table {
          tr {
            td {
              font-size: var(--fs-base__three) !important;
              color: var(--unnamed-color-707070)!important;
              font-family: var(--unnamed-font-segoeUI) !important;
              font-weight: normal !important;
            }
          }
        }
      }
    }
  }

  .profile-height-body {
    height: calc(100vh - 130px) !important;
    overflow: auto !important;
    margin-top: -5px;
    margin-bottom: 0!important;
  }

  &.verification-modal {
    .modal-content {
      min-height: 300px;

      .form-container {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        .helper-text {
          text-align: center;
          font-size: var(--fs-base__three);
          letter-spacing: 0;
          color: var(--unnamed-color-00bfa5);
          opacity: 1;
        }
        .form-btns {
          margin-top: 20px;
        }

        .formik-otp-field {
          padding: 20px;
          .otp-input-container {
            display: flex;
            justify-content: space-between;

            .otp-input {
              border-radius: 20%;
              border: 1px solid var(--unnamed-color-707070) !important;
              color: var(--unnamed-color-00bfa5);
              padding: 5px 10px;
              width: 50px !important;
              height: 40px !important;
            }
          }
        }

        .otp-timer-box {
          .otp-timer {
            text-align: center;
            .otp-clock-container {
              color: var(--unnamed-color-1b62ab);
            }
            .otp-resend-btn {
              margin-left: 5px;
              text-decoration: underline;
              cursor: pointer;
              &.disabled {
                text-decoration: none;
                color: var(--unnamed-color-00bfa5);
                pointer-events: none;
              }
            }
          }
        }
      }
    }
  }

  .form-container {
    .farm-wrapper {
      .farm-table {
        margin-bottom: 30px;
        .ant-table-cell{
          white-space: break-spaces;
          word-break: break-all;
        }
      }
      .td-iconwrapper {
        .icon-group {
          display: flex;
          justify-content: space-between;
          .icon {
            cursor: pointer;
            color: var(--unnamed-color-1b62ab);
          }
        }
      }
    }
  }
  .add-new-btn-box {
    .add-new-btn {
      border: 1px solid var(--unnamed-color-1b62ab);
      border-radius: 8px;
      font-size: var(--fs-base__two);
      padding: 7px 25px 8px;
      display: inline-block;
      box-shadow: 0px 0px 10px #0000001a;
      font-family: var(--unnamed-font-segoeUI);
      .icon {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .sidebar-form {
    position: fixed;
    // padding-top: 65px;
    // height: 100vh;
    right: 0;
    top: 65px;
    bottom: 0;
    overflow: hidden;
    z-index: 101;
    .bfc-modalform {
      position: static !important;
      top: auto !important;
      height: calc(100vh - 65px);
      overflow: hidden;
      // height: 100%;
      .bfc-modalform-header {
        position: absolute !important;
        width: 100% !important;
        top: 0 !important;
        background: #ffffff;
        z-index: 1;
      }
      .modalform-body {
        padding-top: 80px !important;
        height: 100% !important;
        overflow: hidden !important;
        overflow-y: auto !important;

        .addanother-farm {
          margin: 0 -55px !important;
          margin-bottom: 15px !important;
          .icon {
            cursor: pointer;
          }
        }
      }

      .add-new-btn-box {
        margin-bottom: 20px !important;
      }

      .react-datepicker-wrapper {
        input {
          height: 45px;
          padding: 15px 10px;
        }
      }
    }
  }
}

.confirm-delete-modal {
  .modal-dialog--header {
    .modal__title {
      color: var(--unnamed-color-ffffff)!important;
      font-weight: 600;
      font-size: var(--fs-base__two);
    }
  }
}
//TODO : tmp kept here
.theme-light.ltr-support {
  .bfc-html {
    .bfc-form {
      background-color: var(--unnamed-color-ffffff);
      border-radius: 7px;
      box-shadow: 0px 0px 15px #0000001a;
      .form-container {
        padding: 15px 25px 25px;
        .card {
          .main-cardbody {
            border: 1px solid #b0b0b0;
            border-radius: 0;
            padding: 0;
            .collapse__wrapper {
              margin: 0;
              .collapse__title {
                background-color: #f2f2f2;
                border-bottom: 1px solid #b0b0b0;
                p {
                  font-size: var(--fs-base__two);
                  color: var(--unnamed-color-000);
                  // @include Font-Semibold;
                  font-family: var(--unnamed-font-segoeUI);
                  font-weight: 600;
                  font-style: normal;
                  .mdi-icon {
                    height: 20px;
                    width: 20px;
                    fill: var(--unnamed-color-000);
                    top: calc(50% - 10px);
                  }
                }
              }
              .collapse__content {
                background-color: var(--unnamed-color-ffffff);
                border-bottom: 1px solid #b0b0b0;
                .card {
                  padding-bottom: 0;
                }
              }
              &:nth-last-child(1) {
                .collapse__title {
                  border-bottom: 0;
                }
                .collapse__content {
                  border-top: 1px solid #b0b0b0;
                  border-bottom: 0;
                }
              }
            }
          }
        }
        .btn-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .btn-primary {
          }
          .btn-primary-hallow {
          }
          .btn-link {
          }
        }
      }
    }
  }
}
