.bfc-top-header {
  font-family: var(--unnamed-font-segoeUI);
  min-height: 120px;

  .topbar--navigation {
    min-height: 120px;

    .bfc-topbar-content-wrapper {
      margin-top: 24px;
      min-height: 80px;
    }

    .topbar__logo {
      width: 122px;
      height: 80px;
      margin: auto 0;
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: left;
      background-size: contain;
      display: none;
      background-image: url(../../images/logo/BiofuelCircleLogo_120x80.png);

      @media screen and (min-width: 835px) {
        display: block;
      }
    }

    .bfc-topbar-nav-menu {
      a.topbar__link {
        height: auto;
        font-size: var(--fs-base__twenty-four);
        &:hover {
          p {
            color: var(--unnamed-color-32a836);
          }
        }
        &::before {
          width: 0;
          background: none;
        }
      }
    }
  }
}
