.member-container {
  .button-container {
    .btn {
      border-radius: 4px;
      padding: 6px 12px;
      svg {
        fill: var(--unnamed-color-1b62ab);
        height: 22px;
        width: 22px;
      }
      &:hover,
      &:focus {
        .icon {
          fill: #fff;
        }
      }
    }
  }
  // .ant-table-body {
  //   height: calc(100vh - 295px);
  //   max-height: 100% !important;
  // }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
.MuiDropzoneArea-icon {
  color: var(--unnamed-color-1b62ab) !important;
}
.MuiButton-label {
  color: var(--unnamed-color-1b62ab);
  text-transform: capitalize;
  font-weight: bold;
}
