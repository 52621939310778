.bfc-html {
  .bfc-overview {
    padding: 30px 47px;
    @media (max-width: 1440px) {
      padding: 30px 0px;
    }
    .btn-wrapper {
      margin: 40px 0 20px;
      display: flex;
      justify-content: space-between;
    }
  }
}
