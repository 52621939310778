$color-white: #fff;
$color-black: black;
$bg-blue: #1b62ab;
$text-color: var(--unnamed-color-1b62ab);
$box-border: 3px solid var(--unnamed-color-ccc);
$box-padding: 15px;

$colors: (
  aliceblue-color: #1c62ab,
  correct-icon-color: var(--unnamed-color-ffffff),
  subtitle-color: #b8b8b8,
);

@mixin subscription-block {
  .btn-contact-pricing {
    display: inline-block;
    background-color: $bg-blue;
    color: var(--unnamed-color-ffffff);
    padding: 8px 12px;
    border-radius: 5px;
    border-color: $bg-blue;
    border: none;
    font-size: var(--fs-base__three);
    font-weight: 500;
    line-height: 1.42857143;
    text-align: center;
    margin-bottom: 15px;
  }
  .price-see {
    font-size: var(--fs-base__zero);
    margin-top: 30px;
    color: var(--unnamed-color-1b62ab);
    font-weight: bold;
    text-align: center;
    .price-small {
      color: #b8b8b8;
      font-size: var(--fs-base__three);
      font-weight: 100;
      margin-left: 5px;
    }
  }
  .other_price-see {
    @extend .price-see;
    color: var(--unnamed-color-00bfa5);
    .other_price-small {
      color: var(--unnamed-color-000);
      font-size: var(--fs-base__three);
      font-weight: 100;
    }
  }
  .min-user {
    color: $text-color;
    font-size: var(--fs-base__three);
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
    .min-numbers {
      font-weight: 700;
      font-size: var(--fs-base__zero);
    }
  }
  .list {
    line-height: 25px;
    // padding: 10px 0px 10px 20px;
    li {
      color: $text-color;
    }
    .fa-li {
      color: #4fb635;
    }
    .tooltip_text {
      color: $text-color;
      font-size: var(--fs-base__two);
      font-weight: normal;
      .trueIcons {
        width: 15px;
        height: 15px;
        margin: 0px 5px !important;
      }
    }
    .other_tooltip_text {
      @extend .tooltip_text;
      color: var(--unnamed-color-707070);
    }
    .point-subtitle {
      color: #b8b8b8;
      line-height: 20px;
      font-size: var(--fs-base__three);
      margin-left: 30px;
      margin-top: 0px;
      margin-bottom: 5px;
      width: 100%;
    }
  }
}
// New code sass

// Prathmesh new code
.subscription {
  margin-left: 3%;
  margin-right: 3%;
  .latest-blog-posts {
    padding: 15px;
    .subscriptions-cards {
      // height: calc(100vh - 300px);
      height: 430px;
      border: 1px solid var(--unnamed-color-e7e2e2);
      border-radius: 5px;
      .newPackIcon {
        z-index: 1;
        position: absolute;
        right: -50px;
        width: 100px;
        margin-top: -32px;
      }
      .subscriptions-card-header {
        background-color: transparent;
        .caption {
          padding: 0px !important;
          border-radius: 10px 10px 0px 0px;
          display: flex;
          justify-content: center;
          .icons {
            // height: 3vh !important;
            // margin: 15px 5px;
            height: 4vh !important;
            margin: 10px 0px;
          }
          h4 {
            padding: 10px;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
          }
          .headline {
            color: $bg-blue;
            text-align: center;
            font-size: var(--fs-base__zero);
            font-weight: bold;
          }
          .other_headline {
            @extend .headline;
            font-size: var(--fs-base__zero);
          }
          h4 {
            padding: 10px;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
          }
        }
        .sub-headline {
          background-color: #f2f6f8;
          width: -webkit-fill-available;
          padding: 5px 0px;
          text-align: center;
          font-size: var(--fs-base__two);
          margin-top: 0px;
          color: var(--unnamed-color-afafaf);
          min-height: 50px;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
      .subscriptions-card-body {
        .sub-caption-no-button {
          // min-height: 470px;
          @include subscription-block;
        }
      }
      .subscriptions-card-body {
        .sub-caption {
          // min-height: 470px;
          @include subscription-block;
          .contact-button {
            background: transparent
              linear-gradient(82deg, #f9f0ab 0%, #fcf1b6 17%, #fff8c9 48%, #fff7c2 50%, #fcf3b6 72%, #f3e58f 100%) 0% 0%
              no-repeat padding-box;
            width: auto;
            font-weight: 400;
            margin-top: 25px;
            margin-left: auto;
            margin-right: auto;
            align-self: center;
            margin-bottom: 25px;
            color: var(--unnamed-color-707070);
            &:before,
            &:hover,
            &:focus,
            &:active {
              color: var(--unnamed-color-707070);
              background: transparent
                linear-gradient(82deg, #f9f0ab 0%, #fcf1b6 17%, #fff8c9 48%, #fff7c2 50%, #fcf3b6 72%, #f3e58f 100%) 0%
                0% no-repeat padding-box;
            }
          }
        }
      }
      .subscriptions-card-footer {
        background-color: transparent;
        border-top: 0;
        border-radius: 15px;
        padding: 15px;
        .button-area {
          .btn-biofuel {
            width: 100%;
            background-color: var(--unnamed-color-1b62ab);
            align-self: center;
            color: var(--unnamed-color-ffffff);
            padding: 5px 25px;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            font-family: var(--unnamed-font-segoeUI) !important;
            // background-color: transparent;
            border: 1px solid var(--unnamed-color-ccc);
            // color: var(--unnamed-color-1b62ab);
            &:before,
            &:hover,
            &:focus,
            &:active {
              background-color: var(--unnamed-color-ffffff);
              color: var(--unnamed-color-707070);
            }
          }
          .other_btn-biofuel {
            @extend .btn-biofuel;
            background-color: var(--unnamed-color-00bfa5);
            border: 1px solid var(--unnamed-color-00bfa5);
            &:hover,
            &:focus,
            &:before {
              color: var(--unnamed-color-ffffff);
              background-color: #169584;
            }
          }
          button:focus,
          button:active {
            outline: none;
          }
        }
      }
      &:hover {
        // transform: translateY(0) scale(1.1);
        border: 1px solid var(--unnamed-color-e7e2e2);
        box-shadow: 0px 2px 5px 2px rgba(151, 142, 142, 0.1);
      }
    }
    .single-subscriptions-cards {
      @extend .subscriptions-cards;
      height: auto !important;
    }
  }
}
// End new code

.subscription {
  margin-left: 3%;
  margin-right: 3%;
  .latest-blog-posts {
    padding: 15px;
    .thumbnail {
      min-height: 680px;
      display: block;
      padding: 0px !important;
      line-height: 1.42857143;
      background-color: var(--unnamed-color-ffffff);
      border: 1px solid #ddd;
      border-radius: 10px;
      -webkit-transition: border 0.2s ease-in-out;
      -o-transition: border 0.2s ease-in-out;
      transition: all 0.2s;
      .caption {
        padding: 0px !important;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        justify-content: center;
        .icons {
          // height: 3vh !important;
          // margin: 15px 5px;
          height: 4vh !important;
          margin: 10px 0px;
        }
        h4 {
          padding: 10px;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
        }
        .headline {
          color: $bg-blue;
          text-align: center;
          font-size: var(--fs-base__zero);
          font-weight: bold;
        }
        h4 {
          padding: 10px;
          margin-top: 0px !important;
          margin-bottom: 0px !important;
        }
      }
      .sub-headline {
        background-color: #f2f6f8;
        padding: 5px 0px;
        text-align: center;
        font-size: var(--fs-base__three);
        margin-top: 0px;
        color: var(--unnamed-color-afafaf);
        min-height: 50px;
      }
      .sub-caption-no-button {
        // min-height: 470px;
        @include subscription-block;
      }
      .sub-caption {
        // min-height: 470px;
        @include subscription-block;
        .contact-button {
          background-color: var(--unnamed-color-1b62ab);
          width: auto;
          font-weight: 400;
          margin-top: 25px;
          margin-left: auto;
          margin-right: auto;
          font-size: 1vw;
          align-self: center;
          color: var(--unnamed-color-ffffff);
          &:before {
            background-color: var(--unnamed-color-1b62ab);
          }
          &:hover,
          &:focus,
          &:active {
            color: var(--unnamed-color-ffffff);
          }
        }
      }
      .button-area {
        margin: 15px;
        .btn-biofuel {
          bottom: 35px !important;
          position: absolute !important;
          right: 0;
          left: -15px;
          width: 70%;
          background-color: var(--unnamed-color-1b62ab);
          margin-top: 25px;
          margin-left: auto;
          margin-right: auto;
          align-self: center;
          color: var(--unnamed-color-ffffff);
          padding: 5px 25px;
          font-size: var(--fs-base__two);
          font-weight: 400;
          border-radius: 5px;
          font-family: var(--unnamed-font-segoeUI) !important;
          // background-color: transparent;
          border: 1px solid var(--unnamed-color-ccc);
          // color: var(--unnamed-color-1b62ab);
          &:before {
            background-color: transparent;
          }
          &:hover,
          &:focus,
          &:active {
            color: var(--unnamed-color-ffffff);
          }
        }
        .other_btn-biofuel {
          @extend .btn-biofuel;
          background-color: var(--unnamed-color-00bfa5);
          border: 1px solid var(--unnamed-color-00bfa5);
          &:before {
            background-color: var(--unnamed-color-00bfa5);
          }
        }
        button:focus,
        button:active {
          outline: none;
        }
      }
    }
    .fa-chevron-left:before {
      content: '\f060' !important;
    }
    .tooltip_text .tooltip-hover {
      visibility: hidden;
      width: auto;
      margin: 30px -100px;
      color: var(--unnamed-color-afafaf) !important;
      text-align: left;
      padding: 5px;
      // position: absolute;
      // position: fixed;
      z-index: 999999999999 !important;
      background: #fefefe 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 5px 2px rgba(151, 142, 142, 0.2);
      border: 1px solid #e9e9e9;
      border-radius: 3px;
      opacity: 1;
    }
    .tooltip_list {
      padding: 0px 0px;
      line-height: 15px;
      .trueIcons {
        height: 2vh !important;
        margin: 0px 15px !important;
      }
      .bulletIcons {
        height: 2vh !important;
        margin: 3px 5px !important;
      }
    }
    .tooltip_text:hover .tooltip-hover {
      visibility: visible;
    }
  }
}
.subscription-slider {
  .slick-slider .slick-arrow.slick-prev:before {
    left: -5px;
    font-size: 62px !important;
  }
  .slick-slider .slick-arrow.slick-next:before {
    right: -5px;
    font-size: 62px !important;
  }
  .slick-slide img {
    display: inline-block !important;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 1 !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    color: var(--unnamed-color-707070) !important;
  }
  .slick-slider .slick-arrow.slick-prev {
    background: none;
  }
  .slick-slider .slick-arrow.slick-next {
    left: auto;
    background: none;
  }
}
.vas-title{
  margin-top: -32px;
  font-size: 19px;
  position: relative;
  width: fit-content;
  left: 200;
  // margin-left: 513px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 0 10px;
}

.vas-12-title{
  font-size: 16px;
  letter-spacing: 0.03rem;
  margin-top: 5px;
}

@media only screen and (max-width: 769px) {
  .subscription-body {
    .divide-section {
      display: block !important;
      .package-headline {
        text-align: center;
      }
      .subscription {
        display: block !important;
      }
    }
  }

}
