.bfc-form {
  .form__form-group-field {
    // this will break MT label side to create form
    display: flex;
    align-items: center;

    .extention {
      font-family: var(--unnamed-font-segoeUI);
      font-weight: 700;
      font-style: normal;
      font-display: swap;
      margin-left: 7px;
      min-width: 40px;
      font-size: var(--fs-base__three);
    }

    input::disabled {
      background: #f7f7f7;
    }
  }
}

.stickToBottomStyle {
  height: 70px;
  bottom: 0px;
  overflow: hidden;
  top: auto !important;
}

.commodity-create-edit-form-wrapper {
  .bfc-date-from-wrapper {
    position: relative;
  }

  .datepicker-calendar-icon {
    position: absolute;
    right: 10px;
    pointer-events: none;
  }

  .date-picker--interval {
    display: flex;

    & > div,
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      max-width: 100% !important;
    }
  }
}

.bfc-html .bfc-modalform {
  z-index: 100;
}

.bid-popup-bottom-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.company-info {
  display: flex;
  flex-direction: row;
  padding: 0px 0px 20px 0px;
  .pic {
    width: 70px;
    height: 70px;
    display: block;
    padding-right: 10px;
    .sub-title {
      font: normal normal 600 16px/19px Segoe UI;
      color: var(--unnamed-color-1b62ab);
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    .title {
      font: normal normal 600 30px Segoe UI;
      color: var(--unnamed-color-000);
      padding-top: 5px;
    }
    .dealTitle {
      font: normal normal 600 30px Segoe UI;
      color: var(--unnamed-color-707070);
      padding-top: 5px;
    }
    .transportDealTitle {
      font: normal normal 600 20px Segoe UI;
      color: var(--unnamed-color-707070);
      padding-top: 5px;
    }

    .location {
      font-weight: bold;
      color: var(--unnamed-color-707070);
    }
  }
}

.commodity-deal-list-page {
  .react-grid-Header {
    box-shadow: none !important;
    margin-bottom: 10px;
  }

  .bfc-list-data-grid {
    border-radius: 10px;
  }
}

// .bfc-calenderpage {
//   .calender-weekmonth-compo {
//     .title-wrapper {
//       background-color: #f8f8f8 !important;
//       &.selected {
//         background-color: var(--unnamed-color-1b62ab);
//         .title {
//           color: #fff !important;
//         }
//       }
//       .title {
//         color: var(--unnamed-color-1b62ab);
//       }
//     }
//   }
// }

.timeline {
  .timeline__icon {
    background: transparent !important;
  }
}
