.btn {
  font-size: var(--fs-base__two)!important;
  border-radius: 25px;
  @include Font-Semibold;
  margin: 0;
}
.btn.btn-grey {
  background-color: var(--unnamed-color-ffffff);
  border: 1px solid var(--unnamed-color-afafaf);
  color: var(--unnamed-color-707070);
  &:before {
    background-color: var(--unnamed-color-afafaf);
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--unnamed-color-ffffff);
    border-color: var(--unnamed-color-e7e2e2);
  }
}
.btn.btn-primary {
  background-color: var(--unnamed-color-1b62ab);
  // border: 2px solid #1B62AB;
  &:before {
    background-color: var(--unnamed-color-ffffff);
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--unnamed-color-1b62ab);
    border-color: var(--unnamed-color-1b62ab);
  }
}
.btn.btn-secondary {
  background-color: var(--unnamed-color-ffffff);
  border: 1px solid var(--unnamed-color-1b62ab);
  color: var(--unnamed-color-1b62ab);
  &:before {
    color: var(--unnamed-color-ffffff);
    background-color: var(--unnamed-color-1b62ab);
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--unnamed-color-ffffff)!important;
    // border-color: var(--unnamed-color-1b62ab);
  }
}
.btn.btn-link {
  text-decoration: underline;
  color: var(--unnamed-color-1b62ab);
  &:before {
    background-color: transparent;
  }
  &:hover,
  &:focus,
  &:active {
    color: darken($color: #1b62ab, $amount: 10%);
  }
}
.follow-btn{
  padding: 2px 15px!important;
  font-weight: 100!important;
  font-size: var(--fs-base__four)!important;
  border-radius: 5px!important;
  background-color: var(--unnamed-color-1b62ab)!important;
  color: var(--unnamed-color-ffffff)!important;
}

.one-lin__button {
  padding: 10px!important;
}
.auction-btn{
  width:180px !important;
}
.auction-btn::after {
  content: "Enter Live Market";
}
.auction-btn:hover::after {
   content: "Buy | Sell | Watch";
}
