.bfc-auth {
  .bfc-container {
    .bfc-account {
      .account__wrapper {
        .account__card {
          .bfc-otp-form {
            .form__form-group {
              .bfc-otp-container {
                > div {
                  input {
                    // padding: 20px;
                    width: 33px !important;
                    height: 33px;
                    padding: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
////////////
.theme-light.ltr-support .bfc-html {
  .market-bg {
    // margin-left: -10px;
    // margin-right: -10px;
    margin-top: -5px !important;
    img {
      object-fit: cover;
      // height: calc(100vh - 60px);
      width: 100%;
    }
  }
}

/////////////// remove_this_file.scs
.bfc-form {
  .form__form-group-field {
    // this will break MT label side to create form
    display: flex;
    align-items: center;

    .extention {
      font-family: var(--unnamed-font-segoeUI);
      font-weight: 700;
      font-style: normal;
      font-display: swap;
      margin-left: 7px;
      min-width: 40px;
      font-size: var(--fs-base__three);
    }

    input::disabled {
      background: #f7f7f7;
    }
  }
}

.stickToBottomStyle {
  height: 70px;
  bottom: 0px;
  overflow: hidden;
  top: auto !important;
}

.commodity-create-edit-form-wrapper {
  .bfc-date-from-wrapper {
    position: relative;
  }

  .datepicker-calendar-icon {
    position: absolute;
    right: 10px;
    pointer-events: none;
  }

  .date-picker--interval {
    display: flex;

    & > div,
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      max-width: 100% !important;
    }
  }
}

.theme-light.ltr-support .bfc-html .bfc-modalform {
  z-index: 100;
}

.bid-popup-bottom-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.commodity-deal-list-page {
  .react-grid-Header {
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 10px;
  }

  .bfc-list-data-grid {
    border-radius: 10px;
  }
}

.timeline {
  .timeline__icon {
    background: transparent !important;
  }
}

///////// START : Sidebar css
.bfc-sidebar__profile {
  padding-top: 10px;
  padding-bottom: 10px;
  @media only screen and (min-width: 769px) and (max-width: 1200px){
    margin-top: 70px;
  }
}
.sidebar__block {
  padding: 0;
  margin-bottom: 0;
  > a {
    padding: 0;
  }
}

.sidebar {
  .sidebar__link {
    display: flex;
    align-items: center;
    // padding: 20px;
    // height: 40px;
    // .sidebar__link-icon {
    // }
    .sidebar__link-title {
      font-size: var(--fs-base__three);
      @include mq('p-576', 'min') {
        left: 50px !important;
      }
    }
  }
  &.sidebar--collapse {
    .sidebar__link {
      .sidebar__link-title {
        @include mq('p-576', 'min') {
          left: 70px !important;
        }
      }
    }
  }
}
///////// END : Sidebar css

.bfc-topbar-nav-menu {
  ul {
    li {
      .topbar__link {
        @include mq('t-768', 'min') {
          min-height: 60px;
          align-items: center;
          padding: 9px 10px!important;
        }
      }
      .topbar__link-indent{
        @extend .topbar__link;
      }
    }
  }
}
@media screen and (min-width: 576px) and (max-width: 769px) {
  .sidebar__wrapper{
    padding-top: 70px;
  }
}
  // @media screen and (max-width: 769px) {
  //   .theme-light.ltr-support .bfc-html .market-bg img {
  //     height: auto;
  //     width: 100%;
  //     top: 50%;
  //     position: absolute;
  //   }
  // }
