//changed css in core files
html,
body {
  position: relative;
  height: 100%;
  font-size: var(--fs-base__three);
  color: var(--unnamed-color-707070);
}

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Segoe UI';
    color: var(--unnamed-color-1b62ab);
    -webkit-font-smoothing: antialiased;
    // font-weight: bold;
  }

  p {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  // font-family: 'Raleway', sans-serif;
  background: var(--unnamed-color-fcfcfc) 0% 0% no-repeat padding-box;

  //TODO : review if needs to change directly in them
  // input {
  //   border-radius: 5px;
  //   border: 1px solid #dcdcdc;
  //   color: var(--unnamed-color-707070);
  //   &[disabled] {
  //     // outline: none;
  //     // background: #FCFCFC;

  //     &:focus,
  //     &:active {
  //       outline: none;
  //       border-color: #dcdcdc;
  //     }
  //   }

  //   &:focus,
  //   &:active {
  //     outline: none;
  //     border-color: var(--unnamed-color-707070);
  //   }
  // }
}

.theme-light.ltr-support {
  // .card {
  //   &.card--not-full-height {
  //     height: 430px;
  //   }
  // }

  // .form {
  //   &.form--horizontal {
  //     @media screen and (min-width: 480px) {
  //       .form__form-group-label {
  //         width: 160px; /////
  //       }

  //       .form__form-group-field {
  //         width: calc(100% - 160px); /////
  //       }
  //     }
  //   }
  // }

  .modal__footer {
    justify-content: unset;
  }

  // .wizard__step {
  //   height: 45px;
  //   background: #f4f7f6 0% 0% no-repeat padding-box;
  //   opacity: 1;

  //   p {
  //     font-weight: bold;
  //     color: var(--unnamed-color-1b62ab);
  //   }
  // }

  // .wizard__title {
  //   margin-left: unset;
  //   margin-right: unset;
  // }

  // .container__wrap{
  //   padding-top: unset;
  // }

  .notification {
    min-width: 300px;
  }

  // .date-picker{
  //   .react-datepicker__day--disabled{
  //     cursor: default;
  //     color: #ccc;
  //   }

  //   .react-datepicker__day--selected,
  //   .react-datepicker__day--in-range,
  //   .react-datepicker__time-list-item--selected{
  //     color: #FFF;
  //     font-weight: bold;
  //   }

  //   .react-datepicker__day:hover,
  //   .react-datepicker__time-list-item:hover{
  //     color: var(--unnamed-color-707070);
  //   }
  // }

  .date-picker {
    .react-datepicker__header {
      color: var(--unnamed-color-ffffff)!important;
    }

    .react-datepicker__input-container {
      input {
        width: 100%;
      }
    }

    .react-datepicker__time-list-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .react-datepicker__day--weekend,
    .react-datepicker__day {
      &:hover {
        background-color: var(--unnamed-color-1b62ab);
        border-color: #14497f;
        color: var(--unnamed-color-ffffff);
      }
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      color: var(--unnamed-color-ffffff);

      &:hover {
        color: var(--unnamed-color-707070);
      }
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      cursor: default !important;
      color: #ccc !important;
      // background-color: var(--unnamed-color-ffffff);
      &:hover {
        background-color: transparent !important;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: var(--unnamed-color-1b62ab);
      color: var(--unnamed-color-ffffff);

      &:hover {
        background-color: #14497f !important;
        border-color: #14497f;
        color: var(--unnamed-color-ffffff);
      }
    }

    .bfc-datepicker-day-disabled {
      pointer-events: none !important;
      // background: red;
      cursor: default !important;
      color: var(--unnamed-color-ccc) !important;
      background: transparent !important;
      &:hover,
      &:active,
      &:focus {
        pointer-events: none !important;
        // background: red;
        cursor: default !important;
        color: var(--unnamed-color-ccc) !important;
        background: transparent !important;
      }
    }
  }

  .alert {
    p {
      font-weight: bold;
    }
  }
}

.notification__message {
  // margin-top: 0;
  font-size: var(--fs-base__three);
  font-weight: bold;
}

.date-picker {
  .react-datepicker__header {
    color: var(--unnamed-color-ffffff)!important;
  }

  .react-datepicker__input-container {
    input {
      width: 100%;
    }
  }

  .react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day {
    &:hover {
      background-color: var(--unnamed-color-1b62ab);
      border-color: #14497f;
      color: var(--unnamed-color-ffffff);
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    color: var(--unnamed-color-ffffff);

    &:hover {
      color: var(--unnamed-color-707070);
    }
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default !important;
    color: #ccc !important;
    // background-color: var(--unnamed-color-ffffff);
    &:hover {
      background-color: transparent !important;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: var(--unnamed-color-1b62ab);
    color: var(--unnamed-color-ffffff);

    &:hover {
      background-color: #14497f !important;
      border-color: #14497f;
      color: var(--unnamed-color-ffffff);
    }
  }

  .bfc-datepicker-day-disabled {
    pointer-events: none !important;
    // background: red;
    cursor: default !important;
    color: var(--unnamed-color-ccc) !important;
    background: transparent !important;
    &:hover,
    &:active,
    &:focus {
      pointer-events: none !important;
      // background: red;
      cursor: default !important;
      color: var(--unnamed-color-ccc) !important;
      background: transparent !important;
    }
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: var(--unnamed-color-1b62ab);
    font-weight: bold;
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #cccccc6b;
  }
}

.container__wrap {
  // padding-top: 70px;
  // padding-bottom: 20px;
  padding-top: 60px;
  padding-bottom: 00px;
  padding-left: 240px;
  padding-right: 15px;
  background-color: var(--unnamed-color-fcfcfc);
}

//TODO : relocate

.formik-location-drilldown {
  position: relative;
  .icon-box {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -12px;
    cursor: pointer;
  }
  .cust-dropdown {
    position: relative;
    .dropdown-header {
      height: 45px;
      display: flex;
      align-items: center;
      border: 1px solid #b0b0b0;
      border-radius: 3px;
      padding: 10px 15px;
      color: var(--unnamed-color-000);
      font-size: var(--fs-base__two);
      position: relative;

      svg {
        position: absolute;
        right: 15px;
      }

      .filter-item {
        span {
          position: relative;
          &:not(:last-of-type) {
            padding-right: 15px;
          }
          &:last-of-type {
            &:after {
              display: none;
            }
          }
          &:after {
            content: '/';
            position: absolute;
            right: 4px;
          }
        }

        .close {
          opacity: 1;
          width: 15px;
          margin-left: 10px;
          img {
          }
        }
      }
    }
    .dropdown-body {
      // padding: 20px;
      border: 1px solid #b0b0b0;
      // margin-top: 10px;
      position: absolute;
      width: 100%;
      left: 0;
      background: #fff;
      z-index: 100;
      height: 300px;
      overflow: hidden;
      overflow-y: auto;

      .search {
        position: relative;
        svg {
          position: absolute;
          width: 25px;
          top: 9px;
          right: 10px;
        }
        input {
          height: 45px;
          display: flex;
          align-items: center;
          border: 1px solid #b0b0b0;
          border-radius: 3px;
          padding: 10px 15px;
          // color: var(--unnamed-color-000);
          font-size: var(--fs-base__two);
          padding-right: 42px;
        }
      }

      .back-btn {
        padding: 10px;
        border-bottom: 1px solid #b0b0b0;
        cursor: pointer;
        // color: #b0b0b0;
        .icon {
          color: var(--unnamed-color-1b62ab);
        }
      }

      ul {
        padding: 0;
        li {
          // border-bottom: 1px solid #b0b0b0;
          cursor: pointer;
          padding: 6px 20px;

          &:hover {
            background: #e7e2e2;
          }

          .icon {
            float: right;
            color: var(--unnamed-color-707070);
          }

          a {
            color: var(--unnamed-color-000);
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              display: inline-block;
              width: 6px;
            }
          }
        }
      }
    }
  }
}
