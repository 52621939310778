//=======================* Google Font Mixin Start *=======================

// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@mixin font-face($font-weight, $font-style) {
  font-family: var(--unnamed-font-segoeUI);
  font-weight: $font-weight;
  font-style: $font-style;
  font-display: swap;
}

@mixin Font-Bold {
  @include font-face(700, normal);
}

@mixin Font-Semibold {
  @include font-face(600, normal);
}

@mixin Font-Medium {
  @include font-face(500, normal);
}

@mixin Font-Regular {
  @include font-face(400, normal);
}

@mixin Font-Light {
  @include font-face(300, normal);
}

//==============* Breakpoint mixin *=======================

$breakpoints: (
  'p-320': 320px,
  'p-400': 400px,
  'p-480': 480px,
  'p-576': 576px,
  'p-640': 640px,
  't-768': 768px,
  't-992': 992px,
  't-1025': 1025px,
  'd-1200': 1200px,
  'desktop': 1248px,
  'mac': 1280px,
  'lap-1302': 1302px,
  'd-wide1': 1336px,
  'd-wide': 1440px,
  'd-large': 1680px,
);
@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
//.site-header {
//  @include mq('tablet') {
//    padding: 10px;
//  }
//}
//

//////////
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin rem($property, $values...) {
  $max: length($values);
  $pxValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value/16}rem;

    @if $i < $max {
      $pxValues: #{$pxValues + ' '};
    }
  }

  #{$property}: $pxValues;
}

// body{
//   @include rem(font-size, 18);
// }

.tabs-primary,
.tabs-secondary {
  background-color: #f1f2f3;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    li {
      list-style-type: none;
      padding: 15px 20px 0;
      @include mq('d-large', 'min') {
        padding: 20px 20px 0;
      }

      &::marker {
        display: none;
      }
      a {
        color: var(--unnamed-color-000);
        font-size: var(--fs-base__two);
        @include Font-Medium;
        position: relative;
        padding-bottom: 15px;
        display: inline-block;
        @include mq('d-large', 'min') {
          padding-bottom: 20px;
        }
        &:after {
          height: 3px;
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0%;
          transition: all 0.3s;
        }
        &:hover {
          &:after {
            background-color: var(--unnamed-color-1b62ab);
            width: 100%;
          }
        }
        &.active {
          @include Font-Bold;
          &:after {
            background-color: var(--unnamed-color-1b62ab);
            width: 100%;
          }
        }
      }
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 30px!important;
          width: 70px !important;
          object-fit: cover;
          margin: 0px!important;
        }
      }
    }
  }
  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--unnamed-color-ccc);
    -webkit-border-radius: 5px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 10px;
    background: var(--unnamed-color-ccc);
  }
}

.tabs-primary {
  background: transparent;
  ul {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    li {
      padding: 5px 15px 5px;
      a {
        color: var(--unnamed-color-1b62ab);
        padding-bottom: 5px;
        font-size: var(--fs-base__two)!important;
        white-space: nowrap;
        &:after {
          bottom: -15px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .bfc-tabs {
    .tabs-secondary{
      ul{
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
      }
    }
  }
  .tabs-primary{
    ul{
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
    }
  }
}
// @media screen and (min-width: 769px) {
//   .bfc-tabs {
//     .tabs-secondary{
//       ul{
//         white-space: nowrap!important;
//       }
//     }
//   }
//   .tabs-primary{
//     ul{
//       white-space: nowrap;
//       li{
//         padding: 10px 15px!important;
//       }
//     }
//   }
// }
