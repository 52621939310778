.subscription-cards__box {
    .card{
        .card-body{
            box-shadow: 0px 5px 18px #00000014;
            border: 1px solid transparent;
            border-radius: 10px;
            &:hover{
                transform: scale(1.1);
                transition: all 0.5s ease-out;
                box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                border: 1px solid #00000014;
            }
        }
    }
}