.bfc-html {
  .bfc-form {
    background-color: var(--unnamed-color-ffffff);
    border-radius: 7px;
    box-shadow: 0px 0px 15px #0000001a;
    margin-bottom: 100px;
    .form {
      padding: 15px 25px 25px;
      .card {
        .main-cardbody {
          border: 1px solid #b0b0b0;
          border-radius: 0;
          padding: 0;
          .collapse__wrapper {
            margin: 0;
            .collapse__title {
              background-color: var(--unnamed-color-f2f2f2);
              border-bottom: 1px solid #b0b0b0;
              @include mq('d-large', 'min') {
                padding-top: 20px;
                padding-bottom: 20px;
              }
              p {
                font-size: var(--fs-base__one);
                color: var(--unnamed-color-000);
                @include Font-Semibold;
                .mdi-icon {
                  height: 20px;
                  width: 20px;
                  fill: var(--unnamed-color-000);
                  top: calc(50% - 10px);
                }
              }
            }
            .collapse__content {
              background-color: var(--unnamed-color-ffffff);
              border-bottom: 1px solid #b0b0b0;
              .card {
                padding-bottom: 0;
              }
            }
            &:nth-last-child(1) {
              .collapse__title {
                border-bottom: 0;
              }
              .collapse__content {
                border-top: 1px solid #b0b0b0;
                border-bottom: 0;
              }
            }
          }
        }
      }
      .btn-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    //quality-paramter
    .quality-paramter-wrapper {
      .table {
        border: 1px solid var(--unnamed-color-f2f2f2);
        margin-bottom: 30px;
        tr {
          th {
            font-size: var(--fs-base__two);
            color: var(--unnamed-color-707070);
            @include Font-Medium;
            background-color: var(--unnamed-color-f2f2f2);
            text-align: center;
            vertical-align: middle;
            padding: 15px 10px;
          }
          td {
            font-size: var(--fs-base__two);
            color: var(--unnamed-color-707070);
            // @include Font-Bold;
            text-align: center;
            vertical-align: middle;
            padding: 15px 10px;
            border: 1px solid var(--unnamed-color-f2f2f2);
          }
          &:nth-of-type(odd) {
            background-color: transparent;
          }
          &:nth-of-type(even) {
            background-color: #f8fbff;
          }
        }
      }

      .addnew-row {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
        a {
          display: inline-flex;
          align-items: center;
          font-size: var(--fs-base__two);
          // @include Font-Light;
          .icon {
            background-color: var(--unnamed-color-1b62ab);
            @include Font-Regular;
            font-size: 1.5rem;
            color: var(--unnamed-color-ffffff);
            border-radius: 22px;
            height: 22px;
            width: 22px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
          }
          .text {
            text-decoration: underline;
          }
        }
      }
    }
  }

  ////////
  .table-farms-wrapper {
    .addnew-row {
      text-align: right;
      margin-bottom: 20px;
      a {
        border: 1px solid var(--unnamed-color-1b62ab);
        border-radius: 8px;
        font-size: var(--fs-base__two);
        padding: 7px 25px 8px;
        display: inline-block;
        box-shadow: 0px 0px 10px #0000001a;
        font-family: var(--unnamed-font-segoeUI);
        line-height: 1;
        .icon {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
  .table-farms {
    border: 1px solid #b0b0b0;
    width: 100%;
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    table-layout: fixed !important;
    margin: 0 auto !important;
    table {
      width: 100%;
      border-spacing: 0 !important;
      border-collapse: collapse !important;
      table-layout: fixed !important;
      margin: 0 auto !important;
    }
    .b-0 {
      border: none;
    }
    .p-0 {
      padding: 0;
    }

    .border-all {
      border: 1px solid #b0b0b0;
    }
    .border-r {
      border-right: 1px solid #b0b0b0;
    }
    .border-b {
      border-bottom: 1px solid #b0b0b0;
    }
    .border-l {
      border-left: 1px solid #b0b0b0;
    }
    .border-t {
      border-top: 1px solid #b0b0b0;
    }

    .border-r0 {
      border-right: none;
    }
    .border-b0 {
      border-bottom: none;
    }
    .border-l0 {
      border-left: none;
    }
    .border-t0 {
      border-top: none;
    }
    ////
    thead {
      background-color: var(--unnamed-color-f2f2f2);
      th {
        border: 1px solid #b0b0b0;
        white-space: nowrap;
        vertical-align: middle;
        text-align: center;
        font-family: var(--unnamed-font-segoeUI);
        color: var(--unnamed-color-000);
        font-size: var(--fs-base__two);
      }
      .th-farm {
        width: 20%;
      }
      .th-address {
        width: 30%;
      }
      .th-crops {
        width: 40%;
      }
      .th-action {
        width: 80px;
      }

      ////
      .th-radio {
        width: 60px;
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #b0b0b0;
          vertical-align: top;
          text-align: center;
          font-family: var(--unnamed-font-segoeUI);
          color: var(--unnamed-color-000);
          font-size: var(--fs-base__two);
          &.td-iconwrapper {
            vertical-align: middle;
            a {
              display: inline-block;
              &:not(:last-child) {
                margin-right: 10px;
              }
            }
          }

          &.td-radio {
            text-align: center;
            vertical-align: middle;
            .radio-color-btn {
              padding-left: 19px;
              margin-right: 0;
              min-height: 20px;
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
  .table-bank {
    border: 1px solid #b0b0b0;
    width: 100%;
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    table-layout: fixed !important;
    margin: 0 auto !important;
    table {
      width: 100%;
      border-spacing: 0 !important;
      border-collapse: collapse !important;
      table-layout: fixed !important;
      margin: 0 auto !important;
    }
    .b-0 {
      border: none;
    }
    .p-0 {
      padding: 0;
    }

    .border-all {
      border: 1px solid #b0b0b0;
    }
    .border-r {
      border-right: 1px solid #b0b0b0;
    }
    .border-b {
      border-bottom: 1px solid #b0b0b0;
    }
    .border-l {
      border-left: 1px solid #b0b0b0;
    }
    .border-t {
      border-top: 1px solid #b0b0b0;
    }

    .border-r0 {
      border-right: none;
    }
    .border-b0 {
      border-bottom: none;
    }
    .border-l0 {
      border-left: none;
    }
    .border-t0 {
      border-top: none;
    }
    ////
    thead {
      background-color: var(--unnamed-color-f2f2f2);
      th {
        border: 1px solid #b0b0b0;
        white-space: nowrap;
        vertical-align: middle;
        text-align: center;
        font-family: var(--unnamed-font-segoeUI);
        color: var(--unnamed-color-000);
        font-size: var(--fs-base__two);
      }
      .th-name {
        width: 40%;
      }
      .th-account {
        width: 20%;
      }
      .th-ifsc {
        width: 20%;
      }
      .th-status {
        width: 20%;
      }
      .th-action {
        width: 80px;
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #b0b0b0;
          vertical-align: top;
          text-align: center;
          font-family: var(--unnamed-font-segoeUI);
          color: var(--unnamed-color-000);
          font-size: var(--fs-base__two);
          &.td-iconwrapper {
            vertical-align: middle;
            a {
              display: inline-block;
              &:not(:last-child) {
                margin-right: 10px;
              }
            }
          }

          &.td-radio {
            text-align: center;
            vertical-align: middle;
            .radio-color-btn {
              padding-left: 19px;
              margin-right: 0;
              min-height: 20px;
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
  ///////
  .radiobox-address {
    p {
      font-family: var(--unnamed-font-segoeUI);
      color: var(--unnamed-color-000);
      margin: 0 0 10px 0;
    }
    .radiobox-wrapper {
      .radio-color-btn {
        padding-left: 0;
        padding-right: 25px;
        &:not(:last-child) {
          margin-right: 18px;
        }
        input {
          + .control-indicator {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }

  /////
  .pan-wrapper {
    .dont-pan {
      label {
        color: var(--unnamed-color-000);
        font-family: var(--unnamed-font-segoeUI);
        display: flex;
        align-items: center;
        margin: 0 0 15px 0;
        input {
          height: 13px;
          width: 13px;
          margin-left: 12px;
        }
      }
      .content {
        color: var(--unnamed-color-000);
        font-family: var(--unnamed-font-segoeUI);
        margin: 0 0 5px 0;
      }
      a {
        color: var(--unnamed-color-1b62ab);
        text-decoration: underline;
      }
    }
  }

  ////
  .businesstax-block {
    .radiobox-wrapper {
      .radio-color-btn {
        font-family: var(--unnamed-font-segoeUI);
        color: var(--unnamed-color-000);
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  //////
  .notes-block {
    p {
      margin: 0;
      color: var(--unnamed-color-ccc);
      border-radius: 3px;
      font-size: var(--fs-base__three);
      padding: 5px 15px;
      &.redbg {
        background-color: rgba(255, 0, 0, 0.05);
      }
      &.greenbg {
        background-color: rgba(2, 177, 2, 0.05);
      }
    }
  }

  ///
  .fileupload-wrapper {
    display: flex;
    align-items: center;
    .fileupload-block {
      position: relative;
      .fileupload {
        opacity: 0;
        position: absolute;
        z-index: 1;
        border: 0;
        padding: 0;
        height: 100%;
        width: 100%;
      }
      .fileupload-cust {
        display: flex;
        align-items: center;
        .icon-block {
          margin-right: 7px;
        }
        p {
          margin: 0;
          color: var(--unnamed-color-000);
          font-family: var(--unnamed-font-segoeUI);
        }
      }
    }

    .drag-block {
      > div {
        display: flex;
        align-items: center;
        border: 1px dashed #c0c0c0;
        padding: 7px 15px;
        margin-left: 5px;
        .icon-block {
          margin-right: 10px;
        }
        p {
          margin: 0;
          font-family: var(--unnamed-font-segoeUI);
        }
      }
    }

    .file-name {
      color: var(--unnamed-color-1b62ab);
      text-decoration: underline;
      margin: 0 0 0 20px;
    }
  }
}

//====================* Radio "Color" Button Style Start *=======================
.radio-color-btn {
  margin: 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 33px;
  margin-right: 10px;
  min-height: 24px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    + .control-indicator {
      position: absolute;
      top: 0px;
      left: 0;
      height: 20px;
      width: 20px;
      background: #fff;
      border-radius: 20px;
      border: 1px solid #000000;
      transition: all 0.2s;

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: var(--unnamed-color-000);
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 24px;
        transition: all 0.2s ease;
        opacity: 0;
        transform: scale(0);
      }
    }
    &:checked {
      + .control-indicator {
        border-color: var(--unnamed-color-000);
        &:after {
          transition: all 0.2s ease;
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

//====================* CheckBox "Color" Button Style Start *=======================
.chekbox-color-btn {
  margin: 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 33px;
  margin-right: 10px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    + .control-indicator {
      position: absolute;
      top: 0px;
      left: 0;
      height: 20px;
      width: 20px;
      background: #fff;
      border-radius: 2px;
      border: 1px solid #000000;
      transition: all 0.2s;

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: var(--unnamed-color-000);
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 2px;
        transition: all 0.2s ease;
        opacity: 0;
        transform: scale(0);
      }
    }
    &:checked {
      + .control-indicator {
        border-color: var(--unnamed-color-000);
        &:after {
          transition: all 0.2s ease;
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
