.bfc-data-table {
  .bfc-datatable-filters {
    .filters_item {
      padding: 10px 0;

      .MuiOutlinedInput-input {
        color: var(--unnamed-color-707070)!important;
      }

      .filter-switch {
        text-align: right;
      }

      .filter-search {
        .form__form-group-input-wrap {
          margin: 0;
        }
      }

      .filter-clear-all {
        bottom: -8px;
        position: absolute;
        text-align: right;
        right: 15px;
        font-size: var(--fs-base__three);
      }
    }
  }
}
