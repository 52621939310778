.bfc-tnc-heading {
    text-align: center;
    font-size: 40px;
    color: var(--unnamed-color-707070);
}
.bfc-tnc-headingPoints {
    text-align: left;
    font-size: var(--fs-base__zero);
    font-weight: 200px;
    color: var(--unnamed-color-707070);
}
.bfc-tnc-content {
    font-weight: 200px ;
    font-size: var(--fs-base__three);
    width: 95%;
    margin: auto;
    text-align: left;
    color: var(--unnamed-color-707070);
}
.bfc-tnc-sub-elements {
    text-align: left;
    margin-left: 30px;
    font-size: var(--fs-base__four);
    font-weight: 300px;
    color: var(--unnamed-color-707070);
}