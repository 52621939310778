.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  padding: 20px;
}

.cookie-consent-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.cookie-consent-title {
  font-size: 20px;
  font-weight: 600;
  color: #16191f;
  margin: 0;
}

.cookie-consent-content p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #2d3748;
  flex: 1;
}

.cookie-consent-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
}

.cookie-consent-info {
  color: #1b62ab;
  text-decoration: underline;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-weight: 500;
}

.cookie-consent-info:hover {
  color: #144a8a;
}

.cookie-consent-accept {
  background-color: #1b62ab;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.cookie-consent-accept:hover {
  background-color: #144a8a;
}

@media (max-width: 768px) {
  .cookie-consent-content {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .cookie-consent-buttons {
    width: 100%;
    justify-content: center;
  }
}
