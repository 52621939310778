.theme-light.ltr-support {
  .bfc-html {
    //////Component
    //*********   Overviewblock.jsx   ********
    .bfc-tab-content.overview {
      > .container-fluid {
        .row {
          &:first-child {
            @include mq('lap-1302', 'max') {
              margin: 0 -10px;
            }
            div[class*='col-'] {
              margin-bottom: 25px;
              @include mq('lap-1302', 'max') {
                padding: 0 10px;
              }
            }
          }
        }
      }
      .btn-wrapper {
        margin: 40px 0 20px;
        display: flex;
        justify-content: space-between;

        .btn-link {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .company-info {
        display: flex;
        flex-direction: row;
        padding: 0px 0px 20px 0px;
        .pic {
          padding-right: 10px;
          display: flex;
          flex-direction: column;
          .sub-title {
            font-size: var(--fs-base__two);
            color: var(--unnamed-color-1b62ab);
            margin-bottom: 5px;
            text-align: center;
            @include Font-Semibold;
          }
          img {
            width: 70px;
          }
        }
        //
        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 10px;
          .title {
            font: normal normal 600 30px Segoe UI;
            color: var(--unnamed-color-000);
            padding-top: 5px;
          }
          .dealTitle {
            // font: normal normal 600 30px Segoe UI;
            @include rem(font-size, 23);
            @include rem(line-height, 27);
            font-family: var(--unnamed-font-segoeUI);
            color: var(--unnamed-color-707070);
            padding-top: 5px;
          }

          .location {
            font-weight: bold;
            color: var(--unnamed-color-707070);
          }
        }
      }
      .animate-number {
        font-weight: bold;
        color: var(--unnamed-color-1b62ab);
        font-size: var(--fs-base__two);
        margin-bottom: 4px;
        font-family: var(--unnamed-font-segoeUI);
      }
    }
    .overview-block {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      flex-direction: column;
      height: auto;
      height: 100%;
      .group-trag-color {
        font-size: 12px;
        padding: 2px 5px;
        letter-spacing: 0.8px;
      }
      // .tag-position {
      //   float: right;
      //   position: relative;
      //   top: 4px;
      // }
      .position-to-comm {
        position: relative;
      }
      .commodity-code {
        position: absolute;
        right: 10px;
        top: 0;
      }
      .overview-single {
        background-color: #f8f8f8;
        border-radius: 4px;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        &.bg-color {
          background: -moz-linear-gradient(
            62deg,
            rgba(238, 241, 245, 1) 0%,
            rgba(218, 238, 255, 1) 17%,
            rgba(239, 248, 255, 1) 48%,
            rgba(242, 245, 247, 1) 72%,
            rgba(221, 239, 255, 1) 100%
          );
          background: -webkit-gradient(
            linear,
            left bottom,
            right top,
            color-stop(0%, rgba(238, 241, 245, 1)),
            color-stop(17%, rgba(218, 238, 255, 1)),
            color-stop(48%, rgba(239, 248, 255, 1)),
            color-stop(72%, rgba(242, 245, 247, 1)),
            color-stop(100%, rgba(221, 239, 255, 1))
          );
          background: -webkit-linear-gradient(
            62deg,
            rgba(238, 241, 245, 1) 0%,
            rgba(218, 238, 255, 1) 17%,
            rgba(239, 248, 255, 1) 48%,
            rgba(242, 245, 247, 1) 72%,
            rgba(221, 239, 255, 1) 100%
          );
          background: -o-linear-gradient(
            62deg,
            rgba(238, 241, 245, 1) 0%,
            rgba(218, 238, 255, 1) 17%,
            rgba(239, 248, 255, 1) 48%,
            rgba(242, 245, 247, 1) 72%,
            rgba(221, 239, 255, 1) 100%
          );
          background: -ms-linear-gradient(
            62deg,
            rgba(238, 241, 245, 1) 0%,
            rgba(218, 238, 255, 1) 17%,
            rgba(239, 248, 255, 1) 48%,
            rgba(242, 245, 247, 1) 72%,
            rgba(221, 239, 255, 1) 100%
          );
          background: linear-gradient(
            28deg,
            rgba(238, 241, 245, 1) 0%,
            rgba(218, 238, 255, 1) 17%,
            rgba(239, 248, 255, 1) 48%,
            rgba(242, 245, 247, 1) 72%,
            rgba(221, 239, 255, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#DDEFFF', endColorstr='#EEF1F5',GradientType=0 );
        }
        .top-block {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          min-height: 80px;
          flex: 1;
          align-items: center;
          @include mq('d-large', 'min') {
            // padding: 20px 16px 10px 25px;
            padding: 15px;
          }
          @include mq('lap-1302', 'max') {
            padding: 15px;
          }
          .title-wrapper {
            .title {
              font-size: var(--fs-base__two);
              color: var(--unnamed-color-1b62ab);
              margin-bottom: 4px;
              font-family: var(--unnamed-font-segoeUI);
            }
            .calender-fromto {
              p {
                font-size: var(--fs-base__two);
                color: var(--unnamed-color-1b62ab);
                margin: 0;
                font-family: var(--unnamed-font-segoeUI);
                span {
                  font-size: var(--fs-base__two);
                  color: var(--unnamed-color-ccc);
                  @include Font-Regular;
                }
              }
              table {
                td {
                  &:nth-child(1) {
                    font-size: var(--fs-base__two);
                    color: var(--unnamed-color-ccc);
                    @include Font-Regular;
                  }
                  &:nth-child(2) {
                    font-size: var(--fs-base__two);
                    color: var(--unnamed-color-1b62ab);
                    margin: 0;
                    font-family: var(--unnamed-font-segoeUI);
                    font-weight: 600;
                    line-height: 1;
                  }
                }
              }
            }
            p.content {
              font-size: var(--fs-base__three);
              color: var(--unnamed-color-1b62ab);
              margin: 0;
              letter-spacing: 0.18px;
            }
          }
          .icon-wrapper {
            display: flex;
            justify-content: flex-end;
            svg {
              @include mq('d-large', 'max') {
                max-width: 80%;
              }
            }
          }
        }

        .bottom-block {
          background-color: #f1f5f79e;
          padding: 3px 15px 3px 25px;
          @include mq('d-large', 'min') {
            // padding: 4px 15px 5px 25px;
            padding: 5px 15px;
          }
          @include mq('lap-1302', 'max') {
            // padding: 3px 15px 3px 15px;
            padding: 5px 15px;
          }
          p {
            color: var(--unnamed-color-1b62ab);
            margin: 0;
            letter-spacing: 0.16px;
            font-size: var(--fs-base__two);
          }
        }
        .bottom-block-animated{
          @extend .bottom-block;
          background-color: transparent;
        }
      }
      .overview-single-price {
        @extend .overview-single;
        background: linear-gradient(-45deg, #d8f0f9, #d5fcf3);  
	      background-size: 400% 400%;
        animation: gradient ease 5s;
      }
      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
      
      // @keyframes changeBackgroundColor {
      //   0% {
      //     background-color: rgb(252, 244, 203);
      //   }
      //   50% {
      //     background-color: rgb(234, 225, 173);
      //   }
      //   100% {
      //     background-color: rgb(238, 226, 160);
      //   }
      // }
      .block-offers {
        color: var(--unnamed-color-afafaf);
        font-size: var(--fs-base__three);
        margin-top: 4px;
        padding: 0 7px;
        &.color-text {
          color: #28bdc2;
          display: flex;
          .icon-wrapper {
            display: inline-block;
            margin-right: 4px;
          }
        }
      }

      /////////
      &.overview2 {
        .overview-single {
          .top-block {
            align-items: flex-end;
            .content-wrapper {
              .single-block {
                &:not(:last-child) {
                  margin-bottom: 15px;
                }
                .title {
                  font-size: var(--fs-base__two);
                  color: var(--unnamed-color-1b62ab);
                  margin: 0;
                  line-height: 1.3;
                }
                .details {
                  font-size: var(--fs-base__three);
                  line-height: 1.3;
                  margin: 0;
                  color: var(--unnamed-color-1b62ab);
                  font-family: var(--unnamed-font-segoeUI);
                }
              }
            }
          }
        }
      }
    }
    .note__form-group {
      position: relative;
      .note-alert {
        background-image: linear-gradient(to bottom, #fff, #f9f9f9);
        color: var(--unnamed-color-000);
        padding-left: 61px;
        padding: 10px 55px;
        border: 1px solid var(--unnamed-color-f2f2f2);
        font-size: var(--fs-base__three);
        border-radius: 3px;
        .icon {
          border-color: var(--unnamed-color-f2f2f2);
          background-color: var(--unnamed-color-f2f2f2);
          text-align: center;
          width: 40px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border: 1px solid var(--unnamed-color-f2f2f2);
          padding-top: 15px;
          border-radius: 3px 0px 0px 3px;
          .i {
            color: var(--unnamed-color-ffffff);
            left: 8px;
            margin-top: -10px;
            position: absolute;
            top: 50%;
            height: 20px;
          }
          &::after {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            display: block;
            content: '';
            width: 10px;
            height: 10px;
            border: 1px solid #f1f2f3;
            position: absolute;
            border-left: 0;
            border-bottom: 0;
            top: 50%;
            right: -5px;
            margin-top: -5px;
            background: #f1f2f3;
          }
        }
      }
    }
    //*********   OverviewDetailsSellercompo.jsx   ********
    .overview-details-seller {
      width: 100%;
      .overview-details,
      .overview-sellers {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        .title {
          font-size: var(--fs-base__zero);
          @include Font-Bold;
          color: var(--unnamed-color-000);
          // flex-basis: 200px;
          min-width: 200px;
        }
        .details-wrapper {
          border: 1px solid #c7c7c7;
          border-radius: 4px;
          display: flex;
          overflow: hidden;
          .single-block {
            min-width: 125px;
            .header-block {
              background-color: #f1f5f79e;
              padding: 7px 10px;
              text-align: center;
              img {
                margin-right: 8px;
              }
              span {
                font-size: 1;
                color: var(--unnamed-color-1b62ab);
                @include mq('d-large', 'max') {
                  font-size: var(--fs-base__three);
                }
              }
            }
            .content-block {
              padding: 13px 10px;
              text-align: center;
              p {
                font-size: var(--fs-base__one);
                @include Font-Medium;
                color: var(--unnamed-color-000);
                margin: 0;
              }
            }
            &:not(:last-child) {
              border-right: 1px solid #c7c7c7;
            }
            /////////
            &.withicon {
              min-width: 270px;
              .content-block {
                p {
                  span {
                    display: inline-block;
                    margin-right: 10px;
                    img {
                      max-width: 35px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      //
    }

    .bfc-overview {
      padding: 30px 47px;
      @media (max-width: 1440px) {
        padding: 30px 0px;
      }
      .btn-wrapper {
        margin: 40px 0 20px;
        display: flex;
        justify-content: space-between;

        .btn-link {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.delivery-overview {
  .overview-wrapper {
    .overview-card {
      // box-shadow: rgb(239 237 237) 0px 0px 5px 1px;
      box-shadow: 0px 0px 5px 2px rgba(151, 142, 142, 0.1);
      border-radius: 10px;
      padding: 10px 20px 10px 20px;
      .overview-card__heading {
        font-size: var(--fs-base__zero);
        color: #c3cfda;
        margin-bottom: 10px;
      }
      .delivery-card__heading {
        font-size: var(--fs-base__one);
      }
      .md__responsive {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: initial;
        min-height: 80px;
        .overview__card-icons {
          width: fit-content;
          max-width: 60px;
          margin-left: 5px;
          // padding: 20px 25px 10px 4px;
        }
        .overview__card-icons-buyer {
          width: fit-content;
          max-width: 60px;
          margin-left: 5px;
          // padding: 20px 25px 10px 4px;
          color: red;
        }
        .dc__status {
          font-size: var(--fs-base__two);
          background-color: var(--unnamed-color-f2f2f2);
          width: fit-content;
          padding: 0px 15px;
          border-radius: 5px;
          color: var(--unnamed-color-1b62ab);
        }
        .dc__expired-status {
          font-size: var(--fs-base__two);
          background-color: #ffebe6;
          width: fit-content;
          padding: 0px 15px;
          border-radius: 5px;
          color: #ff3300;
        }
        .dc__title {
          font-weight: bold;
          color: var(--unnamed-color-1b62ab);
          font-size: var(--fs-base__three);
          margin: 0;
        }
        .dc__address {
          color: var(--unnamed-color-707070);
          font-size: var(--fs-base__three);
          margin: 0;
        }
        .dc_start_date {
          font-size: var(--fs-base__three);
          color: var(--unnamed-color-ccc);
          margin-bottom: 0;
        }
        .delivery__title {
          font-size: var(--fs-base__one);
          margin-top: 0px;
        }
        .delivery__address {
          color: var(--unnamed-color-707070) !important;
          min-height: 100px;
        }
        .dc_end_date {
          @extend .dc_start_date;
          .dc__date {
            font-weight: bold;
            color: var(--unnamed-color-1b62ab);
            font-size: var(--fs-base__three);
          }
        }
      }
      .driver_details-section {
        border-left: 1px solid #c0c0c0;
        .driver-text {
          font-size: var(--fs-base__one);
          color: var(--unnamed-color-ccc);
        }
        .vehicle-text {
          @extend .driver-text;
        }
      }
      @media only screen and (max-width: 766px) {
        .driver_details-section {
          border-left: none !important;
        }
        .trans {
          border-bottom: 1px solid var(--unnamed-color-ccc);
          margin-bottom: 20px;
        }
      }
    }
    .form-container {
      .farm-table {
        .table-farms-wrapper {
          .table-label {
            color: var(--unnamed-color-1b62ab);
            font-size: var(--fs-base__two);
            font-weight: bold;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1250px) {
  .theme-light.ltr-support {
    .bfc-html {
      .overview-block {
        .overview-single {
          .top-block {
            .icon-wrapper {
              position: absolute;
              right: 20px;
              bottom: 5px;
              .mdi-icon {
                height: 25px;
              }
            }
          }
        }
      }
    }
  }
}