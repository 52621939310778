:root {
  font-size: var(--fs-base__two);
  // font-size: 100%;
  // @include mq('d-large', 'max') {
  //   font-size: var(--fs-base__three);
  // }
}
@mixin scrollbarLine {
  .scrollbar {
    height: calc(100vh - 115px);
    overflow-y: auto;
    overflow-x: hidden;
    .ant-collapse  {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 0;
          // margin-bottom: 15px;
          font-weight: 900;
          .ant-collapse-arrow {
            right: 0;
            margin-right: 10px;
            position: absolute;
            top: 5px;
          }
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            margin-top: 20px;
            margin-bottom: 10px;
            padding: 0;
            .commodity-filter {
              .ant-checkbox-group {
                width: 100%;
                .ant-checkbox-wrapper {
                  width: 100%;
                  .ant-checkbox + span {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #scrollableDiv::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
    border-radius: 0px 0px 0px 0px;
  }
  #scrollableDiv::-webkit-scrollbar {
    width: 5px;
  }
  #scrollableDiv::-webkit-scrollbar-thumb {
    border-radius: 0px 0px 0px 0px;
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
    background-color: lightgrey;
  }
  #side_filter__scrollable::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
    border-radius: 0px 0px 0px 0px;
  }
  #side_filter__scrollable::-webkit-scrollbar {
    width: 5px;
  }
  #side_filter__scrollable::-webkit-scrollbar-thumb {
    border-radius: 0px 0px 0px 0px;
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
    background-color: lightgrey;
  }
}
@mixin filterData {
  padding: 0px;
  @include scrollbarLine;
  .ant-select{
    width: 100%;
  }
  .label {
    font-weight: 700;
    font-size: var(--fs-base__four);
    color: #000;
    margin-bottom: 20px;
  }
  .ant-slider {
    margin: 5px 6px 10px;
    &:hover {
      .ant-slider-track {
        background-color: #1b62ab;
      }
    }
    .ant-slider-rail {
      background: #D9D9D9;
      height: 3px;
    }
    .ant-slider-track {
      height: 3px;
      top: 5px;
      background-color: #1b62ab;
      border-radius: 2px;
      z-index: 11;
    }
    .ant-slider-step {
      background: #D9D9D9;
      height: 3px;
      .ant-slider-dot {
        display: none;
      }
    }
    .ant-slider-handle {
      width: 9px;
      height: 10px;
      margin-top: -3px;
      background-color: #1b62ab;
      border: solid 2px #1b62ab;

    }
    // .ant-tooltip-open {
    //   display: none;
    // }
  }
  // .verified-filter {
  //   .ant-radio-wrapper {
  //     .ant-radio {
  //       display: none;
  //       .ant-radio-inner {
  //         display: none;
  //       }
  //     }
  //     span {
  //       padding: 0px;
  //     }
  //     .text {
  //       border: 1px solid #979797;
  //       border-radius: 50px;
  //       padding: 3px 15px;
  //     }
  //   }
  // }
  // .grade-filter {
  //   @extend .verified-filter;
  //   .ant-radio-group {
  //     .ant-radio-wrapper {
  //       margin: 0px;
  //       span {
  //         padding: 0px 10px 0px 0px;
  //         .text {
  //           border: 1px solid #979797;
  //           border-radius: 50px;
  //           padding: 3px 15px;
  //         }
  //       }
  //     }
  //   }
  // }
}
@media (max-width: 425px) {
  .data-extract-body {
    padding: 25px !important;
  }
  .left_line_value:before {
    height: 141px !important;
  }
}

.commodity-details-overview-page .MuiChip-outlined {
  cursor: pointer;
  margin: 5px;
}

.theme-light.ltr-support {
  .bfc-html {
    min-height: calc(100vh - 70px);
    background: transparent;
    font-family: var(--unnamed-font-segoeUI) !important;
    font-size: var(--fs-base__three);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include Font-Bold;
    }
    ul {
      list-style: none;
    }
    .multiparty-header {
      padding: 0px !important;
      .bg-lightblue {
        .main-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 0px;
          padding: 15px;
          background-color: #f8fafc;
          .left__side-content {
            column-gap: 45px;
            display: flex;
            flex-wrap: wrap;
            margin-left: 15px;
            .li__header {
              width: 100px;
              margin-bottom: 10px;
            }
          }
          .right__side-content {
            display: flex;
            align-items: center;
            gap: 0px;
            margin-left: auto;
            .blink {
              animation: 1s linear infinite condemned_blink_effect;
            }

            @keyframes condemned_blink_effect {
              0% {
                visibility: hidden;
              }
              25% {
                visibility: hidden;
              }
              50% {
                visibility: hidden;
              }
              100% {
                visibility: visible;
              }
              // 0%{opacity: 0;}
              // 25%{opacity: .1;}
              // 50%{opacity: .1;}
              // 75%{opacity: .5;}
              // 100%{opacity: 1;}
            }
          }
        }
      }
      .multiparty-tabs {
        // background-color: #F8FAFC;
        // box-shadow: 0px 0px 5px #0000001a;
        // box-shadow: 0px -2px 3px rgb(50 50 50 / 5%);
        // padding: 15px;
        .ant-tabs {
          .ant-tabs-nav {
            position: absolute !important;
            right: 100px; // 20px;
            top: 15px; //20px;
            margin-bottom: 0px;
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                background-color: var(--unnamed-color-ffffff);
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding: 5px 10px;
                width: 130px;
                .ant-tabs-tab-btn {
                  line-height: 15px;
                  .text {
                    font-weight: 700;
                    color: var(--unnamed-color-707070);
                  }
                }
              }
              .ant-tabs-tab-active {
                background-color: #fbfcfd;
                border: 1px solid var(--unnamed-color-1b62ab);
                .ant-tabs-tab-btn {
                  .text {
                    color: var(--unnamed-color-1b62ab);
                  }
                }
              }
            }
            .ant-tabs-nav-operations {
              .ant-tabs-nav-more {
                .anticon {
                  transform: rotate(90deg);
                }
              }
            }
          }
        }
        .multiparty-tabs__row {
          height: calc(100vh - 235px);
          .sort__card-side {
            .ant-tag {
              .ant-tag-close-icon {
                background-color: lightgray;
                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                border-radius: 50%;
                padding: 4px;
              }
            }
          }
          .multiparty-tabs-bid__row {
            .ant-collapse {
              background-color: white;
              border: none;
              .col-header {
                border: none;
                margin-bottom: 20px;
                .ant-collapse-header {
                  display: revert;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  box-shadow: 0px 0px 5px #0000001a;
                  background-color: white;
                  padding: 0px;
                  .ant-collapse-arrow {
                    display: none !important;
                  }
                }
                .ant-collapse-content {
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  border: none;
                  box-shadow: 0px 0px 15px #0000001a;
                }
              }
              .hover-col-header {
                border: none;
                margin-bottom: 20px;
                .ant-collapse-header {
                  display: revert;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  box-shadow: 0px 0px 5px #0000001a;
                  background-color: white;
                  padding: 0px;
                  box-shadow: 0px 0px 5px #0000001a;
                  transition: transform 0.6s;
                  // &:hover {
                  box-shadow: 0px 0px 20px #0000001a;
                  transform: scale(1.06);
                  // }
                  .collapse-data-section {
                    // &:hover {
                    background-color: #faf9d9 !important;
                    // }
                  }
                  .ant-collapse-arrow {
                    display: none !important;
                  }
                }
                .ant-collapse-content {
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  border: none;
                  box-shadow: 0px 0px 15px #0000001a;
                }
              }
            }
            .card-middle-part-hover {
              transition: transform 0.6s;
              &:hover {
                box-shadow: 0px 0px 20px #0000001a;
                transform: scale(1.06);
                background: linear-gradient(90deg, #23d5ab, #23a6d5);
                animation: gradientBG 0.5s ease;
                background-size: 400% 400%;
              }
              @keyframes gradientBG {
                0% {
                  background-position: 0% 50%;
                }
                50% {
                  background-position: 100% 50%;
                }
                100% {
                  background-position: 0% 50%;
                }
              }
            }
            .offer-bid-deal__cards {
              .table-responsive {
                box-shadow: 0px 0px 10px #0000001a;
                border-radius: 5px;
                .pink-header__tr {
                  background-color: #fff7f7;
                  position: relative;
                  box-shadow: 0px 0px 10px #0000001a;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
                .green-header__tr {
                  background-color: #f2fff2;
                  @extend .pink-header__tr;
                }
                .collapse-body__tr {
                  background-color: var(--unnamed-color-ffffff);
                }
              }
            }
            .emty-auction__view {
              margin-top: -20px;
              .ant-empty-image {
                height: 80px;
                .ant-empty-img-simple {
                  height: 100px;
                  width: 100px;
                }
              }
              .empty-tag__description {
                font-size: 20px;
                font-weight: 100;
                // font-family: sans-serif;
                color: #afafaf;
              }
            }
          }
          .multiparty-btn__groups {
            .btn-group--justified {
              .sorted-btn {
                height: 50px;
                .sort {
                  font-weight: 100;
                }
                small {
                  font-weight: 700;
                  color: var(--unnamed-color-333);
                }
              }
            }
          }
          .ant-slider {
            .ant-slider-track {
              background-color: var(--unnamed-color-707070);
            }
            .ant-slider-step {
              background-color: #727272;
              .ant-slider-dot {
                border: 1px solid var(--unnamed-color-707070);
                top: 0px;
                width: 4px;
                height: 4px;
                border-radius: 0%;
              }
            }
            .ant-slider-handle {
              box-shadow: 0px 0px 10px #ccc;
              border: 1px solid var(--unnamed-color-707070);
            }
          }
        }
      }
    }
    /* Start Multiparty Media Query */
    @media only screen and (min-width: 1400px) {
      .multiparty-tabs__row {
        .sort__card-side {
          .filter__sort-mobile {
            display: none !important;
          }
          .filter__sort-web {
            display: block !important;
          }
        }
        .customizer {
          display: none;
        }
      }
    }
    @media only screen and (min-width: 1300px) and (max-width: 1399px) {
      .multiparty-header {
        .bg-lightblue {
          .main-content {
            .left__side-content {
              column-gap: 30px;
            }
            .right__side-content {
              gap: 0px;
              padding: 0px 10px;
            }
          }
        }
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
      .multiparty-tabs__row {
        .filter__side {
          display: none !important;
        }
        .sort__card-side {
          max-width: 100%;
          flex-basis: auto;
          .filter__sort-mobile {
            display: block;
          }
          .filter__sort-web {
            display: none !important;
          }
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      .ant-tabs-nav {
        min-width: 0%;
        max-width: 50%;
      }
    }
    @media only screen and (max-width: 1199px) {
      .multiparty-header {
        .multiparty-tabs {
          .multiparty-tabs__row {
            height: calc(100vh - 305px);
          }
        }
        .bg-lightblue {
          margin-top: 50px;
        }
        .ant-tabs-nav {
          margin-top: 50px;
          right: 1% !important;
        }
      }
    }
    @media only screen and (min-width: 776px) and (max-width: 1199px) {
      .multiparty-header {
        // .bg-lightblue{
        //   .main-content{
        //     flex-direction: row-reverse;
        //   }
        // }
        .ant-tabs-nav {
          min-width: 0%;
          max-width: 90%;
        }
      }
    }
    @media only screen and (min-width: 576px) and (max-width: 776px) {
      .ant-tabs-nav {
        min-width: 0%;
        max-width: 75%;
      }
    }
    @media only screen and (max-width: 576px) {
      .ant-tabs-nav {
        min-width: 0%;
        max-width: 90%;
      }
    }
    @media only screen and (min-width: 379px) and (max-width: 699px) {
      .multiparty-header {
        .bg-lightblue {
          .main-content {
            .left__side-content {
              column-gap: 25px;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 378px) {
      .multiparty-header {
        .bg-lightblue {
          .main-content {
            .left__side-content {
              column-gap: 5px;
            }
            .right__side-content {
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
    }
    /* End Multiparty Media Query */

    .bfc-header {
      background-color: var(--unnamed-color-ffffff);
      box-shadow: 0px 0px 15px #0000001a;
      border-radius: 7px;
      // display: flex;
      // justify-content: space-between;
      // margin-bottom: 10px;
      // padding: 10px 40px 10px 10px;
      padding: 10px;
      align-items: center;
      .content-wrapper {
        display: flex;
        .bck-btn {
          display: flex;
          margin-right: 10px;
          align-items: center;
          img {
            width: 35px;
          }
        }
        .title {
          color: var(--unnamed-color-1b62ab);
          // margin-top: 10px;
        }
        .content-block {
          .top-content {
            margin-bottom: 10px;
            .title {
              font-size: var(--fs-base__one);
              span {
                display: inline-block;
                margin-right: 0px !important;
              }
              // @include rem(font-size, 20);
              color: var(--unnamed-color-1b62ab);
              margin-bottom: 0;
              @include mq('d-large', 'min') {
                margin-bottom: 5px;
              }
            }
          }
          .bottom-content {
            display: flex;
            align-items: center;
            .lbl {
              display: inline-flex;
              background-color: #eff9f0;
              font-size: var(--fs-base__three);
              text-transform: uppercase;
              border-radius: 2px;
              padding: 3px 10px;
              margin: 0 25px 0 0;
              @include mq('d-large', 'max') {
                font-size: var(--fs-base__three);
              }
              &.lbl-published {
                color: #599906;
              }
              &.lbl-default {
                color: #b0b0b0
              }
            }

            p {
              font-size: var(--fs-base__two);
              display: flex;
              align-items: center;
              @include mq('d-large', 'max') {
                font-size: var(--fs-base__three);
              }
              color: #b0b0b0;
              margin: 0;
              span {
                display: inline-block;
                margin-right: 0px !important;
                font-size: var(--fs-base__three);
              }
            }
          }
        }
      }
      .right-content-box {
        .discount-right-content {
          display: flex;
          .discount-middle-content {
            display: flex;
            align-items: center;
            .discount-text {
              color: var(--unnamed-color-707070);
            }
            .discount-subtext {
              color: var(--unnamed-color-1b62ab);
            }
          }
          .topbar__collapse {
            .dot-icon {
              height: 30px;
              width: 20px;
            }
            .topbar__collapse-content {
              width: 150px;
              margin-right: 0px;
              margin-top: 10px;
              padding: 10px;
            }
          }
          .button-with-dropdowndown {
            display: flex;
            align-items: center;
          }
        }
        .right-side__btn {
          display: flex;
          justify-content: end;
          // margin-top: 10px;
        }
      }
      //
      .btn-wrapper {
        display: flex;
        align-items: center;
      }

      .bfc-invoice-header {
        display: flex;
        justify-content: space-between;
        // padding: 5px 5px 0px 0px !important;
        margin-bottom: 0;
        .right-content-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .top-content {
          margin-bottom: 0 !important;
        }
        .title {
          display: flex;
          span {
            img {
              width: 25px;
              margin-right: 15px;
            }
          }
          p {
            margin: 0;
            display: flex;
            color: var(--unnamed-color-1b62ab);
            font-size: 1.5rem;
            flex-direction: column;
            .big-text {
              display: inline-block;
              line-height: 1;
              margin: 0 0 5px 0;
            }
            .small-text {
              font-size: var(--fs-base__four);
              display: inline-block;
              line-height: 1;
              margin: 0 0 0 0;
            }
          }
        }

        .print-btn-wrapper {
          .download-btn {
            display: inline-block;
            margin-right: 23px;
            img {
              width: 20px;
            }
          }
          .print-btn {
            display: inline-block;
            margin-right: 30px;
            img {
              width: 20px;
            }
          }
        }

        .close-btn {
          position: relative;
          top: -15px;
          img {
            width: 19px;
          }
        }
      }

      .about-header {
        h5 {
          font-size: var(--fs-base__two);
        }
      }
      .about-body {
        padding: 15px;
        // max-height: 400px;
        // min-height: 400px;
        // overflow-y: scroll;
        .about-info {
          font-size: var(--fs-base__two);
          .codex-editor {
            .codex-editor__redactor {
              padding-bottom: 50px !important;
              margin-right: 0;
              .ce-block {
                .ce-block__content {
                  ul {
                    list-style: circle;
                  }
                }
              }
            }
            .ce-inline-toolbar {
              .ce-inline-toolbar__toggler-and-button-wrapper {
                .ce-inline-toolbar__buttons {
                  .colorPlugin {
                    xy-color-picker {
                      display: contents;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    //

    .form-group-label {
      font-weight: 700;
      font-size: var(--fs-base__two) !important;
    }
    .bfc-tabs {
      background-color: #f1f2f3;
      // box-shadow: 0px 0px 15px #0000002b;
      padding-left: 0px !important;
      @include mq('d-wide', 'max') {
        padding-left: 0;
      }
    }
    //

    .bfc-body {
      background-color: var(--unnamed-color-ffffff);
      box-shadow: 0px 0px 5px #0000002b;
      height: 100%;
      // height: calc(100vh - 130px);
      .ant-table {
        color: 'red';
      }
      .table-row-light {
        background-color: #e6f7ff;
      }
      .table-row-dark {
        background-color: var(--unnamed-color-ffffff);
      }
      .storage-chart {
        min-height: 500px;
        .storage-container {
          // width: 100%;
          // height: 100%;
          width: 100%;
          height: calc(100vh - 210px);
          .storage-card {
            padding-bottom: 0;
            border: 1px solid var(--unnamed-color-ccc);
            box-shadow: 0px 0px 15px #0000001a;
            .storage-card-header {
              color: var(--unnamed-color-51c4c3);
              font-weight: bold;
              background-color: var(--unnamed-color-ffffff);
            }
            .storage-body {
              background-color: var(--unnamed-color-ffffff);
              .exportCSV {
                display: none;
              }
              .custom-icon {
                margin-left: 20px;
              }
            }
          }
        }
      }
      .storage-bin-details {
        min-height: 500px;
        .bin-detail-card {
          padding-bottom: 0;
          border: 1px solid var(--unnamed-color-ccc);
          box-shadow: 0px 0px 15px #0000001a;
          .bin-card-header {
            color: var(--unnamed-color-51c4c3);
            font-weight: bold;
            background-color: var(--unnamed-color-ffffff);
            .divider {
              margin-left: 10px;
            }
            .bin-text {
              margin-left: 15px;
            }
            .bin-text-right {
              margin-left: 15px;
              float: right;
              .right-content-img {
                margin-left: 15px;
                float: right;
                cursor: pointer;
              }
            }
          }
          .bin-card-body {
            background-color: var(--unnamed-color-ffffff);
            .exportCSV {
              display: none;
            }
            .custom-icon {
              margin-left: 20px;
            }
          }
        }
      }
      .bfc-tab-content {
        .tab-content {
          .tab-pane {
            .ant-table {
              .ant-table {
                .ant-table-container {
                  .ant-table-body {
                    overflow: auto scroll;
                    max-height: calc(100vh - 295px);
                    min-height: calc(100vh - 295px);
                    .ant-table-tbody {
                      .ant-table-row:nth-child(2) {
                        position: sticky;
                        top: 0px;
                        z-index: 11;
                        .ant-table-cell,
                        .ant-table-cell:nth-child(2),
                        .ant-table-cell:nth-child(3) {
                          background-color: white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // .notification-profile{
      //   .profile__card{
      //     position: absolute;
      //     .profile__activity{
      //       position: relative;
      //     }
      //   }
      // }
      .filter-section {
        // .ant-select{
        //   width: 100%;
        // }
        // .label {
        //   font-weight: 100;
        //   font-size: var(--fs-base__one);
        //   color: #000;
        //   margin-bottom: 20px;
        // }
        // .ant-slider {
        //   margin: 5px 6px 10px;
        //   &:hover {
        //     .ant-slider-track {
        //       background-color: #1b62ab;
        //     }
        //   }
        //   .ant-slider-rail {
        //     background: #D9D9D9;
        //     height: 3px;
        //   }
        //   .ant-slider-track {
        //     height: 2px;
        //     top: 5px;
        //     background-color: #1b62ab;
        //     border-radius: 2px;
        //     z-index: 11;
        //   }
        //   .ant-slider-step {
        //     background: #D9D9D9;
        //     height: 3px;
        //     .ant-slider-dot {
        //       display: none;
        //     }
        //   }
        //   .ant-slider-handle {
        //     width: 9px;
        //     height: 10px;
        //     margin-top: -3px;
        //     background-color: #1b62ab;
        //     border: solid 2px #1b62ab;

        //   }
        //   // .ant-tooltip-open {
        //   //   display: none;
        //   // }
        // }
        // .verified-filter {
        //   .ant-radio-wrapper {
        //     .ant-radio {
        //       display: none;
        //       .ant-radio-inner {
        //         display: none;
        //       }
        //     }
        //     span {
        //       padding: 0px;
        //     }
        //     .text {
        //       border: 1px solid #979797;
        //       border-radius: 50px;
        //       padding: 3px 15px;
        //     }
        //   }
        // }
        // .grade-filter {
        //   @extend .verified-filter;
        //   .ant-radio-group {
        //     .ant-radio-wrapper {
        //       margin: 0px;
        //       span {
        //         padding: 0px 10px 0px 0px;
        //         .text {
        //           border: 1px solid #979797;
        //           border-radius: 50px;
        //           padding: 3px 15px;
        //         }
        //       }
        //     }
        //   }
        // }
      }
      .filter-slider-section {
        .slider-filter-1 {
          .slick-slider {
            margin-bottom: 0px;
            .slick-dots {
              display: none!important;
            }
            .slick-list {
              .slick-track {
                transform:  translate3d(0px, 0px, 0px);;
                .slick-slide {
                  .ant-btn {
                    .commodity-icon {
                      height: 30px!important;
                      // width: 30px!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .left-filter__Section {
        .card {
          // position: fixed;
          // width: 320px;
          border-right: 1px solid lightgrey;
          border-radius: 0;
          height: 100vh;
          .card-body {
            @include filterData;
            // .scrollbar {
            //   .ant-collapse  {
            //     .ant-collapse-item {
            //       .ant-collapse-header {
            //         padding: 0;
            //         // margin-bottom: 15px;
            //         font-weight: 900;
            //         .ant-collapse-arrow {
            //           right: 0;
            //           position: absolute;
            //           top: 5px;
            //         }
            //       }
            //       .ant-collapse-content {
            //         .ant-collapse-content-box {
            //           margin-top: 20px;
            //           margin-bottom: 10px;
            //           padding: 0;
            //           .commodity-filter {
            //             .ant-checkbox-group {
            //               width: 100%;
            //               .ant-checkbox-wrapper {
            //                 width: 100%;
            //                 .ant-checkbox + span {
            //                   display: flex;
            //                   justify-content: space-between;
            //                   width: 100%;
            //                 }
            //               }
            //             }
            //           }
            //         }
            //       }
            //     }
            //   }
            // }
            // padding: 0px;
            // .ant-select{
            //   width: 100%;
            // }
            // .label {
            //   font-weight: 700;
            //   font-size: var(--fs-base__four);
            //   color: #000;
            //   margin-bottom: 20px;
            // }
            // .ant-slider {
            //   margin: 5px 6px 10px;
            //   &:hover {
            //     .ant-slider-track {
            //       background-color: #1b62ab;
            //     }
            //   }
            //   .ant-slider-rail {
            //     background: #D9D9D9;
            //     height: 3px;
            //   }
            //   .ant-slider-track {
            //     height: 2px;
            //     top: 5px;
            //     background-color: #1b62ab;
            //     border-radius: 2px;
            //     z-index: 11;
            //   }
            //   .ant-slider-step {
            //     background: #D9D9D9;
            //     height: 3px;
            //     .ant-slider-dot {
            //       display: none;
            //     }
            //   }
            //   .ant-slider-handle {
            //     width: 9px;
            //     height: 10px;
            //     margin-top: -3px;
            //     background-color: #1b62ab;
            //     border: solid 2px #1b62ab;

            //   }
            //   // .ant-tooltip-open {
            //   //   display: none;
            //   // }
            // }
            // .verified-filter {
            //   .ant-radio-wrapper {
            //     .ant-radio {
            //       display: none;
            //       .ant-radio-inner {
            //         display: none;
            //       }
            //     }
            //     span {
            //       padding: 0px;
            //     }
            //     .text {
            //       border: 1px solid #979797;
            //       border-radius: 50px;
            //       padding: 3px 15px;
            //     }
            //   }
            // }
            // .grade-filter {
            //   @extend .verified-filter;
            //   .ant-radio-group {
            //     .ant-radio-wrapper {
            //       margin: 0px;
            //       span {
            //         padding: 0px 10px 0px 0px;
            //         .text {
            //           border: 1px solid #979797;
            //           border-radius: 50px;
            //           padding: 3px 15px;
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
      }
      .filter-customizer {
        .customizer__wrap {
          .customizer__title-wrap {
            .filter-body {
              @include filterData;
              .middle-part {
                @include scrollbarLine;
              }
            }
          }
        }
      }
      .right-discovery-section {
        // padding: 30px;
        @include scrollbarLine;
        // .scrollbar {
        //   height: calc(100vh - 180px);
        //   overflow-y: auto;
        //   overflow-x: hidden;
        // }
        .infinite-scroll-component__outerdiv {
          .infinite-scroll-component {
            height: 100%!important;
            overflow: hidden!important;
          }
        }
        .top-filters-headers {
          display: flex;
          padding: 15px;
          height: 58px;
          .flex-grow-1 {
            border-right: 1px solid lightgrey;
             height: inherit;
             margin-right: 15px;
             margin-top: -15px;
             .left-panel {
              display: flex;
              align-items: center;
              margin-top: 15px;
              column-gap: 10px;
             }
            .chip-filters {
              border: 1px solid lightgrey;
              border-radius: 25px;
              padding: 5px 10px;
            }
          }
          .right-panel {
            display: flex;
            align-items: center;
            column-gap: 20px;
            margin-top: 10px;
            .location-section {
              width: 250px;
              .bfc-ref-data-select-wrapper {
                .location-dropdown {
                  width: 250px;
                  .ant-select-selector{
                    border-bottom: 1px solid #d9d9d9!important;
                    border: none;
                    .ant-select-arrow {
                      right: -30px;
                    }
                    .ant-select-clear {
                      right: -30px;
                    }
                  }
                }
              }
            }
          }
        }
        .discovery-card {
          padding-bottom: 0px;
          border: 1px solid lightgrey;
          border-radius: 10px;
          .card-body {
            border-radius: 10px;
            .ul-top {
              display: flex;
              align-items: center;
              list-style-type: none;
              list-style-image: none;
              justify-content: start;
              column-gap: 20px;
              padding-left: 0px;
            }
            .ul-middle {
              li {
                .rating-section {
                  .star-ratings {
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
          .card-footer {
            .connect-btn {
              float: right;
            }
          }
        }
      }
    }
    .all-detail-body {
      height: calc(100vh - 220px) !important;
      overflow: auto;
    }
    .header-body {
      height: calc(100vh - 65px);
      overflow: auto;
    }
    .header-tab-body {
      height: calc(100vh - 200px);
      overflow: auto;
    }
    .history-tab-body {
      .ant-timeline {
        .ant-timeline-item {
          .ant-timeline-item-content {
            top: -15px;
            margin-left: 35px;
          }
        }
      }
    }
    .quality-tab-body {
      height: calc(100vh - 175px);
      overflow: auto;
    }
    .schedule-body {
      height: calc(100vh - 205px);
      overflow: auto;
    }
    .planD-header-tab-body {
      height: calc(100vh - 200px);
      overflow: auto;
    }
    .cargo-details-body {
      height: calc(100vh - 200px) !important;
      overflow-x: hidden;
    }
    .ct_header-tab-body {
      height: calc(100vh - 175px);
      overflow: auto;
    }
    .record-payment-main-body {
      .record-payment-body {
        .rc__table {
          .rc__table-height {
            overflow-y: scroll;
            // overflow-x: hidden;
            height: calc(100vh - 455px);
          }
        }
      }
      // height: calc(100vh - 65px);
      height: 100%;
    }
    .storage-section {
      .bfc-header {
        .list-view-header {
          display: contents;
          .right-content-box {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            .select-location__dropdown {
              margin-left: 20px;
              .ant-select {
                .ant-select-selector {
                  height: 40px;
                  display: flex;
                  align-items: center;
                  border-radius: 5px;
                  border: 1px solid #c4c4c4;
                  .ant-select-selection-item {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
      .storages-body {
        height: calc(100vh - 130px);
        overflow: auto;
      }
    }
    .usersubscription-body {
      height: calc(100vh - 65px);
      overflow: auto;
    }
    .TransportDeal-overview-body {
      height: calc(100vh - 180px);
      overflow: auto;
    }
    // .platform-charges-body {
    //   height: calc(100vh - 210px);
    //   overflow: auto;
    // }
    .create-form-body {
      height: calc(100vh - 125px);
      overflow: auto;
    }
    .data-extract-body {
      height: calc(100vh - 120px);
      overflow: auto;
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb,
      ::-webkit-scrollbar-thumb:horizontal {
        background-color: transparent;
        border-radius: 10px;
      }
    }
    .manual-invoice-body {
      height: calc(100vh - 80px);
      overflow: auto;
    }
    .link-transport-body {
      height: calc(100vh - 65px);
      overflow: hidden;
      padding-right: 17px;
    }

    .link-transport-body:hover {
      overflow-y: auto;
      padding-right: 0;
    }

    .payment-body {
      height: calc(100vh - 150px);
      overflow: auto;
    }
    .follow-preferences-body {
      height: calc(100vh - 150px);
      overflow: auto;
    }
    .left_line:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 103px;
      border-left: 2px solid #4ce1b6;
      background-color: #f1f2f3;
      padding-bottom: 25px;
    }
    .left_line_value:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 119px;
      border-left: 2px solid #4ce1b6;
      padding-bottom: 10px;
    }
    .parent-data-extract {
      .form__form-group {
        padding-left: 20px;
      }
      .left_line_value {
        background-color: #f1f2f3;
        padding-bottom: 10px;
      }
      .left_line {
        background-color: #f1f2f3;
        padding-bottom: 25px;
      }
    }
    .datepicker-extract {
      .ant-picker:hover {
        border-color: '#000';
      }
      // .ant-picker-focused {
      // }
    }

    .data-extract-reset-btn {
      padding: 0 32px !important;
    }

    .recent-reports {
      font-size: 15;
      font-weight: bold;
    }
    .downloadWord {
      color: #00ae11 !important;
    }
    .FailureWord {
      color: #f44336 !important;
    }
    .SubmittedWord {
      color: #1b62ab !important;
    }
    .generatingdWord {
      color: #ecdd22 !important;
    }
    .invoice-pdf-body {
      height: calc(100vh - 120px);
      overflow: auto;
      .invoice-wrapper {
        .viewer {
          iframe {
            height: calc(100vh - 180px) !important;
            width: 100%;
          }
        }
      }
    }
    .invoice-ledger-body {
      height: calc(100vh - 125px);
      overflow: auto;
    }
    .finance-partners-body {
      height: calc(100vh - 125px);
      overflow: auto;
    }
    .finance-row2 {
      height: calc(100vh - 310px) !important;
      overflow: auto;
    }
    .header-body__combined-invoice {
      height: calc(100vh - 150px);
      overflow: auto;
    }
    .quality-profile-body {
      height: calc(100vh - 65px);
      overflow: auto;
    }
    .payment-overview-body {
      height: calc(100vh - 180px);
      overflow: auto;
    }

    .generate-pdf {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }

    .cr-dr-note {
      width: 25px;
      height: 30px;
      margin-right: 20px;
      margin-top: -2px;
    }

    .e-Sign {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }

    .subscription-body {
      height: calc(100vh - 60px);
      overflow: auto;
    }
    @media screen and (max-width: 769px) {
      .record-payment-main-body {
        height: 100vh;
      }
    }
    @media screen and (min-width: 1677px) {
      .record-payment-main-body {
        .record-payment-body {
          .rc__table {
            .rc__table-height {
              overflow-y: scroll;
              // overflow-x: hidden;
              height: calc(100vh - 485px);
            }
          }
        }
      }
      .header-tab-body {
        height: calc(100vh - 215px);
        overflow: auto;
      }
      .quality-tab-body {
        height: calc(100vh - 195px);
        overflow: auto;
      }
      .TransportDeal-overview-body {
        height: calc(100vh - 185px);
        overflow: auto;
      }
      // .platform-charges-body {
      //   height: calc(100vh - 225px);
      //   overflow-y: hidden;
      // }
      .header-body__combined-invoice {
        height: calc(100vh - 150px);
        overflow: auto;
      }
      .payment-overview-body {
        height: calc(100vh - 185px);
        overflow: auto;
      }
      .schedule-body {
        height: calc(100vh - 220px);
        overflow: auto;
      }
    }
  }
  .collapsable-wrapper {
    padding: 15px 15px !important;
  }
}
.delivery-reject-warning span.anticon.anticon-warning {
  font-size: 15px;
  color: #f1b043 !important;
  padding-top: 3px;
  padding-right: 5px;
}
span.anticon.anticon-warning {
  font-size: 21px;
  color: #ff4861 !important;
  cursor: text;
}

.d-flex.align-items-center.grouping-tag span.ant-tag {
  margin-right: 2px;
}
.data-extract-body {
  padding: 25px;
}
@media screen and (min-width: 2199px) {
  .theme-light.ltr-support {
    .bfc-html {
      .bfc-body {
        .storage-chart {
          .storage-container {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1499px) {
  .theme-light.ltr-support {
    .bfc-html {
      .bfc-body {
        .storage-chart {
          .storage-container {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .right-content-box {
    .discount-right-content {
      .discount-middle-content {
        .schems-col {
          padding: 0px 30px;
          .discount-text {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .deliveries-details-page {
    .bfc-header {
      display: block !important;
    }
  }
  .right-content-box {
    .discount-right-content {
      justify-content: space-between;
      .discount-middle-content {
        border: none !important;
        .schems-col {
          padding: 0px 50px;
          .discount-text {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 989px) {
  .filter-customizer {
    visibility: hidden;
  }
}
@media screen and (max-width: 989px) {
  .discount-right-content {
    display: block !important;
    justify-content: space-between;
    .discount-middle-content {
      justify-content: space-between;
      border: none !important;
    }
    .button-with-dropdowndown {
      justify-content: center;
    }
  }
  .theme-light.ltr-support {
    .bfc-html {
      .bfc-body {
        .storage-chart {
          .storage-container {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .right-content-box {
    .discount-right-content {
      justify-content: space-between;
      .discount-middle-content {
        border: none !important;
        .schems-col {
          padding: 0px 50px;
          justify-content: center;
          .discount-text {
            white-space: nowrap;
          }
        }
      }
    }
    .right-side__btn {
      display: flex;
      justify-content: center !important;
      align-items: center;
    }
  }
  .bfc-html {
    // margin-left: 10px;
    // margin-right: 10px;
    .bfc-header {
      .right-content-box {
        // display: block !important;
        line-height: 50px;
      }
    }
    .ant-table {
      overflow: auto;
    }
  }
  .center-btn {
    display: flex;
    justify-content: center;
  }
  .storage-section {
    .bfc-header {
    display: block!important;
      .list-view-header {
        display: block;
        .right-content-box {
          display: block!important;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .bfc-tabs {
    display: block !important;
    .supplier-profile-link {
      margin: 15px;
      text-align: center;
    }
  }
  .filter-section {
    .row {
      .col-12 {
        margin-bottom: 20px;
      }
    }
  }
  .right-discovery-section {
    .top-filters-headers {
      display: flex;
      .flex-grow-1 {
        border-right: none;
        margin-right: 0px;
        margin-top: 0px;
        width: 100%;
        overflow-x: auto;
        display: flex;
        justify-content: unset;
        padding: 0px 15px 0px 15px;
      }
      .right-panel {
        margin-top: 10px;
        column-gap: 20px;
        padding: 10px 15px 15px 15px;
        justify-content: space-between;
        .location-section {
          width: 170px!important;
          .bfc-ref-data-select-wrapper {
            .location-dropdown {
              width: 170px!important;
            }
          }
        }
      }
      .right-panel-filter {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .theme-light.ltr-support {
    .bfc-html {
      .bfc-body {
        .storage-chart {
          .storage-container {
            width: 100%;
            height: calc(100vh - 210px);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  // .ltr-support {
  //   .bfc-html {
  //     padding-left: 10px!important;
  //   }
  // }
  .data-extract-body {
    padding: 25px 0 0 !important;
  }
}
.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 35px !important;
}

@media screen and (min-width: 1200px) and (max-width: 1350px) {
  .right-discovery-section {
    .discovery-card {
      .card-body {
        .ul-top {
          li {
            p {
              font-size: var(--fs-base__four)!important;
            }
            img {
              height: 20px!important;
              width: 20px!important;
            }
          }
        }
        .demo {
          .star-ratings {
            .star-container {
              .widget-svg {
                width: 14px!important;
                height: 14px!important;
              }
            }
          }
        }
      }
    }
  }
}
