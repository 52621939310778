.bfc-balance-position {
  p {
    color: var(--unnamed-color-1b62ab);
    strong {
      padding: 0 5px;
    }
  }
  &__tabs {
    display: flex;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 7px #0000001a;
    .tab {
      cursor: pointer;
      color: var(--unnamed-color-1b62ab);
      background: var(--unnamed-color-ccc) 0% 0% no-repeat padding-box;
      box-shadow: 4px 6px 10px #0000001a;
      border: 1px solid var(--unnamed-color-ccc);
      padding: 10px 40px;
      &:hover,
      &.active {
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      }
      p {
        color: var(--unnamed-color-1b62ab);
        strong {
          padding: 0 5px;
        }
      }
    }

    .switch-pane {
      display: flex;
      align-items: center;
      margin-left: auto;
      padding-right: 30px;
    }
  }

  &__tabs-detail-wrapper {
    th,
    th > p {
      // font-weight: 600;
      // font-family: Raleway;
    }
    td {
      // font-family: Raleway;
    }
  }
}

.no-border-bottom {
  border-bottom: none !important;
}
