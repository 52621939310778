.bfc-auth {
  .bfc-account {
    min-height: auto;
    height: 100%;
    background: #ffffff;
    // width: 400px;
    box-shadow: 5px 8px 12px -2px rgba(151, 142, 142, 0.2);
    .img {
      width: 50%;
    }

    .form input,
    .form textarea {
      border-radius: 20%;
    }

    .account__wrapper {
      margin: 0;
      padding-top: 30px;
    }

    .account__card {
      padding: 0;
      .tabs__wrap {
        .tab-content {
          .tab-pane {
            .account-embed-link {
              background-color: #e4edf5;
              // margin: 20px 20px;
              margin-left: 20px;
              margin-right: 20px;
              text-align: center;
              border: 1px solid var(--unnamed-color-1b62ab);
              border-radius: 5px 20px 5px 20px;
              box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
              .embed-link__content {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 12px;
                .embed-link__video {
                  border-radius: 5px;
                }
                .embed-link__title {
                  margin: 0;
                  color: var(--unnamed-color-1b62ab);
                  font-weight: 100;
                  margin-left: 30px;
                  line-height: 20px;
                  text-align: left;
                }
              }
            }
          }
        }
      }
      .card {
        .card-body {
          .signup-form {
            .MuiFormControl-root {
              width: 100%;
            }
          }
        }
      }
    }

    .bfc-account-tabs {
      &.tabs--bordered-bottom {
        .nav-tabs {
          li .nav-link {
            color: var(--unnamed-color-1b62ab);
            font-size: var(--fs-base__two);
          }

          li .nav-link.active {
            &,
            &:focus,
            &:hover {
              border-bottom: 2px solid var(--unnamed-color-1b62ab);
              font-weight: bold;
              color: var(--unnamed-color-1b62ab);
            }
          }
        }
      }
    }
  }

  .bfc-otp-container {
    margin: 20px 0;
  }

  .bfc-otp-input {
    width: 80% !important;
    border: 1px solid var(--unnamed-color-000) !important;
  }

  .bfc-welcome-card {
    background: var(--unnamed-color-ffffff);
    max-width: 500px;
    overflow: auto;
    height: 100%;
    box-shadow: -2px 4px 10px 0px rgba(151, 142, 142, 0.2);
    // border: 1px solid blue;
    // height: 100%;
    // > {
    //  .row {
    //    height: 100%;
    //    > .col {
    //      flex: 1;
    //    }
    //  }
    // }

    .bfc-welcome-card-header {
      padding: 10px 0px;
      text-align: center;
      // max-width: 300px;
      .bfc-title {
        // height: 35px;
        // padding-top: 30px;
        text-align: center;
        color: var(--unnamed-color-1b62ab);
        opacity: 1;
        font-family: var(--unnamed-font-segoeUI);
        font-weight: bold;
        font-size: 35px;
        line-height: 1.4;
      }
    }

    .bfc-welcome-card-body {
      text-align: center;
      // max-width: 300px;
      padding: 15px;
      min-height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bfc-welcome-card-footer {
      text-align: center;
      padding: 30px 0px;

      .bfc-footer-title {
        text-align: center;
        letter-spacing: 0px;
        color: var(--unnamed-color-1b62ab);
        opacity: 1;
        font-size: var(--fs-base__three);
      }
    }
  }

  .bfc-login-form {
    padding-top: 80px;
  }

  .bfc-otp-form {
    padding-top: 80px;
  }

  .bfc-otpVerification {
    color: var(--unnamed-color-afafaf);
    text-align: center;
    // font: normal normal normal 12px/21px Raleway;
    letter-spacing: 0px;
    opacity: 1;
    font-size: var(--fs-base__two);
  }

  .bfc-login-page {
    color: var(--unnamed-color-afafaf);
    text-align: center;
    // font: normal normal normal 12px/21px Raleway;
    letter-spacing: 0px;
    opacity: 1;
    font-size: var(--fs-base__two);
  }

  .bfc-account__btns {
    justify-content: center;
  }

  .bfc-form__form-group-field {
    justify-content: center;
  }

  .bfc-text_center {
    text-align: center;
  }

  .material-form {
    .bfc-has-error {
      color: var(--unnamed-color-f44336) !important;
      // border : 1px solid #f44336 !important;
      .checkbox-btn__label {
        color: var(--unnamed-color-f44336) !important;
      }
      .checkbox-btn__checkbox-custom {
        border: 1px solid var(--unnamed-color-f44336) !important;
      }
      .bfc-error {
        color: var(--unnamed-color-f44336) !important;
      }

      .bfc-otp-container {
        .bfc-otp-input {
          border-color: var(--unnamed-color-f44336) !important;
        }
      }
      .otp-err-text {
        position: absolute;
        left: 25px;
        bottom: 0;
      }
    }

    // label.Mui-error {
    //  color: var(--unnamed-color-f44336);
    // }

    .material-form__field > div.Mui-error:after {
      border-bottom-color: var(--unnamed-color-f44336);
    }

    .MuiFormHelperText-root.Mui-error {
      color: var(--unnamed-color-f44336) !important;
      font-size: var(--fs-base__four);
      font-family: var(--unnamed-font-segoeUI) !important;
      // position: absolute;//TODO : review
    }
  }

  .bfc-OTPtext {
    width: 100%;
    text-align: center;

    .bfc-OTPResend {
      span.bfc-otp-timer {
        color: var(--unnamed-color-70bbfd);
        text-decoration: none !important;
      }
      span {
        color: var(--unnamed-color-70bbfc);
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
        &.disabled {
          color: var(--unnamed-color-707070);
          pointer-events: none;
        }
      }
    }
  }

  .bfc-lineBreak {
    width: 30%;
    margin-left: 0;
    font-size: large;
    font-weight: bold;
  }

  .bfc-buttonWidth {
    width: 80%;
  }

  .bfc-topbar__search {
    display: flex;
    // margin: auto 0;
    padding: 0;
    // position: relative;
    margin-left: 50px;
    // margin-top: 15px;
    width: 350px;
    height: 35px;
  }

  .bfc-topbar__right {
    display: flex;
  }

  .bfc-navbar {
    height: 60px;
    width: 150%;
    // margin-right: 0px;
    margin-left: 20%;
  }

  .bfc-container {
    border: 1px solid #ffffff;
  }

  .checkbox-btn:hover .checkbox-btn__label {
    color: var(--unnamed-color-707070);
  }

  .bfc-account .account__wrapper {
    width: 100%;
  }

  .card-body {
    padding: 0;
  }

  .checkbox-btn__label {
    font-size: 90%;
  }

  .account__have-account {
    margin-top: 0;
  }

  .card__title h5 {
    color: var(--unnamed-color-000);
    font-size: 120%;
    // margin-top: 10%;
  }

  .material-form .material-form__field {
    margin-bottom: 10px;
  }

  .form__form-group-field {
    width: 85%;
    margin-left: 8%;
  }

  .material-form .material-form__field input,
  .material-form .material-form__field textarea {
    color: var(--unnamed-color-000);
  }

  .material-form {
    .location__field {
      label {
        &[data-shrink='true'] {
          transform: translate(14px, -6px) scale(0.75) !important;
        }
      }
    }
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root {
      // .MuiAutocomplete-noOptions{
      font-size: 12px !important;
      // }
    }
  }

  .bfc-socialFollowImg {
    width: 12%;
    margin: 1%;
    border-radius: 20%;
  }

  .bfc-socialFollowImg1 {
    width: 9%;
    margin: 2%;
    border-radius: 20%;
  }

  .material-form .material-form__field {
    .Mui-disabled {
      color: var(--unnamed-color-1b62ab);
    }
  }

  .bfc-footer {
    bottom: 0 !important;
    top: auto !important;
    left: 0;
    right: 0;
    padding-top: 10px;

    .bfc-footer-content {
      min-height: 60px;
      display: flex;
      // align-items:center;
      justify-content: center;
      text-align: center;
      p {
        color: var(--unnamed-color-00bfa5);
        font-size: var(--fs-base__four);
      }
    }
  }
}

.account-wrapper {
  background: #ffffff;
}

@media screen and (max-width: 769px) {
  .container__wrap {
    padding-right: 0px;
    padding-left: 0px;
  }
  .set-border {
    display: block;
    border: 2px solid #fbfbfb;
    .bfc-login-side {
      .bfc-account {
        .account__wrapper {
          padding-bottom: 30px;
        }
      }
    }
    .bfc-signup-side {
      @extend .bfc-login-side;
    }
  }
  // .bfc-login-side {
  //   display: none;
  // }
  .bfc-welcome-card {
    box-shadow: none !important;
    .bfc-welcome-card-header {
      .bfc-title {
        margin-bottom: 0px;
      }
    }
    .bfc-welcome-card-body {
      .bfc-logo {
        width: 90% !important;
      }
    }
  }
  .bfc-welcome-card-footer-link {
    .link-body {
      background-color: var(--unnamed-color-ffffff);
      // width: max-content;
      text-align: center;
      // width: min-content;
      min-width: 210px;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      font-size: var(--fs-base__one);
      border: 2px solid #ededed;
      border-radius: 10px;
    }
  }
}
// .login-mobile-view{
//   top: 20px;
//   position: absolute;
//   padding-left: 0px;
// }
@media screen and (min-width: 1500px) {
  .bfc-auth {
    .offset-lg-3 {
      margin-left: 15% !important;
    }
    .col-lg-3 {
      flex: 0 0 30% !important;
      max-width: 30% !important;
    }
  }
}
@media screen and (min-width: 1300px) and (max-width: 1499px) {
  // .bfc-auth {
  //   .offset-lg-3{
  //     margin-left: 22%!important;
  //   }
  //   .col-lg-3 {
  //     flex: 0 0 25%!important;
  //     max-width: 25%!important;
  //   }
  // }
  .bfc-auth {
    .offset-lg-3 {
      margin-left: 12% !important;
    }
    .col-lg-3 {
      flex: 0 0 35% !important;
      max-width: 35% !important;
    }
  }
}
@media screen and (min-width: 991px) and (max-width: 1300px) {
  .bfc-auth {
    .offset-lg-3 {
      margin-left: 8% !important;
    }
    .col-lg-3 {
      flex: 0 0 40% !important;
      max-width: 40% !important;
    }
  }
}
@media screen and (min-width: 991px) and (max-width: 1200px) {
  .account-embed-link {
    bottom: 25px !important;
  }
}
@media screen and (min-width: 991px) {
  .account__card {
    padding: 0;
    .tabs__wrap {
      .tab-content {
        .tab-pane {
          .account-embed-link {
            bottom: 15px;
            position: absolute;
            width: 90%;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .account-embed-link {
    margin-top: 15px !important;
  }
}
@media screen and (min-width: 769px) {
  .bfc-welcome-card {
    .bfc-welcome-card-header {
      .bfc-title {
        margin-bottom: 0px;
      }
    }
  }
  .bfc-welcome-card-footer-link {
    display: none;
  }
  .container__wrap {
    padding-bottom: 0px;
    padding-top: 70px;
  }
}

@media screen and (max-width: 769px) {
  .bfc-auth {
    // padding-left: 0;
    top: 70px;
    position: absolute;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .bfc-auth {
    max-width: 90vw !important;
  }
  .container__wrap {
    padding-top: 110px !important;
  }
}
@media screen and (max-width: 576px) {
  .container__wrap {
    // padding-top: 110px!important;
    padding-top: 130px !important;
  }
}
@media screen and (min-width: 321px) and (max-width: 391px) {
  .container__wrap {
    padding-top: 110px !important;
  }
}
@media screen and (max-width: 320px) {
  .container__wrap {
    padding-top: 130px !important;
  }
}
// Prathmesh
.container__wrap {
  padding-top: 60px;
  // padding-right: 0px;
  .bfc-header {
    margin-bottom: 0;
  }
}
.bfc-container__wrap {
  padding-top: 60px;
  padding-left: 250px;
  // padding-bottom: 10px;
}
@media screen and (min-width: 576px) {
  .ltr-support .container__wrap {
    padding-left: 240px;
    padding-right: 0;
  }
  .ltr-support .layout--collapse .container__wrap {
    padding-left: 55px;
    // padding-left: 230px;
  }
}

@media screen and (min-width: 989px) {
  .ltr-support .layout--collapse .container__wrap-socialfeed {
    // padding-left: 55px;
    padding-left: 230px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  // .ltr-support .layout.layout--collapse + .container__wrap {
  //   padding-left: 230px;
  // }
  .container__wrap-socialfeed {
    padding-left: 0px;
  }
}
