.bfc-html {
  .commodity-wrapper {
    padding-left: 10px;
  }
  .bfc-table-list-header {
    .main-links-wrapper {
      ul {
        li {
          a {
            padding: 20px 20px 17px;
          }
        }
      }
    }
    //
    .btn-wrapper {
      > div {
        margin-right: 20px;
      }
      .text-link {
        a {
          font-family: var(--unnamed-font-segoeUI);
        }
      }
      .combobox-container {
        input {
          border: 1px solid var(--unnamed-color-ccc);
          padding: 10px 15px;
        }
      }

      .btn-primary {
        padding: 8px 25px;
      }
    }
  }
  //

  .bfc-list-data-grid {
    .react-grid-Container {
      .react-grid-Toolbar {
        border-color: transparent;
        button {
          font-family: var(--unnamed-font-segoeUI);
          border-color: var(--unnamed-color-4ce1b6);
          letter-spacing: 0.3px;
        }
      }
      //
      .react-grid-Main {
        .react-grid-Grid {
          .react-grid-Header {
            .react-grid-HeaderCell {
              font-family: var(--unnamed-font-segoeUI);
            }
          }
          .react-grid-Viewport {
            .react-grid-Row {
              .react-grid-Cell {
                font-family: var(--unnamed-font-segoeUI);
                button {
                  padding: 5px 15px;
                  font-size: var(--fs-base__three);
                }
                //
                div[title='Published'] {
                  font-size: var(--fs-base__four);
                  color: #599906;
                  background-color: #eff9f0;
                  border-radius: 3px;
                  padding: 3px 6px;
                  text-transform: uppercase;
                }
                div[title='Closed'] {
                  font-size: var(--fs-base__four);
                  color: #720404;
                  background-color: #fdf5f5;
                  border-radius: 3px;
                  padding: 3px 6px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }

    //
    .grid-pagination {
      ul {
        li {
          &.round-btn {
            a {
              span {
                font-size: var(--fs-base__zero);
                position: relative;
                top: -1px;
              }
            }
            &.disabled {
              a {
                span {
                  opacity: 0.5;
                }
              }
            }
          }
          &.page-item {
            a {
            }
            &.active {
              a {
              }
            }
          }
        }
      }
      .grid-pagination-text {
        span {
          color: var(--unnamed-color-ccc);
        }
      }
    }
  }
}
