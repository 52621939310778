//=======================* Google Font Mixin Start *=======================

// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@mixin font-face($font-weight, $font-style) {
  font-family: var(--unnamed-font-segoeUI);
  font-weight: $font-weight;
  font-style: $font-style;
  font-display: swap;
}

@mixin Font-Bold {
  @include font-face(700, normal);
}

@mixin Font-Semibold {
  @include font-face(600, normal);
}

@mixin Font-Medium {
  @include font-face(500, normal);
}

@mixin Font-Regular {
  @include font-face(400, normal);
}

@mixin Font-Light {
  @include font-face(300, normal);
}

.r-bold {
  font-family: var(--unnamed-font-segoeUI) !important;
  font-weight: 700 !important;
}
.r-semibold {
  font-family: var(--unnamed-font-segoeUI) !important;
  font-weight: 600 !important;
}
.r-medium {
  font-family: var(--unnamed-font-segoeUI) !important;
  font-weight: 500 !important;
}
.r-regular {
  font-family: var(--unnamed-font-segoeUI) !important;
  font-weight: 400 !important;
}
.r-light {
  font-family: var(--unnamed-font-segoeUI) !important;
  font-weight: 300 !important;
}
/////////////
.n-bold {
  font-family: var(--unnamed-font-segoeUI) !important;
}
.n-boldi {
  font-family: var(--unnamed-font-segoeUI) !important;
}
.n-regular {
  font-family: var(--unnamed-font-segoeUI) !important;
}

//==============* Breakpoint mixin *=======================

$breakpoints: (
  'p-320': 320px,
  'p-400': 400px,
  'p-480': 480px,
  'p-576': 576px,
  'p-640': 640px,
  't-768': 768px,
  't-992': 992px,
  't-1025': 1025px,
  'd-1200': 1200px,
  'desktop': 1248px,
  'mac': 1280px,
  'lap-1302': 1302px,
  'd-wide1': 1336px,
  'd-wide': 1440px,
  'd-large': 1680px,
);
@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
//.site-header {
//  @include mq('tablet') {
//    padding: 10px;
//  }
//}
//

//////////
@function strip-unit($num) {
  @return calc($num / ($num * 0 + 1));
}

@mixin rem($property, $values...) {
  $max: length($values);
  $pxValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + calc($value/16)}rem;

    @if $i < $max {
      $pxValues: #{$pxValues + ' '};
    }
  }

  #{$property}: $pxValues;
}