.theme-light.ltr-support {
  .bfc-html {
    .bfc-calenderpage {
      position: relative;
      .week-wapper {
        display: flex;
        .week-view {
          display: flex;
          align-items: flex-start;
          // flex: 70% 0 0;
          // border-right: 1px solid #cbcbcb;
          padding-right: 10px;
          flex-wrap: wrap;
          @include mq('d-wide1', 'max') {
            flex: 66.66% 0 0;
          }
          .calender-weekmonth-compo {
            margin: 0 10px;
            width: calc(33.33% - 20px);
            margin-bottom: 20px;
            @include mq('d-wide', 'max') {
              width: calc(50% - 20px);
            }
          }
        }
        .week-details {
          flex: 30% 0 0;
          padding-left: 20px;
          @include mq('d-wide1', 'max') {
            flex: 33.33% 0 0;
          }
        }
      }
      ///////////

      .month-wapper {
        display: flex;
        align-items: flex-start;
        padding: 20px 0;

        & > div {
          flex: 1;
        }

        &::before {
          content: '';
          // border: 1px solid #cbcbcb;
          position: absolute;
          top: 20px;
          bottom: 20px;
          left: 33.33%;
        }

        &::after {
          content: '';
          // border: 1px solid #cbcbcb;
          position: absolute;
          top: 20px;
          bottom: 20px;
          right: 33.33%;
        }

        .year-view {
          display: flex;
          flex-wrap: wrap;
          // // flex: 40% 0 0;
          // border-right: 1px solid #cbcbcb;
          // padding-right: 10px;
          // flex-wrap: wrap;
          padding: 0 20px;
          // @include mq('d-wide1', 'max') {
          //   flex: 33.33% 0 0;
          // }
          border-right: 2px solid #cbcbcb;

          .month-box {
            // padding: 10px;
            padding: 0;
            width: 50%;
            flex-basis: 50%;

            flex-grow: 1;
            @include mq('d-wide1', 'max') {
              width: 100%;
              flex-basis: 100%;
              padding: 5px;
            }
            &:nth-child(odd) {
              padding: 0 8px 8px 0;
            }
            &:nth-child(even) {
              padding: 0 0 8px 0;
            }
            &:last-child {
              padding: 0 0 8px 0;;
            }
          }

          .calender-weekmonth-compo {
            // margin: 0 10px;
            // width: calc(50% - 20px);
            // margin-bottom: 20px;
            // width: 100%;
            // @include mq('d-wide1', 'max') {
            //   width: calc(100% - 20px);
            // }
          }
        }
        .month-view {
          // flex: 30% 0 0;
          // border-right: 1px solid #cbcbcb;
          padding: 0 20px;
          // @include mq('d-wide1', 'max') {
          //   flex: 33.33% 0 0;
          // }
          border-right: 2px solid #cbcbcb;
        }
        .month-details {
          // flex: 30% 0 0;
          padding: 0 20px;
          // @include mq('d-wide1', 'max') {
          //   flex: 33.33% 0 0;
          // }
        }
      }
    }
    /////Component
    .calender-weekmonth-compo {
      overflow: hidden;
      // border: 1px solid #C7C7C7;
      border: 1px solid var(--unnamed-color-1b62ab);
      box-shadow: 0px 0px 8px #00000029;
      border-radius: 8px;
      background-color: var(--unnamed-color-ffffff);
      &.deliverd-bg {
        background-color: #f8f8f8;
      }
      &.selected {
        .title-wrapper {
          background-color: var(--unnamed-color-1b62ab);
          .title {
            color: var(--unnamed-color-ffffff);
          }
        }
      }
      .title-wrapper {
        // background-color: var(--unnamed-color-1b62ab);
        border-bottom: 1px solid var(--unnamed-color-1b62ab);
        background-color: var(--unnamed-color-ffffff);
        padding: 10px 15px;
        text-align: center;
        .title {
          // color: var(--unnamed-color-ffffff);
          color: var(--unnamed-color-1b62ab);
          font-size: var(--fs-base__two);
          font-family: var(--unnamed-font-segoeUI);
        }
      }
      //
      .compo-body {
        padding: 15px 5px 15px 10px;
        // padding-left: 15px;
        // padding-right: 5px;
        > div {
          display: flex;
          &:not(:last-child) {
            margin-bottom: 13px;
          }
          .icon {
            margin-right: 10px;
            img {
              max-width: 20px;
            }
          }
          .title-text {
            font-size: var(--fs-base__two);
            color: var(--unnamed-color-000);
            min-width: 70px;
            @include mq('d-large', 'max') {
              min-width: 63px;
            }
          }
          .content {
            font-size: var(--fs-base__two);
            font-family: var(--unnamed-font-segoeUI);
            color: var(--unnamed-color-000);
            flex: 1;
            // text-align: right;
            .right-side {
              max-width: 80px;
              margin-right: 2px;
            }
          }
        }

        ///////
        .date {
          .title-text {
            display: flex;
            flex-direction: column;
            span {
              &:not(:last-child) {
                margin-bottom: 4px;
              }
            }
          }
          .content {
            display: flex;
            flex-direction: column;
            span {
              line-height: 1.3;
              &:not(:last-child) {
                margin-bottom: 4px;
              }
            }
          }
        }
        .status {
          .content {
            color: #0094ca;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              &.delivered {
                color: #1bab20;
              }
              &.inprogess {
                color: #ff980e;
              }
              &.pending,
              &.planned {
                color: #ff0e0e;
              }
            }
          }
        }
      }
      .view-wrapper {
        background-color: var(--unnamed-color-ffffff);
        border-top: 1px solid var(--unnamed-color-1b62ab);
        a {
          font-size: var(--fs-base__two);
          color: var(--unnamed-color-000);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 15px;
        }
      }
    }

    ////////
    .weekmonth-wrapper {
      .weekmonth-detailscompo {
        overflow: hidden;
        border: 1px solid var(--unnamed-color-1b62ab);
        box-shadow: 0px 0px 8px #00000029;
        border-radius: 8px;
        background-color: var(--unnamed-color-ffffff);
        &.selected {
          .title-wrapper {
            background-color: var(--unnamed-color-1b62ab);
            .title {
              color: var(--unnamed-color-ffffff);
            }
          }
        }
        .title-wrapper {
          background-color: var(--unnamed-color-ffffff);
          padding: 10px 15px;
          text-align: center;
          justify-content: space-between;
          display: flex;
          border-bottom: 1px solid var(--unnamed-color-1b62ab);
          .title {
            color: var(--unnamed-color-1b62ab);
            font-size: var(--fs-base__two);
            font-family: var(--unnamed-font-segoeUI);
          }
          span {
            img {
              max-width: 20px;
            }
          }
        }

        //
        .compo-body {
          display: flex;
          flex-direction: column;
          .single-block {
            padding: 10px 15px;
            display: flex;
            &:not(:last-child) {
              border-bottom: 1px solid #cbcbcb;
            }
            .date {
              flex: 1;
              display: flex;
              align-items: center;
              p {
                font-size: var(--fs-base__two);
                font-family: var(--unnamed-font-segoeUI);
                color: var(--unnamed-color-000);
              }
            }
            .input-wrapper {
              .form-group {
                margin: 0;
                input {
                  border: 1px solid #b0b0b0;
                  border-radius: 3px;
                  height: 45px;
                  padding: 10px 15px;
                  color: var(--unnamed-color-000);
                  font-size: var(--fs-base__two);
                  width: 80px;
                  border-color: #c7c7c7;
                  @include mq('d-large', 'max') {
                    height: 40px;
                  }
                  &:focus {
                    border-color: var(--unnamed-color-1b62ab);
                  }
                }
                .extention {
                  @include Font-Bold;
                  margin-left: 7px;
                  min-width: 30px;
                  font-size: var(--fs-base__three);
                }
              }
            }
          }
        }
      }
      .total-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #cbcbcb;
        padding: 10px 15px;
        margin-bottom: 30px;
        span {
          font-size: var(--fs-base__two);
          display: inline-flex;
          font-family: var(--unnamed-font-segoeUI);
        }
      }
      .btn-wrapper {
        text-align: center;
      }
    }

    ///////
    .monthdetails-wrapper {
      .month-detailscompo {
        overflow: hidden;
        border: 1px solid var(--unnamed-color-1b62ab);
        box-shadow: 0px 0px 8px #00000029;
        border-radius: 8px;
        background-color: var(--unnamed-color-ffffff);
        &.selected {
          .title-wrapper {
            background-color: var(--unnamed-color-1b62ab);
            .title {
              color: var(--unnamed-color-ffffff);
            }
          }
        }
        .title-wrapper {
          background-color: var(--unnamed-color-ffffff);
          padding: 10px 15px;
          text-align: center;
          border-bottom: 1px solid var(--unnamed-color-1b62ab);
          .title {
            color: var(--unnamed-color-1b62ab);
            font-size: var(--fs-base__two);
            font-family: var(--unnamed-font-segoeUI);
          }
        }

        .compo-body {
          padding: 15px;
          display: flex;
          justify-content: space-between;
          .date {
            p {
              font-size: var(--fs-base__two);
              font-family: var(--unnamed-font-segoeUI);
              color: var(--unnamed-color-000);
              margin: 0;
              &:not(:last-child) {
                margin-bottom: 4px;
              }
              span {
                font-family: var(--unnamed-font-segoeUI);
                display: inline-flex;
                min-width: 45px;
                @include mq('d-large', 'max') {
                  min-width: 40px;
                }
              }
            }
          }
          .quantity,
          .status {
            display: flex;
            .icon {
              margin-right: 10px;
              img {
                max-width: 20px;
              }
            }
            .content {
              font-size: var(--fs-base__two);
              font-family: var(--unnamed-font-segoeUI);
              color: var(--unnamed-color-707070);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          //
          .quantity {
            margin-bottom: 4px;
          }
          //
          .status {
            .content {
              color: #0094ca;
              span {
                &.delivered {
                  color: #1bab20;
                }
                &.inprogess {
                  color: #ff980e;
                }
                &.pending,
                &.planned {
                  color: #ff0e0e;
                }
              }
            }
          }
        }

        //
        .view-wrapper {
          background-color: var(--unnamed-color-ffffff);
          border-top: 1px solid var(--unnamed-color-1b62ab);
          a {
            font-size: var(--fs-base__two);
            color: var(--unnamed-color-707070);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 15px;
          }
        }
      }
      .total-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #cbcbcb;
        padding: 10px 15px;
        margin-bottom: 30px;
        span {
          font-size: var(--fs-base__one);
          display: inline-flex;
          font-family: var(--unnamed-font-segoeUI);
        }
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .bfc-calenderpage{
    .month-wapper{
      .year-view{
        .calender-weekmonth-compo{
          min-width: 250px;
        }
      }
      .month-view{
        .monthdetails-wrapper{
          .month-detailscompo {
            min-width: 250px;
          }
        }
      }
      .month-details{
        .weekmonth-wrapper{
          .weekmonth-detailscompo{
            min-width: 250px;
          }
        }
      }
    }
  }
}
