//utility
.icon-group {
  display: flex;
  justify-content: space-between;
  .icon {
    cursor: pointer;
    color: var(--unnamed-color-1b62ab);
  }
}

.flex-1 {
  flex: 1;
}
.bfc-text-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.bfc-full-width {
  width: 100%;
}

.bfc-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bfc-underline {
  text-decoration: underline;
}

.bfc-modalform {
  position: fixed !important;
  bottom: 0;
  right: 0;
  z-index: 99;
  top: 70px !important;
}

.text-center {
  text-align: center;
}

.text-black__bfc{
  color: var(--unnamed-color-000)!important;
}

.text-blue_bfc{
  color: var(--unnamed-color-1b62ab);
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-static {
  position: static;
}
.bg-white{
  background-color: var(--unnamed-color-ffffff);
}
.br-7 {
  border-radius: 7px;
}
.cursor-pointer{
  cursor: pointer;
}
.float-left {
  text-align: left;
}
.float-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-blue {
  color: var(--unnamed-color-1b62ab);
}
.text-white {
  color: var(--unnamed-color-ffffff);
}
.text-black {
  color: var(--unnamed-color-000);
}
.text-bold{
  font-weight: 600;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.gap-y-1 {
  column-gap: 10px;
}
.gap-y-2 {
  column-gap: 20px;
}
.gap-y-3 {
  column-gap: 30px;
}
.gap-y-4 {
  column-gap: 40px;
}
.gap-y-5 {
  column-gap: 50px;
}
.gap-y-6 {
  column-gap: 60px;
}
.gap-y-7 {
  column-gap: 70px;
}
.gap-y-8 {
  column-gap: 80px;
}
.gap-y-9 {
  column-gap: 90px;
}
.gap-y-10 {
  column-gap: 100px;
}
.gap-x-1 {
  row-gap: 10px;
}
.gap-x-2 {
  row-gap: 20px;
}
.gap-x-3 {
  row-gap: 30px;
}
.gap-x-4 {
  row-gap: 40px;
}
.gap-x-5 {
  row-gap: 50px;
}
.gap-x-6 {
  row-gap: 60px;
}
.gap-x-7 {
  row-gap: 70px;
}
.gap-x-8 {
  row-gap: 80px;
}
.gap-x-9 {
  row-gap: 90px;
}
.gap-x-10 {
  row-gap: 100px;
}
.fw-100{
  font-weight: 100;
}
.fw-200{
  font-weight: 200;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}
.fw-700{
  font-weight: 700;
}
.fw-800{
  font-weight: 800;
}
.fw-900{
  font-weight: 900;
}
.fs-10{
  font-size: .625rem;
}
.fs-12{
  font-size: var(--fs-base__four);
}
.fs-14{
  font-size: var(--fs-base__three);
}
.fs-16{
  font-size: var(--fs-base__two);
}
.fs-18{
  font-size: var(--fs-base__one);
}
.fs-20{
  font-size: var(--fs-base__zero);
}
.h-100{
  height: 100%;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
//padding classes
.p-0 {
  padding: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}

.p-5 {
  padding: 5px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}

.p-10 {
  padding: 10px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pr-10 {
  padding-right: 10px !important;
}

.p-15 {
  padding: 15px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pr-20 {
  padding-right: 20px !important;
}

.p-30 {
  padding: 30px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}

.p-35 {
  padding: 35px !important;
}
.pt-35 {
  padding-top: 35px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
.pl-35 {
  padding-left: 35px !important;
}
.pr-35 {
  padding-right: 35px !important;
}

.p-40 {
  padding: 40px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pr-40 {
  padding-right: 40px !important;
}

.p-45 {
  padding: 45px !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pl-45 {
  padding-left: 45px !important;
}
.pr-45 {
  padding-right: 45px !important;
}

.p-50 {
  padding: 50px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.pr-50 {
  padding-right: 50px !important;
}

// margin custome size
.m-0 {
  margin: 0px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}

.m-5 {
  margin: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}

.m-10 {
  margin: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}

.m-15 {
  margin: 15px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}

.m-20 {
  margin: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}

.m-25 {
  margin: 25px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.mr-25 {
  margin-right: 25px !important;
}

.m-30 {
  margin: 30px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.-mt-30 {
  margin-top: -30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}

.m-35 {
  margin: 35px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.ml-35 {
  margin-left: 35px !important;
}
.mr-35 {
  margin-right: 35px !important;
}

.m-40 {
  margin: 40px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}

.m-45 {
  margin: 45px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.ml-45 {
  margin-left: 45px !important;
}
.mr-45 {
  margin-right: 45px !important;
}

.m-50 {
  margin: 50px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.mr-50 {
  margin-right: 50px !important;
}

.m-55 {
  margin: 55px !important;
}
.mt-55 {
  margin-top: 55px !important;
}
.mb-55 {
  margin-bottom: 55px !important;
}
.ml-55 {
  margin-left: 55px !important;
}
.mr-55 {
  margin-right: 55px !important;
}

.m-60 {
  margin: 60px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.mr-60 {
  margin-right: 60px !important;
}


.m-65 {
  margin: 65px !important;
}
.mt-65 {
  margin-top: 65px !important;
}
.mb-65 {
  margin-bottom: 65px !important;
}
.ml-65 {
  margin-left: 65px !important;
}
.mr-65 {
  margin-right: 65px !important;
}

.m-70 {
  margin: 70px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.mr-70 {
  margin-right: 70px !important;
}

.m-70 {
  margin: 70px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.mr-70 {
  margin-right: 70px !important;
}

.m-70 {
  margin: 70px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.mr-70 {
  margin-right: 70px !important;
}

.m-70 {
  margin: 70px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.mr-70 {
  margin-right: 70px !important;
}

.m-75 {
  margin: 75px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mb-75 {
  margin-bottom: 75px !important;
}
.ml-75 {
  margin-left: 75px !important;
}
.mr-75 {
  margin-right: 75px !important;
}

.m-80 {
  margin: 80px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.mr-80 {
  margin-right: 80px !important;
}

.m-85 {
  margin: 85px !important;
}
.mt-85 {
  margin-top: 85px !important;
}
.mb-85 {
  margin-bottom: 85px !important;
}
.ml-85 {
  margin-left: 85px !important;
}
.mr-85 {
  margin-right: 85px !important;
}

.m-90 {
  margin: 90px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.mr-90 {
  margin-right: 90px !important;
}
