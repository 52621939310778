.plan-list {
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  // .ant-picker-panels > *:last-child {
  //   display: none !important;
  // }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }

  .ant-picker-dropdown {
    opacity: 1 !important;
    pointer-events: all !important;
  }

  #datepicker > div {
    position: static !important;
  }

  #datepicker-container > div {
    position: static !important;
  }
}

.rc-notification {
  z-index: 10999999999;
}

.dropdown-button-group {
  button:hover svg {
    fill: #1b62ab !important;
  }
}
.stock-in-out-table {
  .ant-table-cell {
    padding: 4px 8px !important;
  }
}
