.select-options {
  margin: 0 4px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;

  .ant-select{
    .ant-select-selector{
      border-radius: 6px;
      height: 40px;
      padding-left: 15px;
      padding-right: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  @include themify($themes) {
    color: themed('colorText');
  }

  &:focus {
    outline: none;
  }

  option {

    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  }
}