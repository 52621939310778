//overrides
// TODO : review

// @import './themeChanges.scss';
// @import './utils.scss';
// @import 'base/colors'; //css color variables
// @import 'base/fonts';

// @import './containers/socialfeed.scss';
// @import './containers/termsAndConditions.scss';

// @import 'components/header.scss';
// @import 'components/sidebar.scss';
// @import 'components/details-tab-layout.scss';
// @import 'components/balance-position.scss';
// @import 'components/BFCDatatable.scss';

@import 'gajanan/scss/style.scss';
@import 'remove_this_file.scss';
// @import 'containers/offers.scss';
// @import 'containers/deals.scss';

// @import 'ak.scss';
// @import 'at.scss';

// @import './containers/account.scss';

.rc-notification {
  z-index: 1100;
  // display: none;
  h5.notification__title {
    color: var(--unnamed-color-ffffff)!important;
  }
}

h1.h1--title {
  color: var(--unnamed-color-1b62ab);
  text-align: left;
  font: normal normal 600 28px Segoe UI;
  letter-spacing: 0px;
}

.center-items {
  display: flex;
  align-items: center;
}

.highlighted-text {
  em {
    font-style: normal;
    font-weight: bold;
  }
}

.has-error {
  input {
    border: 1px solid #f44336 !important;
  }
}

.bfc-alert-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 100;
  .alert__content {
    margin: 0 auto;
  }
}

.form__form-group-error {
  color: #f44336;
  font-size: 0.75rem;
}

.reference-data-select {
  width: 100%;
}

.bfc-clr-blue {
  color: #70bbfd !important;
}

.bfc-invisible {
  visibility: hidden !important;
}

.bfc-loader {
  display: flex;
  background: #3333333b;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.red {
  background-color: red;
}

.blue {
  border: 2px solid blue;
}

.green {
  border: 1px solid green;
}

.td-iconwrapper a {
  color: var(--unnamed-color-000);
}
a {
  cursor: pointer;
}

//TODO : review - move to appropriate place

.bfc-post-text-editor-modal {
  .bfc-modal-postbutton {
    color: var(--unnamed-color-ffffff);
    //  margin-bottom: 7px;
    margin: 3px;
    width: auto;
    height: 32px;
    background: #1b62ab 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    position: absolute;
    right: 5%;
  }

  .bfc-modalTitle {
    color: var(--unnamed-color-1b62ab);
    font-size: 1.5rem;
  }

  .bfc-postModalHorizontalLIne {
    width: 10%;
    color: var(--unnamed-color-1b62ab);
  }

  .bfc-modal-profileName {
    font-size: var(--fs-base__two);
    color: var(--unnamed-color-1b62ab);
    margin-top: 4%;
  }

  .bfc-socialfeed-postModalFilename {
    // background-color: red;
    width: 340px;
    text-align: right;
    // position: absolute;
    // right: 70px;
    // bottom: 10px;
  }

  .bfc-post-thumbnail {
    width: 60px;
    height: 60px;
  }

  .bfc-modalPostbox {
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    margin: 10px;
  }

  .modal-dialog {
    max-width: none !important;
    width: 650px;

    .text-editor {
      .DraftEditor-root {
        min-height: 50px !important;
      }
      .rdw-editor-toolbar {
        display: none !important;
      }
      .public-DraftEditorPlaceholder-inner {
        padding: 6px 10px;
      }
    }

    .dropzone {
      &.dropzone--multiple {
        min-height: 0;
        .dropzone__input {
          min-height: 0;
        }

        .dropzone__img {
          width: 100%;
          background-size: contain;
        }
      }
    }
  }
}

.bfc-post-text-editor-modal {
  .bfc-modal-postbutton {
    color: var(--unnamed-color-ffffff);
    //  margin-bottom: 7px;
    margin: 3px;
    width: auto;
    height: 32px;
    background: #1b62ab 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    position: absolute;
    right: 5%;
  }

  .bfc-modalTitle {
    color: var(--unnamed-color-1b62ab);
    font-size: 1.5rem;
  }

  .bfc-postModalHorizontalLIne {
    width: 100%;
    color: var(--unnamed-color-1b62ab);
  }

  .bfc-modal-profileName {
    font-size: var(--fs-base__two);
    color: var(--unnamed-color-1b62ab);
    margin-top: 4%;
  }

  .bfc-socialfeed-postModalFilename {
    // background-color: red;
    width: 340px;
    text-align: right;
    // position: absolute;
    // right: 70px;
    // bottom: 10px;
  }

  .bfc-post-thumbnail {
    width: 60px;
    height: 60px;
  }

  .bfc-modalPostbox {
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    margin: 10px;
  }
  .modal-dialog {
    max-width: none !important;
    width: 650px;

    .text-editor {
      .DraftEditor-root {
        min-height: 100px !important;
      }
      .rdw-editor-toolbar {
        display: none !important;
      }
      .public-DraftEditorPlaceholder-inner {
        padding: 6px 10px;
      }
    }

    .dropzone {
      &.dropzone--multiple {
        min-height: 0;
        .dropzone__input {
          min-height: 0;
        }

        .dropzone__img {
          width: 100%;
          background-size: contain;
        }
      }
    }
  }
}

//TODO : review - tmp fixes
.bfc-html .table-farms tbody tr td {
  color: var(--unnamed-color-707070)!important;
  font-family: var(--unnamed-font-segoeUI) !important;
}

.bfc-html .table-farms thead .th-farm {
  color: #545454 !important;
}

.account__wrapper {
  .has-error {
    .MuiAutocomplete-inputRoot {
      .MuiAutocomplete-input {
        border: unset !important;
      }
    }
  }
}

.table-filter.filter-select {
  .filter-select-__indicator {
    padding: 5px;
  }
}

.invoice-pdf {
  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.timeline:before {
  height: 85%;
  top: 5px;
}
