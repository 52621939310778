.theme-light.ltr-support {
  .formik-form {
    .form__form-group {
      margin-bottom: 30px;
      width: auto;
      &.two-input {
        display: flex;
        > div {
          flex: 1;
          &:nth-child(1) {
            margin-right: 15px;
          }
        }
      }
      .with-verified__btn {
        .mui-formik-text-field {
          .MuiFormControl-root {
            .MuiInputBase-root {
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }
        }
        .form__form-group-button {
          min-width: fit-content;
          width: 100%;
          height: 40px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
      .form-group-field {
        .about-body {
          .about-info {
            .codex-editor {
              .codex-editor__redactor {
                .ce-block {
                  .ce-block__content {
                    max-width: 100%;
                  }
                }
              }
              .ce-inline-toolbar {
                .ce-inline-toolbar__toggler-and-button-wrapper {
                  .ce-inline-toolbar__buttons {
                    .colorPlugin {
                      xy-color-picker {
                        display: contents;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .accept__form-group {
      @extend .form__form-group;
      margin-bottom: 10px;
    }

    .form-group-label {
      font-size: var(--fs-base__two);
      display: inline-block;
      color: var(--unnamed-color-000);
      @include Font-Semibold;
      margin-bottom: 10px;
      @include mq('d-large', 'max') {
        margin-bottom: 6px;
      }
    }

    .form-group-disabled {
      .MuiInputBase-root {
        background-color: rgb(201, 199, 199);
      }
    }

    .form-group-field {
      .type-enum-filter {
        .ant-radio-group {
          .line-btn {
            display: flex;
            border: 1px solid #d2d2d2;
            padding: 0.7px 0px;
            height: 41px;
            .ant-radio-wrapper {
              margin: 0;
              .ant-radio {
                display: none;
              }
              span {
                padding: 8px 0px;
                .bg-text {
                  color: var(--unnamed-color-ffffff);
                  background-color: var(--unnamed-color-1b62ab);
                  padding: 11px 20px;
                }
                .non-bg-text {
                  color: var(--unnamed-color-707070);
                  background-color: var(--unnamed-color-ffffff);
                  padding: 10px 20px;
                  border-right: 1px solid lightgrey;
                }
              }
            }
          }
        }
      }
      .form-group-icon {
        display: flex;
        align-items: center;
        background-color: var(--unnamed-color-afafaf);
        border: 1px solid var(--unnamed-color-afafaf);
        border-right: 0;
        border-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 40px;
      }
      .form__textarea {
        height: 85px;
        width: 100%;
        border: 1px solid var(--unnamed-color-ccc);
        color: var(--unnamed-color-707070);
        border-radius: 5px;
        padding: 10px;
        font-size: var(--fs-base__two);
      }
      .about-body {
        .about-info {
          .codex-editor {
            .codex-editor__redactor {
              .ce-block {
                .ce-block__content {
                  max-width: 100%;
                }
              }
            }
            .ce-toolbar {
              .ce-toolbar__content {
                max-width: initial;
                .ce-toolbar__actions {
                  right: 30px;
                }
                .ce-toolbar__plus {
                  transform: translate3d(50px, calc(19px - 50%), 0px) !important;
                }
                .ce-toolbox {
                  transform: translate3d(60px, calc(19px - 50%), 0px) !important;
                }
              }
            }
          }
        }
      }
    }
    .MuiInputAdornment-positionStart {
      margin-right: 0;
    }
    .form-group-field {
      &.with-icon,
      &.with-extention {
        display: flex;
        justify-content: flex-start;
        // align-items: center;
        align-items: flex-start;

        .custom {
          .MuiFormControl-root {
            .Mui-disabled {
              .MuiInputAdornment-root {
                .MuiFormControl-root {
                  .MuiSelect-root {
                    position: absolute;
                    right: 0px;
                    display: flex;
                    // .MuiSelect-nativeInput {
                    //   position: absolute;
                    //   right: 0px;
                    //   display: flex;
                    // }
                  }
                }
              }
            }
          }
        }

        .quality_spec--input {
          .MuiInputBase-input {
            padding: 10px;
          }
        }

        > * {
          flex: 1;
        }

        .form-group-icon {
          flex: 0;
          padding: 10px;
        }

        .extention {
          flex: 0;
          margin-left: 10px;
          font-family: var(--unnamed-font-segoeUI);
          font-weight: 700;
          font-style: normal;
          margin-left: 7px;
          // min-width: 40px;
          font-size: var(--fs-base__three);
        }

        &.with-icon {
          .MuiOutlinedInput-root {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
          }
        }

        .mui-formik-text-field {
          .custom {
            .MuiOutlinedInput-root {
              .MuiOutlinedInput-notchedOutline {
                border: 1px solid red;
              }
            }
          }
        }

        .MuiOutlinedInput-adornedEnd,
        .MuiOutlinedInput-adornedStart {
          padding: 0;
        }
      }
    }

    .custom-textarea {
      width: 100%;
      border: 1px solid #b0b0b0;
      border-radius: 3px;
      padding: 8px 15px;
      color: var(--unnamed-color-000);
      font-size: var(--fs-base__three);
    }

    .custom-input {
      position: relative;
      input,
      textarea {
        width: 100%;
        border: 1px solid #b0b0b0;
        border-radius: 3px;
        height: 40px !important;
        padding: 8px 15px;
        color: var(--unnamed-color-000);
        font-size: var(--fs-base__three);
        // @include mq('d-large', 'max') {
        //   height: 45px;
        // }
        &:focus {
          border-color: var(--unnamed-color-1b62ab);
        }
      }
      textarea {
        resize: none;
        height: 120px;
      }

      .datepicker-calendar-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        pointer-events: none;
      }

      // .datepicker-close-icon {
      //   visibility: hidden;
      // }
      .datepicker-start-close__icon {
        right: 10px;
        position: absolute;
        top: 10px;
        height: 24px;
        cursor: pointer;
        background-color: var(--unnamed-color-ffffff);
        visibility: hidden;
        z-index: 9;
      }
      .datepicker-end-close__icon {
        @extend .datepicker-start-close__icon;
      }

      .datepicker-dob-close__icon {
        @extend .datepicker-start-close__icon;
      }

      .date-picker-start {
        &:hover {
          .datepicker-start-close__icon {
            visibility: visible;
          }
          .datepicker-dob-close__icon {
            visibility: visible;
          }
        }
      }
      .date-picker-end {
        &:hover {
          .datepicker-end-close__icon {
            visibility: visible;
          }
        }
      }

      .date-picker--interval {
        display: flex;

        & > div,
        .react-datepicker-wrapper,
        .react-datepicker__input-container {
          max-width: 100% !important;
        }
      }

      .react-datepicker {
        // right: 25px;
        right: 40px;
        .react-datepicker__monthPicker {
          display: inline-grid;
          grid-auto-columns: max-content;
        }
      }
    }

    .form-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .collapsable-wrapper {
    padding: 30px 15px;
    .card {
      .main-cardbody {
        border: 1px solid #d4d3d4;
        border-radius: 0;
        padding: 0;
        .collapse__wrapper {
          margin: 0;
          .collapse__title {
            // background-color: #f2f2f2;
            background-color: #f6f6f6;
            border-bottom: 1px solid #d4d4d4;
            @include mq('d-large', 'min') {
              padding-top: 20px;
              padding-bottom: 20px;
            }
            p {
              font-size: var(--fs-base__two);
              color: var(--unnamed-color-000);
              @include Font-Semibold;
              .mdi-icon {
                height: 20px;
                width: 20px;
                fill: var(--unnamed-color-000);
                top: calc(50% - 10px);
              }
            }
          }
          .collapse__content {
            background-color: var(--unnamed-color-ffffff);
            border-bottom: 1px solid #d4d3d4;
            .card {
              padding-bottom: 0;
            }
          }
          &:nth-last-child(1) {
            .collapse__title {
              border-bottom: 0;
            }
            .collapse__content {
              border-top: 1px solid #d4d3d4;
              border-bottom: 0;
            }
          }
        }
      }
    }
    .prev-next-link-box {
      margin: 20px 0 40px 0;
      display: flex;
      justify-content: space-between;
    }
  }

  .bfc-html {
    ///////
    .radiobox-address {
      p {
        font-family: var(--unnamed-font-segoeUI);
        color: var(--unnamed-color-000);
        margin: 0 0 10px 0;
      }
      .radiobox-wrapper {
        .radio-color-btn {
          padding-left: 0;
          padding-right: 25px;
          &:not(:last-child) {
            margin-right: 18px;
          }
          input {
            + .control-indicator {
              left: auto;
              right: 0;
            }
          }
        }
      }
    }

    /////
    .pan-wrapper {
      .dont-pan {
        label {
          color: var(--unnamed-color-000);
          font-family: var(--unnamed-font-segoeUI);
          display: flex;
          align-items: center;
          margin: 0 0 15px 0;
          input {
            height: 13px;
            width: 13px;
            margin-left: 12px;
          }
        }
        .content {
          color: var(--unnamed-color-000);
          font-family: var(--unnamed-font-segoeUI);
          margin: 0 0 5px 0;
        }
        a {
          color: var(--unnamed-color-1b62ab);
          text-decoration: underline;
        }
      }
    }

    ////
    .businesstax-block {
      .radiobox-wrapper {
        .radio-color-btn {
          font-family: var(--unnamed-font-segoeUI);
          color: var(--unnamed-color-000);
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }

    //////
    .notes-block {
      .new-icon {
        position: absolute;
        right: 0px;
        top: 0px;
      }
      p {
        margin: 0;
        border-radius: 3px;
        font-size: var(--fs-base__three);
        padding: 5px 15px;
        &.redbg {
          background-color: rgba(255, 0, 0, 0.05);
          color: var(--unnamed-color-d11010);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          border: 1px solid #c88682;
        }
        &.yellowbg {
          // background-color: rgba(236, 250, 176, 0.945);
          background-color: #fffcce;
          color: var(--unnamed-color-000);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          // border: 1px solid hsl(64, 98%, 50%);
          border: 1px solid #e8e393;
        }
        &.greenbg {
          background-color: rgba(2, 177, 2, 0.05);
          color: var(--unnamed-color-3c763d);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .green-star {
      color: #02b102;
      opacity: 0.5;
      font-size: var(--fs-base__two);
    }

    ///
    .fileupload-wrapper {
      display: flex;
      align-items: center;
      .fileupload-block {
        position: relative;
        .fileupload {
          opacity: 0;
          position: absolute;
          z-index: 1;
          border: 0;
          padding: 0;
          height: 100%;
          width: 100%;
        }
        .fileupload-cust {
          display: flex;
          align-items: center;
          .icon-block {
            margin-right: 7px;
          }
          p {
            margin: 0;
            color: var(--unnamed-color-000);
            font-family: var(--unnamed-font-segoeUI);
          }
        }
      }

      .drag-block {
        > div {
          display: flex;
          align-items: center;
          border: 1px dashed #c0c0c0;
          padding: 7px 15px;
          margin-left: 5px;
          .icon-block {
            margin-right: 10px;
          }
          p {
            margin: 0;
            font-family: var(--unnamed-font-segoeUI);
          }
        }
      }

      .file-name {
        color: var(--unnamed-color-1b62ab);
        text-decoration: underline;
        margin: 0 0 0 20px;
      }
    }
  }
  .equipment-form-container{
    .equipment-input{
      @extend .custom-input;
    .react-datepicker__input-container {
      input {
        width: 100% !important;
        border: 1px solid #b0b0b0 !important;
        border-radius: 3px !important;
        height: 40px !important;
        padding: 8px 15px !important;
        color: var(--unnamed-color-000) !important;
        font-size: var(--fs-base__three) !important;
      }
    }

    .datepicker-calendar-icon {
      position: absolute;
      right: 30px;
      top: 30px;
      visibility: visible;
      pointer-events: none;
    }
    }
  }
  .kyc-form-container {
    .custom-input {
      .datepicker-calendar-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        visibility: visible;
        pointer-events: none;
      }
      .datepicker-dob-close__icon {
        right: 25px;
        position: absolute;
        top: 30px;
        height: 24px;
        cursor: pointer;
        background-color: var(--unnamed-color-ffffff);
        visibility: hidden;
        z-index: 9;
      }
      .date-picker-start {
        &:hover {
          .datepicker-calendar-icon {
            visibility: hidden;
          }
          .datepicker-dob-close__icon {
            right: 30px;
            visibility: visible;
          }
        }
      }
    }
    .driver-dob {
      @extend .custom-input;
      .react-datepicker__input-container {
        input {
          width: 100% !important;
          border: 1px solid #b0b0b0 !important;
          border-radius: 3px !important;
          height: 40px !important;
          padding: 8px 15px !important;
          color: var(--unnamed-color-000) !important;
          font-size: var(--fs-base__three) !important;
        }
      }

      .datepicker-calendar-icon {
        position: absolute;
        right: 30px;
        top: 30px;
        visibility: visible;
        pointer-events: none;
      }
      // .date-picker {
      //   .has-error {
      //     .react-datepicker-wrapper {
      //       .react-datepicker__input-container {
      //         input {
      //           border: 1px solid #f44336 !important;
      //         }
      //       }
      //     }
      //   }
      //   .react-datepicker__input-container {
      //     input {
      //       width: 100% !important;
      //       border: 1px solid #b0b0b0 !important;
      //       border-radius: 3px !important;
      //       height: 40px !important;
      //       padding: 8px 15px !important;
      //       color: var(--unnamed-color-000) !important;
      //       font-size: var(--fs-base__three) !important;
      //     }
      //   }
      // }
      // .datepicker-calendar-icon {
      //   position: absolute;
      //   right: 30px;
      //   top: 30px;
      // }
    }
  }
}

//====================* Radio "Color" Button Style Start *=======================
.radio-color-btn {
  margin: 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 33px;
  margin-right: 10px;
  min-height: 24px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    + .control-indicator {
      position: absolute;
      top: 0px;
      left: 0;
      height: 20px;
      width: 20px;
      background: #fff;
      border-radius: 20px;
      border: 1px solid #000000;
      transition: all 0.2s;

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: var(--unnamed-color-000);
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 24px;
        transition: all 0.2s ease;
        opacity: 0;
        transform: scale(0);
      }
    }
    &:checked {
      + .control-indicator {
        border-color: var(--unnamed-color-000);
        &:after {
          transition: all 0.2s ease;
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

//====================* CheckBox "Color" Button Style Start *=======================
.chekbox-color-btn {
  margin: 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 33px;
  margin-right: 10px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    + .control-indicator {
      position: absolute;
      top: 0px;
      left: 0;
      height: 20px;
      width: 20px;
      background: #fff;
      border-radius: 2px;
      border: 1px solid #000000;
      transition: all 0.2s;

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: var(--unnamed-color-000);
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 2px;
        transition: all 0.2s ease;
        opacity: 0;
        transform: scale(0);
      }
    }
    &:checked {
      + .control-indicator {
        border-color: var(--unnamed-color-000);
        &:after {
          transition: all 0.2s ease;
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .date-picker--interval {
    display: block !important;
  }
}
@media screen and (max-width: 369px) {
  .react-datepicker {
    right: 40px !important;
  }
  .react-datepicker__day-name {
    width: 1.4rem !important;
  }
  .react-datepicker__day {
    width: 1.4rem !important;
  }
  .react-datepicker__time-container {
    width: 60px !important;
  }
}
@media screen and (min-width: 392px) and (max-width: 576px) {
  .react-datepicker {
    right: 35px !important;
  }
}
@media screen and (max-width: 769px) {
  .formik-form {
    .form-btns {
      display: block !important;
      .button {
        display: flex;
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .formik-form {
    .form__form-group {
      width: auto !important;
      .with-verified__btn {
        .form__form-group-button {
          height: 36px !important;
        }
      }
    }
  }
  .form-container {
    .form__form-group {
      width: auto;
    }
  }
  .collapse__wrapper {
    .collapse__content {
      padding: 0 !important;
    }
  }
  .collapsable-wrapper {
    .card {
      .main-cardbody {
        .collapse__wrapper {
          .collapse__content {
            padding-right: 25px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 3072px) {
  form {
    .card {
      .card-body {
        .form__form-group {
          max-width: 70%;
          .form-group-field {
            .MuiInputBase-root {
              padding: 20px;
              font-size: var(--fs-base__one);
              .MuiOutlinedInput-inputMarginDense {
                padding: 0px;
              }
            }
            .date-picker {
              .custom-input {
                .react-datepicker-wrapper {
                  .react-datepicker__input-container {
                    input {
                      padding: 15px;
                      height: 60px !important;
                    }
                  }
                }
              }
              .bfc-date-time-wrapper {
                .react-datepicker-wrapper {
                  .react-datepicker__input-container {
                    input {
                      padding: 18px;
                      height: 60px !important;
                    }
                  }
                }
              }
            }
            .datepicker-calendar-icon {
              margin: 10px;
              height: 25px;
            }
            .form-group-icon {
              padding: 18px !important;
            }
          }
        }
        table {
          table-layout: auto;
        }
      }
    }
  }
  .form-container {
    .farm-wrapper {
      .card {
        .main-cardbody {
          .form-container {
            .form__form-group {
              max-width: 60% !important;
              .form-group-field {
                .MuiInputBase-root {
                  padding: 20px;
                  font-size: var(--fs-base__one);
                  .MuiOutlinedInput-inputMarginDense {
                    padding: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .form-group-field {
    .form__form-group-button {
      min-height: 60px;
    }
  }
  .annual-turnover {
    .mui-formik-text-field {
      input {
        height: 65px;
      }
    }
  }
}
@media only screen and (min-width: 4068px) {
  form {
    .card {
      .card-body {
        .form__form-group {
          max-width: 40%;
          .form-group-field {
            .MuiInputBase-root {
              padding: 20px;
              font-size: var(--fs-base__one);
              .MuiOutlinedInput-inputMarginDense {
                padding: 0px;
              }
            }
            .date-picker {
              .custom-input {
                .react-datepicker-wrapper {
                  .react-datepicker__input-container {
                    input {
                      padding: 15px;
                    }
                  }
                }
              }
              .bfc-date-time-wrapper {
                .react-datepicker-wrapper {
                  .react-datepicker__input-container {
                    input {
                      padding: 18px;
                    }
                  }
                }
              }
            }
            .datepicker-calendar-icon {
              margin: 10px;
              height: 25px;
            }
            .form-group-icon {
              padding: 18px !important;
            }
            .mui-formik-text-field {
              input {
                height: 20px;
              }
            }
          }
        }
      }
    }
    .annual-turnover {
      .mui-formik-text-field {
        input {
          height: 65px !important;
        }
      }
    }
  }
}
