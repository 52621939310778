.bfc-list-data-grid {
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #0000001a;
  border-radius: 0px 0px 10px 10px;
  height: 100%;

  .react-grid-Container {
    .react-grid-Header {
      background: #f7f7f7 0% 0% no-repeat padding-box;
    }

    .react-grid-HeaderCell {
      display: flex !important;
      align-items: center;
      justify-content: center;
      z-index: 99;

      .form-group {
        margin: 5px 5px;
        .form-control {
          padding: 5px 10px;
          margin: 0;
          line-height: 1;
          font-size: 11px;
          background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
          border: 1px solid #c7c7c7;
          border-radius: 2px;
          color: var(--unnamed-color-707070) !important;
          font-weight: 600;
          &::placeholder {
            color: transparent;
          }
        }
      }

      .Select-control {
        .Select-placeholder {
          color: transparent;
        }
      }
    }

    .react-grid-Cell {
      font: normal normal normal 14px/17px Noah;
      .react-grid-Cell__value {
        // text-align: center;
      }
    }
  }

  .grid-pagination {
    position: absolute;
    right: 0;
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font: normal normal normal 14px/19px Segoe UI;

    .pagination {
      li.page-item {
        outline: none;
        box-shadow: none;
        cursor: pointer;

        button.page-link {
          background-color: transparent;
          border: none;
          text-align: center;
          font: normal normal normal 14px/19px Segoe UI;
          color: var(--unnamed-color-707070);
          cursor: pointer;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      li.page-item.active button {
        font-weight: bolder;
        color: var(--unnamed-color-1b62ab);
      }

      li:first-child button,
      li:last-child button {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px #00000029;
        border: 1px solid #f3f3f3;
        border-radius: 18px;
        padding: 0px 5px;
        margin-top: 8px;
        cursor: pointer;
        font-size: var(--fs-base__one);
      }
    }

    .grid-pagination-text {
      padding-left: 20px;
    }
  }
}
