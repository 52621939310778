:root {
  font-size: var(--fs-base__two);
  // font-size: 100%;
  @include mq('d-large', 'max') {
    font-size: var(--fs-base__three);
    // font-size: 80%;
  }
}

.bfc-html {
  font-family: var(--unnamed-font-segoeUI) !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include Font-Bold;
  }
  ul {
    list-style: none;
  }
  .bfc-header {
    background-color: var(--unnamed-color-ffffff);
    box-shadow: 0px 0px 15px #0000001a;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 10px;
    // padding: 10px 40px 10px 10px;
    .content-wrapper {
      display: flex;
      .bck-btn {
        display: flex;
        margin-right: 20px;
        align-items: center;
        img {
          width: 35px;
        }
      }
      .content-block {
        .top-content {
          margin-bottom: 10px;
          .title {
            span {
              display: inline-block;
              margin-right: 5px;
            }
            @include rem(font-size, 30);
            color: var(--unnamed-color-1b62ab);
            margin-bottom: 0;
            @include mq('d-large', 'min') {
              margin-bottom: 5px;
            }
          }
        }
        .bottom-content {
          display: flex;
          align-items: center;
          .lbl {
            display: inline-flex;
            background-color: #eff9f0;
            font-size: var(--fs-base__two);
            text-transform: uppercase;
            border-radius: 2px;
            padding: 3px 10px;
            margin: 0 25px 0 0;
            @include mq('d-large', 'max') {
              font-size: var(--fs-base__three);
            }
            &.lbl-published {
              color: #599906;
            }
            &.lbl-default {
              color: #b0b0b0;
            }
          }

          p {
            font-size: var(--fs-base__one);
            color: #b0b0b0;
            margin: 0;
            span {
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
      }
    }
    //
    .btn-wrapper {
      display: flex;
      align-items: center;
    }
  }
  //

  .bfc-tabs {
    background-color: #f1f2f3;
    // box-shadow: 0px 0px 15px #0000002b;
    padding-left: 43px;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      li {
        list-style-type: none;
        padding: 15px 20px 0;
        @include mq('d-large', 'min') {
          padding: 20px 20px 0;
        }

        &::marker {
          display: none;
        }
        a {
          color: var(--unnamed-color-000);
          @include Font-Medium;
          position: relative;
          padding-bottom: 15px;
          display: inline-block;
          font-size: var(--fs-base__two);
          @include mq('d-large', 'min') {
            padding-bottom: 20px;
          }
          &:after {
            height: 3px;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0%;
            transition: all 0.3s;
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
          }
          &:hover {
            &:after {
              background-color: var(--unnamed-color-1b62ab);
              width: 100%;
            }
          }
          &.active {
            @include Font-Bold;
            &:after {
              background-color: var(--unnamed-color-1b62ab);
              width: 100%;
            }
          }
        }
      }
    }
  }
  //

  .bfc-body {
    background-color: var(--unnamed-color-ffffff);
    box-shadow: 0px 0px 15px #0000002b;
    // padding: 30px 15px;
  }
  //

  //form css common
  .form-wrapper {
    display: flex;
    &.full-width {
      flex-direction: column;
      width: 100%;
    }
    .form__form-group {
      margin-bottom: 30px;
      &.two-input {
        display: flex;
        > div {
          flex: 1;
          &:nth-child(1) {
            margin-right: 15px;
          }
        }
      }
      // Form Label
      .form__form-group-label {
        font-size: var(--fs-base__one);
        color: var(--unnamed-color-000);
        @include Font-Semibold;
        margin-bottom: 10px;
        @include mq('d-large', 'max') {
          margin-bottom: 6px;
        }
      }

      .form__form-group-field {
        margin-left: 0;
        width: 100%;
        .form__form-group-icon {
          height: 60px;
          display: flex;
          align-items: center;
          background-color: var(--unnamed-color-afafaf);
          border: 1px solid var(--unnamed-color-afafaf);
          border-right: 0;
          border-radius: 3px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          width: 40px;
          @include mq('d-large', 'max') {
            height: 45px;
          }
          + input {
            // border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
          }
          + div.w-100 {
            input {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-left: 0;
            }
          }
        }
        input,
        textarea {
          border: 1px solid #b0b0b0;
          border-radius: 3px;
          // height: 45px;
          height: 60px;
          // width: 100%;//TODO : causing mui select to next line -  review later
          padding: 10px 15px;
          color: var(--unnamed-color-000);
          font-size: var(--fs-base__two);
          @include mq('d-large', 'max') {
            height: 45px;
          }
          &:focus {
            border-color: var(--unnamed-color-1b62ab);
          }
        }
        textarea {
          resize: none;
          height: 120px;
        }
        .bfc-ref-data-select-wrapper {
          width: 100%;
          height: 60px;
          @include mq('d-large', 'max') {
            height: 45px;
          }
          .MuiInputBase-root {
            padding-left: 0;
            padding-bottom: 0;
            padding-top: 0;
            &.Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border: 1px solid var(--unnamed-color-1b62ab);
              }
            }
          }
          .MuiOutlinedInput-notchedOutline {
            top: -7px;
            border-color: #b0b0b0;
          }
          input {
            border: 0;
            padding-top: 0;
            padding-bottom: 0;
            height: 60px;
            @include mq('d-large', 'max') {
              height: 45px;
            }
          }
        }
      }
    }
    //
    .form-half {
      flex: 50% 0 0;
      padding-right: 5%;
      .form__form-group {
        max-width: 400px;
      }
    }
  }

  //////Component
  //*********   Overviewblock.jsx   ********
  .overview-block {
    margin-bottom: 20px;
    .overview-single {
      background-color: #f8f8f8;
      border-radius: 4px;
      &.bg-color {
        background: -moz-linear-gradient(
          62deg,
          rgba(238, 241, 245, 1) 0%,
          rgba(218, 238, 255, 1) 17%,
          rgba(239, 248, 255, 1) 48%,
          rgba(242, 245, 247, 1) 72%,
          rgba(221, 239, 255, 1) 100%
        );
        background: -webkit-gradient(
          linear,
          left bottom,
          right top,
          color-stop(0%, rgba(238, 241, 245, 1)),
          color-stop(17%, rgba(218, 238, 255, 1)),
          color-stop(48%, rgba(239, 248, 255, 1)),
          color-stop(72%, rgba(242, 245, 247, 1)),
          color-stop(100%, rgba(221, 239, 255, 1))
        );
        background: -webkit-linear-gradient(
          62deg,
          rgba(238, 241, 245, 1) 0%,
          rgba(218, 238, 255, 1) 17%,
          rgba(239, 248, 255, 1) 48%,
          rgba(242, 245, 247, 1) 72%,
          rgba(221, 239, 255, 1) 100%
        );
        background: -o-linear-gradient(
          62deg,
          rgba(238, 241, 245, 1) 0%,
          rgba(218, 238, 255, 1) 17%,
          rgba(239, 248, 255, 1) 48%,
          rgba(242, 245, 247, 1) 72%,
          rgba(221, 239, 255, 1) 100%
        );
        background: -ms-linear-gradient(
          62deg,
          rgba(238, 241, 245, 1) 0%,
          rgba(218, 238, 255, 1) 17%,
          rgba(239, 248, 255, 1) 48%,
          rgba(242, 245, 247, 1) 72%,
          rgba(221, 239, 255, 1) 100%
        );
        background: linear-gradient(
          28deg,
          rgba(238, 241, 245, 1) 0%,
          rgba(218, 238, 255, 1) 17%,
          rgba(239, 248, 255, 1) 48%,
          rgba(242, 245, 247, 1) 72%,
          rgba(221, 239, 255, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#DDEFFF', endColorstr='#EEF1F5',GradientType=0 );
      }
      .top-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px 7px 25px;
        @include mq('d-large', 'min') {
          padding: 20px 16px 10px 25px;
        }
        .title-wrapper {
          .title {
            @include rem(font-size, 28);
            // @include Font-Bold;
            color: var(--unnamed-color-1b62ab);
            margin-bottom: 4px;
            font-family: var(--unnamed-font-segoeUI);
          }
          .calender-fromto {
            p {
              font-size: var(--fs-base__zero);
              color: var(--unnamed-color-1b62ab);
              margin: 0;
              font-family: var(--unnamed-font-segoeUI);
              span {
                font-size: var(--fs-base__two);
                color: var(--unnamed-color-afafaf);
                @include Font-Regular;
              }
            }
            table {
              td {
                &:nth-child(1) {
                  font-size: var(--fs-base__two);
                  color: var(--unnamed-color-afafaf);
                  @include Font-Regular;
                }
                &:nth-child(2) {
                  font-size: var(--fs-base__zero);
                  color: var(--unnamed-color-1b62ab);
                  margin: 0;
                  font-family: var(--unnamed-font-segoeUI);
                  line-height: 1;
                }
              }
            }
          }
          p.content {
            font-size: var(--fs-base__two);
            color: var(--unnamed-color-1b62ab);
            margin: 0;
            letter-spacing: 0.18px;
          }
        }
        .icon-wrapper {
          svg {
            @include mq('d-large', 'max') {
              max-width: 80%;
            }
          }
        }
      }

      .bottom-block {
        background-color: #f1f5f79e;
        padding: 3px 15px 3px 25px;
        @include mq('d-large', 'min') {
          padding: 4px 15px 5px 25px;
        }
        p {
          color: var(--unnamed-color-1b62ab);
          margin: 0;
          letter-spacing: 0.16px;
          font-size: var(--fs-base__one);
        }
      }
    }
    .block-offers {
      color: var(--unnamed-color-afafaf);
      font-size: var(--fs-base__two);
      margin-top: 4px;
      padding: 0 7px;
      &.color-text {
        color: #28bdc2;
        display: flex;
        .icon-wrapper {
          display: inline-block;
          margin-right: 4px;
        }
      }
    }
    &.overview2 {
      .overview-single {
        .top-block {
          align-items: flex-end;
          .content-wrapper {
            .single-block {
              &:not(:last-child) {
                margin-bottom: 15px;
              }
              .title {
                font-size: var(--fs-base__two);
                color: var(--unnamed-color-1b62ab);
                margin: 0;
                line-height: 1.3;
              }
              .details {
                font-size: 1.875rem;
                line-height: 1.3;
                margin: 0;
                color: var(--unnamed-color-1b62ab);
                font-family: var(--unnamed-font-segoeUI);
              }
            }
          }
        }
      }
    }
  }

  //*********   OverviewDetailsSellercompo.jsx   ********
  .overview-details-seller {
    width: 100%;
    .overview-details,
    .overview-sellers {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .title {
        font-size: var(--fs-base__zero);
        @include Font-Bold;
        color: var(--unnamed-color-000);
        flex-basis: 200px;
      }
      .details-wrapper {
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        display: flex;
        overflow: hidden;
        .single-block {
          min-width: 125px;
          .header-block {
            background-color: #f1f5f79e;
            padding: 7px 10px;
            text-align: center;
            img {
              margin-right: 8px;
            }
            span {
              font-size: var(--fs-base__two);
              color: var(--unnamed-color-1b62ab);
              @include mq('d-large', 'max') {
                font-size: var(--fs-base__three);
              }
            }
          }
          .content-block {
            padding: 13px 10px;
            text-align: center;
            p {
              font-size: var(--fs-base__one);
              @include Font-Bold;
              color: var(--unnamed-color-000);
              margin: 0;
            }
          }
          &:not(:last-child) {
            border-right: 1px solid #c7c7c7;
          }
          /////////
          &.withicon {
            min-width: 270px;
            .content-block {
              p {
                span {
                  display: inline-block;
                  margin-right: 10px;
                  img {
                    max-width: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
