.email-select .ant-select .ant-select-selector .ant-select-selection-overflow {
  overflow: hidden;
}

.ant-select-selection-overflow {
  max-height: 150px;
  overflow: auto;
}

.ant-select-selection-overflow-item {
  flex-wrap: nowrap;
}
