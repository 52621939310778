.theme-light.ltr-support {
  .bfc-html {
    img {
      max-width: 100%;
      width: auto;
    }
    .bfc-modalform {
      background-color: var(--unnamed-color-ffffff);
      width: 450px;
      position: absolute;
      right: 0;
      top: -20px;
      box-shadow: -5px 0px 15px #00000033;
      // overflow-x: hidden;
      // overflow-y: auto;
      overflow: hidden !important;
      border-radius: 7px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .bfc-modalform-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 15px 15px 30px;
        box-shadow: 0px 0px 10px #00000033;
        align-items: center;
        .icons-wrapper {
          display: flex;
          align-items: center;
          img {
            margin-right: 15px;
          }
          .title {
            font-size: 1.25 !important;
            @include Font-Bold;
            color: var(--unnamed-color-1b62ab);
          }
        }
        .close-wrapper {
          a {
            &:not(:last-child) {
              margin-right: 15px;
            }
            img,
            svg {
              @include mq('d-large', 'max') {
                max-width: 18px;
              }
            }
          }
        }
      }
      .modalform-body {
        padding: 30px 40px 20px;
        form {
          .form__form-group {
            margin-bottom: 20px;
            width: auto;
            .left-side-value {
              font-weight: 600;
              color: var(--unnamed-color-707070);
              float: right;
              // padding-right: 25px;
            }
            .form-group-label {
              font-size: var(--fs-base__two);
            }
            .form-group-approx-time {
              font-style: italic;
              font-size: 0.875;
              color: var(--unnamed-color-1b62ab);
              font-weight: 700;
              margin-top: -10px;
            }
            .include-text {
              font-weight: 600;
              color: var(--unnamed-color-afafaf);
              float: right;
              margin-top: 10px;
            }
            .form__form-group-button {
              height: 38px;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
            .form__form-group-field {
              @extend .form__form-group-button;
            }
            .form__form-group-field {
              display: flex;
              align-items: center;
              .extention {
                @include Font-Bold;
                margin-left: 7px;
                min-width: 30px;
                font-size: var(--fs-base__three);
              }
            }
            .custom-input {
              position: relative;
              input,
              textarea {
                width: 100%;
                border: 1px solid #b0b0b0;
                border-radius: 3px;
                height: 40px;
                padding: 7px 10px;
                color: var(--unnamed-color-000);
                font-size: var(--fs-base__two);
                &:focus {
                  border-color: var(--unnamed-color-1b62ab);
                }
              }
              textarea {
                resize: none;
                height: 120px;
              }
              .datepicker-calendar-icon {
                position: absolute;
                right: 10px;
                top: 10px;
                pointer-events: none;
              }
            }
          }
          .vehicle-reg-id {
            .MuiOutlinedInput-root {
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }
          .license-number-field {
            .MuiOutlinedInput-root {
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }

          .invoice-finance-box {
            background-color: #f0fff7;
            border-radius: 5px;
          }

          .addanother-farm {
            margin: 0 -54px;
            padding: 10px 40px;
            background-color: var(--unnamed-color-ffffff);
            box-shadow: 0px 0px 10px #00000033;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              font-size: 1.5rem;
              color: var(--unnamed-color-1b62ab);
              font-family: var(--unnamed-font-segoeUI);
            }
            button {
              border-radius: 8px;
              font-size: var(--fs-base__two);
              padding: 7px 25px 8px;
              display: inline-block;
              box-shadow: 0px 0px 10px #0000001a;
              font-family: var(--unnamed-font-segoeUI);
              line-height: 1;
            }
          }

          .btn-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            border-top: 1px solid #e4e4e4;
            padding-top: 10px;
            .btn {
              font-weight: 400 !important;
            }
            .terms-block {
              position: relative;
              margin-top: 10px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              span {
                @include Font-Regular;
                color: var(--unnamed-color-afafaf);
                font-size: var(--fs-base__four);
                margin-left: 10px;
              }
            }
          }
        }
        .kyc-block {
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 20px;
          .title {
            font-size: var(--fs-base__one);
            color: var(--unnamed-color-000);
            @include Font-Semibold;
            margin-bottom: 15px;
            display: flex;
          }
          .kyc-wrapper {
            .single-block {
              border-radius: 7px;
              box-shadow: 0px 0px 6px #00000033;
              font-size: var(--fs-base__three);
              overflow: hidden;
              margin-bottom: 15px;
            }
            .block-header {
              display: flex;
              justify-content: space-between;
              padding: 10px 15px;
              background: #f9f9f9;
              .status {
                line-height: 1;
                @include Font-Semibold;
                img,
                svg {
                  width: 10px;
                }
              }
              .date-name {
                p {
                  margin: 0;
                  line-height: 1;
                }
                .date-time {
                  margin-bottom: 7px;
                }
                .name {
                  @include Font-Semibold;
                }
              }
            }
            .block-body {
              background-color: var(--unnamed-color-ffffff);
              padding: 10px 15px;
              p {
                @include Font-Medium;
                margin: 0;
              }
            }
          }
        }
        .chat-care {
          margin: 0;
          padding: 0;
          .chat {
            list-style: none;
            padding: 0;
            li {
              width: 100%;
            }
            .admin {
              .heading {
                background-color: #f7f7f7;
                color: var(--unnamed-color-32a836);
                font-weight: bold;
                font-size: var(--fs-base__four);
              }
              .chat-body {
                display: inline-block;
                box-shadow: 0px 2px 5px 2px rgba(151, 142, 142, 0.2);
                max-width: 100%;
                background-color: var(--unnamed-color-ffffff);
                border-radius: 12.5px;
                width: 100%;
                .header {
                  text-align: left;
                  background-color: #35858d08;
                  padding: 10px;
                  color: var(--unnamed-color-707070);
                  border-radius: 10px 10px 0px 0px;
                  font-size: var(--fs-base__three);
                  .right-subtext {
                    font-weight: bold;
                  }
                }
              }
              .chat-msg {
                text-align: left;
                margin: 0px;
                padding: 10px;
                font-size: var(--fs-base__three);
              }
            }
          }
        }
      }
      .modalform-body-aggregation-form {
        padding: 25px;
      }
    }
  }
}
.modal {
  .modal-dialog {
    top: 50%;
    margin: 0 auto;
    transform: translate(0, -50%) !important;
  }
  .price-calc-modal {
    max-width: 650px !important;
    .modal__body{
      padding-right: 20px;
      .row {
        .col-12 {
          margin-bottom: 10px;
        }
      }
    }
  }
  .map-modal {
    max-width: 1000px !important;
    .modal-content {
      max-width: 1000px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .theme-light.ltr-support {
    .bfc-html {
      .bfc-modalform {
        width: 500px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .modal {
    .modal__body {
      .hashtag {
        .slick-list {
          .slick-slide {
            width: auto !important;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 491px) and (max-width: 749px) {
  .bfc-modalform {
    top: 120px !important;
  }
}
@media screen and (min-width: 491px) and (max-width: 576px) {
  .bfc-modalform {
    top: 130px !important;
  }
}
@media screen and (max-width: 491px) {
  .modal-dialog {
    width: auto !important;
    .modal-content {
      .modal__body {
        .modal__footer {
          display: contents !important;
          .blur__bfc-socialfeed-post-camera {
            margin-left: 20px;
          }
          .res-sizes {
            margin-left: 0px;
          }
          .res-sizes-1 {
            flex-flow: nowrap;
            margin-left: 15px;
          }
          .pdf-icon {
            width: 100% !important;
          }
          .trash-icon {
            margin-top: 5px !important;
            margin-right: 20px !important;
          }
          .tab-index {
            display: flex;
            justify-content: start;
            margin-left: 20px;
          }
          .res-upload-doc {
            width: 100%;
          }
          .upload-doc {
            margin-left: 15px;
          }
          .upload__img-pdf {
            margin-left: -30px !important;
          }
          .modal_ok {
            width: 90% !important;
            position: initial !important;
            margin-bottom: 15px;
            margin-left: 15px;
          }
          .share-post__btn {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .bfc-modalform {
    top: 70px !important;
    width: auto !important;
    margin-top: 60px;
    left: 10px;
  }
}
