.sidebar {
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, .1)!important;
  // box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .2)!important;
  .bfc-sidebar {
    font-family: var(--unnamed-font-segoeUI)!important;
    // background: url(../../images/placeholders/farm.jpg);
    // height: 132px;
    // background-size: cover;

    /** temp hack **/
    // position: relative;
    // top: -40px;
    // height: calc(100% + 40px);
    /** temp hack end **/

    // background: var(--unnamed-color-f4f7f6) 0% 0% no-repeat padding-box;
    // box-shadow: 0px 0px 15px #0000001a;
    // opacity: 1;
    // display: flex;
    // flex-direction: column;
    // height: 100%;

    // hr {
    //   width: auto;
    //   margin: 15px 0px 40px 0;
    //   border: 2px solid var(--unnamed-color-1b62ab);
    //   box-shadow: 0px 2px 6px #00000029;
    // }
    &__profile {
      // margin-top: 17px;
      &__pic {
        display: flex;
        justify-content: center;
        align-items: center;
        &__block {
          width: 70px;
          height: 70px;
          background-repeat: no-repeat;
          border-radius: 50px;
          background-size: cover; //contain
          box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, .1);
          .sidebar-profile-pic {
            height: 100%;
            object-fit: cover;
            p {
              font-size: 30px;
              color: white;
            }
          }
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin-top: 5px;
        color: var(--unnamed-color-1b62ab);
        strong {
          font-size: var(--fs-base__one);
          color: var(--unnamed-color-1b62ab);
          font-family: var(--unnamed-font-segoeUI);
        }
        companyName {
          font-size: var(--fs-base__one);
          font-weight: 600;
          color: var(--unnamed-color-1b62ab);
        }
        span {
          font-size: var(--fs-base__three);
        }
      }
    }

    &__links {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          padding: 0px;
          text-align: left;
          &:hover,
          a.active {
            background: var(--unnamed-color-ffffff);
          }
          a {
            display: block;
            padding: 15px 0px 15px 10px;
            width: 100%;
            height: 100%;
            color: var(--unnamed-color-1b62ab);
            font: normal normal 500 18px Segoe UI;
            letter-spacing: 0px;
            span {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
  &.sidebar--collapse {
    .bfc-sidebar {
      &__profile {
        visibility: hidden !important;
      }
    }
  }
}

.sidebar__submenu-wrap{
  .sidebar__submenu{
    .sidebar__link{
      background-color: var(--unnamed-color-ffffff);
      border-bottom: 1px solid #f5f6f9;
      border-top: 1px solid #f5f5f9;
      &:hover{
        background-color: var(--unnamed-color-e7e2e2);
      }
      &:active{
        background: aliceblue;
      }
      .sidebar__link-title{
        margin-left: 15px;
      }
    }
  }
}
.sidebar.sidebar--collapse {
  .bfc-sidebar__profile {
    column-gap: 0px!important;
    visibility: visible!important;
    .bfc-sidebar__profile__pic {
      .bfc-sidebar__profile__pic__block {
        height: 30px;
        width: 30px;
      }
      .first-pic {
        margin-top: 15px;
        .sidebar-profile-pic {
          p {
            font-size: 18px;
          }
        }
      }
    }
    .bfc-sidebar__profile__info {
      display: none;
    }
    .star-ratings {
      display: none!important;
    }
    .shield-icon {
      img {
        display: none;
      }
    }
    .line {
      display: none;
    }
  }
}
// @media (min-width: 576px) and (max-width: 1199px){
//   .sidebar{
//     visibility: visible!important;
//     width: 55px!important;
//     .sidebar__content{
//       overflow: visible!important;
//       .bfc-sidebar{
//         width: max-content;
//         .bfc-sidebar__profile{
//           visibility: hidden!important;
//         }
//       }    }
//     .sidebar__scroll{
//       width: 55px!important;
//     }
//     .sidebar__link {
//       width: 55px!important;
//       .sidebar__link-title{
//         margin-left: 70px!important;
//       }
//     }
//   }
//   .sidebar--collapse{
//     width: 240px!important;
//     visibility: visible!important;
//     .sidebar__content{
//       width: 240px!important;
//       .bfc-sidebar{
//         width: auto;
//         .bfc-sidebar__profile{
//           visibility: visible!important;
//         }
//       }
//     }
//     .sidebar__scroll{
//       width: 240px!important;
//     }
//     .sidebar__link {
//       width: 240px!important;
//       visibility: visible!important;
//       .sidebar__link-title{
//         margin-left: 0px!important;
//       }
//     }
//   }
// }
// @media (max-width: 576px){
//   .sidebar {
//     display: none!important;
//   }
//   .sidebar.sidebar--show {
//     display: block!important;
//   }
//   .sidebar.sidebar--collapse {
//     width: 55px!important;
//   }
// }
