:root {
  /* Colors: */
  --unnamed-color-1b62ab: #1b62ab;        //Done
  --unnamed-color-4ce1b6: #4ce1b6;        //Done   not replace
  --unnamed-color-ffffff: #ffffff;        //Done
  --unnamed-color-000: #000;              //Done
  --unnamed-color-707070: #707070;        //Done  like #858585
  --unnamed-color-afafaf: #afafaf;        //Done  like #b8b8b8, b0b0b0
  --unnamed-color-ccc: #ccc;              //Done  like lightgrey
  --unnamed-color-e7e2e2: #e7e2e2;        //Done  like #dfdfdf, #e0e0e0
  --unnamed-color-f2f2f2: #f2f2f2;        //Done  like #f1f2f3
  --unnamed-color-3c763d: #3c763d;        //Done
  --unnamed-color-32a836: #32a836;        //Done  like #4fb635
  --unnamed-color-549858: #549858;        //Done
  --unnamed-color-00ae11: #00ae11;        //Done   like #599906, 51a837var(--unnamed-color-90d632)
  --unnamed-color-90d632: #90d632;        //Done
  --unnamed-color-EAFFF4: #EAFFF4;        //Done   Wholesale card bg
  --unnamed-color-F1F5FB: #F1F5FB;        //Done   Normal card bg
  --unnamed-color-d1f6ff: #d1f6ff;        //Done
  --unnamed-color-F1B043: #F1B043;        //Done
  --unnamed-color-BCAE00: #BCAE00;        //Done
  --unnamed-color-ecdd22: #ecdd22;        //Done
  --unnamed-color-FFF6C0: #FFF6C0;        //Done
  --unnamed-color-811e00: #811e00;        //Done   like #720404
  --unnamed-color-00bfa5: #00bfa5;        //Done   like #00B3A0
  --unnamed-color-00c4be: #00c4be;        //Done
  --unnamed-color-51c4c3: #51c4c3;        //Done   like #00b4b3
  --unnamed-color-aadcf9: #AADCF9;        //Done
  --unnamed-color-1890ff: #1890ff;        //Done   Filter icon
  --unnamed-color-70bbfd: #70bbfd;        //Done   like #1890ff
  --unnamed-color-red: red;               //Done
  --unnamed-color-d11010: #d11010;        //Done
  --unnamed-color-f44336: #f44336;        //Done
  --unnamed-color-ffc0cb: #FFC0Cb;        //Done
  --unnamed-color-DF6D6D: #DF6D6D;        //Done
  --unnamed-color-ad4444: #ad4444;        //Done
  --unnamed-color-21234c: #21234c;        //Done

  --unnamed-box-shadow: 0px 0px 5px #0000001a;

  //Toast colors
  --toastify-color-info: #1b62ab!important;
  --toastify-color-success: #32a836!important;

  // New MUI colors
  --mui_color_primary: #886bd8;
  --mui_color_secondary: #3fa7aa;
  --mui_color_ternary: #f37759;
}
