.commodity-wrapper {
  .row1 {
    padding-top: 20px;
    padding-right: 20px;
  }
  .row2 {
    // padding-top: 20px;
    padding-right: 20px;
  }


}

.workflow-edit:hover{

  cursor: pointer;

}

.text-theme-light {
  color: #707070;
}

.text-theme-dark {
  color: #000000 !important;
}

.text-theme-blue {
  color: #1B62AB !important;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
