.bfc-table-list-components {
  .commodity-form {
    text-align: center;
    width: 100%;
    a {
      color:  var(--unnamed-color-1b62ab);
    }
  }

  .commodity-field {
    text-align: left;
    .commodity-code {
      font-weight: bold;
    }
  }

  .inovice-field {
    text-align: center;
    .commodity-code {
      color: var(--unnamed-color-1b62ab);
    }
  }
  .quantity-field {
    max-width: 70px;
    text-align: right;
    .periodicity-enum-code {
      text-align: left;
      color: var(--unnamed-color-707070);
      font-size: var(--fs-base__five);
      display: flex;
      justify-content: flex-end;
    }
  }

  .closing-date-field {
    text-align: right;
    font-weight: normal;
    .closing-time {
      color: var(--unnamed-color-707070);
      font-weight: normal;
    }
  }

  .delivery-date-field {
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: normal;
    color: var(--unnamed-color-707070);
    .label {
      display: flex;
      flex-direction: column;
      margin-right: 1px;
      text-align: left;
      color: #d7d7d7;
    }
    .value {
      display: flex;
      flex-direction: column;
      text-align: right;
      min-width: 70px;
    }
  }

  .price-field {
    font-weight: normal;
    text-align: center;
    max-width: 125px;
    .price-enum {
      color: var(--unnamed-color-707070);
      font-size: var(--fs-base__five);
    }
  }
  .price-red-field {
    font-weight: normal;
    text-align: right;
    max-width: 125px;
    color: var(--unnamed-color-f44336);
  }
  .price-green-field {
    font-weight: normal;
    text-align: right;
    max-width: 125px;
    color: #599906;
  }
  .price-black-field {
    font-weight: normal;
    text-align: right;
    max-width: 125px;
    color: var(--unnamed-color-000);
  }
  .price-field-payment {
    font-weight: normal;
    text-align: right;
    max-width: 125px;
    .price-enum {
      color: var(--unnamed-color-707070);
    }
  }
  .price-green-payment {
    font-weight: normal;
    text-align: right;
    max-width: 125px;
    color: #599906;
  }
  .price-red-payment {
    font-weight: normal;
    text-align: right;
    max-width: 125px;
    color: var(--unnamed-color-f44336);
  }
}

.text-theme-light {
  color: #707070;
}

.text-theme-dark {
  color: #000000 !important;
}

.text-weight-bold {
  font-weight: bold;
}

.text-theme-blue {
  color: #1B62AB !important;
}

.ellipsis-text-commodity {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px !important;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
