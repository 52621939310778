:root {
    // Fixed sizes
    --fs-base__seventy-two: 4.5rem;      // 72px
    --fs-base__sixty-four: 4rem;         // 64px
    --fs-base__fifty-two: 3.25rem;       // 52px
    --fs-base__fourty-eight: 3rem;       // 48px
    --fs-base__thirty-two: 2rem;      // 32px
    --fs-base__thirty: 1.875rem;          // 30px
    --fs-base__twenty-four: 1.5rem;     // 24px
    --fs-base__zero: 1.25rem;            // 20px
    --fs-base__one: 1.125rem;            // 18px
    --fs-base__two: 1rem;                // 16px
    --fs-base__three: .875rem;           // 14px
    --fs-base__four: .75rem;             // 12px
    --fs-base__five: .625rem;            // 10px
}
// Desktop 
@media screen and (min-width: 1200px) {
    :root {
        --fs-base__seventy-two: 4.5rem;
        --fs-base__sixty-four: 4rem; 
        --fs-base__fifty-two: 3.25rem;
        --fs-base__fourty-eight: 3rem; 
        --fs-base__thirty-two: 2rem;
        --fs-base__thirty: 1.875rem;
        --fs-base__twenty-four: 1.5rem;
        --fs-base__zero: 1.25rem;
        --fs-base__one: 1.125rem;
        --fs-base__two: 1rem;
        --fs-base__three: .875rem;
        --fs-base__four: .75rem;
        --fs-base__five: .625rem;
    }
}
// Tablet landscape
@media screen and (min-width: 992px) and (max-width: 1199px) {
    :root {
        --fs-base__seventy-two: 4.4375rem;
        --fs-base__sixty-four: 3.9375rem; 
        --fs-base__fifty-two: 3.1875rem;
        --fs-base__fourty-eight: 2.9375rem; 
        --fs-base__thirty-two: 1.9375rem;
        --fs-base__thirty: 1.8125rem;
        --fs-base__twenty-four: 1.4375rem;
        --fs-base__zero: 1.1875rem;
        --fs-base__one: 1.0625rem;
        --fs-base__two: 0.9375rem;
        --fs-base__three: 0.8125rem;
        --fs-base__four: 0.6875rem;
        --fs-base__five: 0.5625rem;
    }
}
// Table portrait
@media screen and (min-width: 769px) and (max-width: 991px) {
    :root {
        --fs-base__seventy-two: 4.375rem;
        --fs-base__sixty-four: 3.875rem; 
        --fs-base__fifty-two: 3.125rem;
        --fs-base__fourty-eight: 2.875rem; 
        --fs-base__thirty-two: 1.875rem;
        --fs-base__thirty: 1.75rem;
        --fs-base__twenty-four: 1.375rem;
        --fs-base-__zero: 1.1875rem;
        --fs-base__one: 1.0625rem;
        --fs-base__two: 0.9375rem;
        --fs-base__three: 0.8125rem;
        --fs-base__four: 0.6875rem;
        --fs-base__five: 0.5625rem;
    }
}
// Mobile
@media screen and (max-width: 768px) {
    :root {
        --fs-base__seventy-two: 4.3125rem;
        --fs-base__sixty-four: 3.8125rem; 
        --fs-base__fifty-two: 3.0625rem;
        --fs-base__fourty-eight:2.8125rem; 
        --fs-base__thirty-two: 1.8125rem;
        --fs-base__thirty: 1.6875rem;
        --fs-base__twenty-four: 1.3125rem;
        --fs-base__zero: 1.125rem;
        --fs-base__one: 1rem;
        --fs-base__two: 0.875rem;
        --fs-base__three: 0.75rem;
        --fs-base__four: 0.625rem;
        --fs-base__five: 0.5rem;
    }
}
// Mobile
@media screen and (max-width: 576px) {
    :root {
        --fs-base__seventy-two: 4.3125rem;
        --fs-base__sixty-four: 3.8125rem; 
        --fs-base__fifty-two: 3.0625rem;
        --fs-base__fourty-eight:2.8125rem; 
        --fs-base__thirty-two: 1.8125rem;
        --fs-base__thirty: 1.6875rem;
        --fs-base__twenty-four: 1.3125rem;
        --fs-base__zero: 1.125rem;
        --fs-base__one: 1rem;
        --fs-base__two: 0.875rem;
        --fs-base__three: 0.75rem;
        --fs-base__four: 0.625rem;
        --fs-base__five: 0.5rem;
    }
}