.bfc-html {
  .bfc-invoice-header {
    padding: 10px;
    margin-bottom: 0;
    .top-content {
      margin-bottom: 0 !important;
    }
    .title {
      display: flex;
      span {
        img {
          width: 25px;
          margin-right: 15px;
        }
      }
      p {
        margin: 0;
        display: flex;
        color: var(--unnamed-color-1b62ab);
        font-size: var(--fs-base__two);
        flex-direction: column;
        .big-text {
          display: inline-block;
          line-height: 1;
          margin: 0 0 5px 0;
        }
        .small-text {
          font-size: var(--fs-base__four);
          display: inline-block;
          line-height: 1;
          margin: 0 0 0 0;
        }
      }
    }

    .print-btn-wrapper {
      .download-btn {
        display: inline-block;
        margin-right: 20px;
        img {
          width: 20px;
        }
      }
      .print-btn {
        display: inline-block;
        margin-right: 20px;
        img {
          width: 20px;
        }
      }
    }

    .close-btn {
      position: relative;
      top: -15px;
      img {
        width: 19px;
      }
    }
  }
  .bfc-invoice {
    padding: 30px 72px;
    @media (max-width: 1336px) {
      padding: 30px 15px;
    }
    .invoice-wrapper {
      table table table {
        table-layout: auto;
      }
      table {
        width: 100%;
        border-spacing: 0 !important;
        border-collapse: collapse !important;
        table-layout: fixed !important;
        margin: 0 auto !important;
        &.border-table {
          border: 1px solid var(--unnamed-color-000);
        }
        .border-all {
          border: 1px solid var(--unnamed-color-000);
        }
        .border-r {
          border-right: 1px solid var(--unnamed-color-000);
        }
        .border-b {
          border-bottom: 1px solid var(--unnamed-color-000);
        }
        .border-l {
          border-left: 1px solid var(--unnamed-color-000);
        }
        .border-t {
          border-top: 1px solid var(--unnamed-color-000);
        }

        .border-r0 {
          border-right: none;
        }
        .border-b0 {
          border-bottom: none;
        }
        .border-l0 {
          border-left: none;
        }
        .border-t0 {
          border-top: none;
        }
        tr {
          td {
            color: var(--unnamed-color-000);
            font-family: var(--unnamed-font-segoeUI);
            // @include Font-Medium;
            font-size: var(--fs-base__two);
            padding: 15px;
            p,
            span,
            h6 {
              color: var(--unnamed-color-000);
              font-size: var(--fs-base__two);
              margin: 0;
              line-height: 1.3;
              // @include Font-Medium;
              // font-family: Noah-bold;
            }
            .big-text {
              font-size: var(--fs-base__one);
              color: var(--unnamed-color-000);
              font-family: var(--unnamed-font-segoeUI);
              // @include Font-Semibold;
            }

            &.p-0 {
              padding: 0;
            }

            &.address {
              .big-text {
                margin-bottom: 10px;
              }
            }
          }
          .font-bold {
            font-family: var(--unnamed-font-segoeUI);
          }
          .font-bolditalic {
            font-family: var(--unnamed-font-segoeUI);
          }
        }

        .edittext-icon {
          display: flex;
          justify-content: space-between;
          span {
            img {
              width: 12px;
            }
          }
        }
      }
      .invoice-details {
        tr {
          td {
            padding: 10px 15px;
            p {
              font-size: var(--fs-base__three);
              &.text {
                margin-bottom: 5px;
              }
            }
            &.bank-td {
              height: 50px;
            }
          }
        }
      }

      //
      .description-goods {
        td,
        p {
          font-size: var(--fs-base__three);
          line-height: 1.3;
        }
        p {
          white-space: nowrap;
        }
        .title-td {
          padding: 8px 15px;
        }
        .td-slno {
          width: 50px;
        }
        .td-description {
        }
        .td-hsn {
          width: 110px;
        }
        .td-qty {
          width: 200px;
        }
        .td-rate {
          width: 100px;
        }
        .td-per {
          width: 80px;
        }
        .td-amount {
          width: 120px;
        }
      }

      //
      .tax-table {
        td,
        p {
          font-size: var(--fs-base__three);
          line-height: 1.3;
        }
        .title-td {
          padding: 8px 15px;
        }
        .t-nowrap {
          white-space: nowrap;
        }
        .td-taxvalue {
          width: 110px;
        }
        .td-centraltax {
          width: 200px;
        }
        .td-statetax {
          width: 180px;
        }
        .td-totaltax {
          width: 120px;
        }
      }

      //
      .declaration-bank-wrapper {
        display: flex;
        justify-content: space-between;
        .left-content {
          flex: 1;
          max-width: 300px;
          p {
            font-size: var(--fs-base__four);
          }
          .title {
            margin-bottom: 5px;
            text-decoration: underline;
          }
        }
        .right-content {
          flex: 1;
          max-width: 400px;
          .title {
            font-family: var(--unnamed-font-segoeUI);
            margin-bottom: 3px;
          }
          p {
            font-family: var(--unnamed-font-segoeUI);
            font-size: var(--fs-base__three);
            .bank-title {
              font-family: var(--unnamed-font-segoeUI);
              display: inline-block;
              min-width: 140px;
            }
          }
        }
      }

      //
      .signature-wrapper {
        p {
          font-size: var(--fs-base__two);
        }
        .c-sign {
          padding: 5px 15px;
        }
        .a-sign {
          padding: 5px 15px;
          .title {
            margin-bottom: 50px;
          }
          p {
            text-align: right;
          }
        }
      }

      //
      .notes-block {
        p {
          font-size: var(--fs-base__two);
          color: var(--unnamed-color-ccc);
          text-align: center;
        }
      }

      //
      .amount-chargeable {
        .left-td {
          .small-text {
            font-size: var(--fs-base__three);
            margin-bottom: 5px;
          }
          .big-text {
            font-size: var(--fs-base__two);
          }
        }
        .right-td {
          p {
            font-size: var(--fs-base__two);
            text-align: right;
          }
        }
      }

      //
      .tax-amount {
        p {
          font-family: var(--unnamed-font-segoeUI);
          font-size: var(--fs-base__two);
          span {
            font-family: var(--unnamed-font-segoeUI);
            font-size: var(--fs-base__three);
            display: inline-block;
            margin-right: 30px;
          }
        }
      }
    }
  }
}
