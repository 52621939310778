$color-white: #fff;
$color-black: black;
$bg-blue: #1b62ab;
$text-color: var(--unnamed-color-1b62ab);
$box-border: 3px solid var(--unnamed-color-ccc);
$box-padding: 15px;

.list {
  line-height: 40px;
  li {
    color: var(--unnamed-color-afafaf);
  }
  .tooltip_text {
    color: var(--unnamed-color-afafaf);
    font-size: var(--fs-base__one);
    font-weight: normal;
  }
}
.tooltip_text .tooltip-hover {
  visibility: hidden;
  font-size: var(--fs-base__three);
  font-weight: 400;
  width: auto;
  font-family: var(--unnamed-font-segoeUI);
  margin: 5px 0px;
  color: var(--unnamed-color-afafaf) !important;
  text-align: left;
  padding: 5px;
  position: absolute;
  z-index: 999999999999 !important;
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 5px 2px rgba(151, 142, 142, 0.2);
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  opacity: 1;
}
.tooltip_list {
  padding: 0px 0px;
  line-height: 15px;
}
.tooltip_text:hover .tooltip-hover {
  visibility: visible;
}

.inovice-field {
  .tooltip-info-icon {
    .tooltip-hover {
      background-color: #e8ffe9;
      color: var(--unnamed-color-afafaf);
      margin-top: 0px;
      margin-left: 10px;
      border-radius: 10px 10px 10px 0px;
      min-width: max-content;
    }
  }
}
.delivery-contract-info-icon {
  .tooltip-hover {
    width: 100%;
    margin-top: -8px;
    margin-left: 5px;
  }
}
.upload-tooltip{
  .tooltip-hover {
    width: 100%;
    margin-top: -40px;
    margin-left: -100px;
    min-width: fit-content;
  }
}

