// Table scroll height
.invoice-table {
  .ant-table-body {
    height: calc(100vh - 216px);
    max-height: 100% !important;
  }
}

.new-customize-table {
  .ant-table-body {
    height: calc(100vh - 266px);
    max-height: 100% !important;
  }
}
.new_cashflow-table {
  .ant-table-body {
    height: calc(100vh - 275px);
    max-height: 100% !important;
  }
}
.aggregation-table {
  .ant-table-body {
    height: calc(100vh - 216px);
    max-height: 100% !important;
  }
}
.zoho-table {
  .ant-table-body {
    height: calc(100vh - 120px);
    max-height: 100% !important;
  }
}

.bulk-record-payment-table {
  .ant-table-body {
    height: calc(100vh - 269px);
    max-height: 100% !important;
  }
}

.record-payment-table {
  .ant-table-body {
    height: calc(100vh - 262px);
    max-height: 100% !important;
    .ant-table-expanded-row {
      .ant-table-cell {
        padding: 8px 8px 8px 0px !important;
        .record-payment__expand {
          .ant-table {
            margin: -8px -8px -8px 0px !important;
            .ant-table-tbody {
              .ant-table-row {
                .invoice_number_column {
                  width: 203px;
                }
              }
            }
          }
        }
        .record-payment__on-expand {
          @extend .record-payment__expand;
          .fixed {
            left: 50px !important;
          }
          .invoice_number_column {
            left: 76px !important;
          }
        }
      }
      .ant-table-selection-column {
        .ant-checkbox-wrapper {
          margin-left: 25px !important;
        }
      }
    }
  }
}
// if only control tower table
.cfollow-reference-table {
  .ant-table-body {
    height: calc(100vh - 220px);
    max-height: 100% !important;
  }
}
// end if only control tower table
.follow-reference-table {
  .ant-table-body {
    height: calc(100vh - 200px);
    max-height: 100% !important;
  }
}
.invoice-sub-table {
  .ant-table-body {
    height: calc(100vh - 295px);
    max-height: 100% !important;
  }
}
.deals-table {
  .ant-table-body {
    height: calc(100vh - 211px);
    max-height: 100% !important;
  }
}
.view-statement-table {
  .ant-table-body {
    height: calc(100vh - 210px);
    max-height: 100% !important;
  }
}

.payment-table {
  .ant-table-body {
    @extend .invoice-table;
  }
}
.buy-table {
  .ant-table-body {
    @extend .invoice-table;
  }
}
.deals-sub-table {
  .ant-table-body {
    height: calc(100vh - 290px);
    max-height: 100% !important;
  }
}
.delivery-table {
  .ant-table-body {
    height: calc(100vh - 270px);
    max-height: 100% !important;
  }
}
.delivery-sub-table {
  .ant-table-body {
    @extend .invoice-sub-table;
  }
}
.payments-sub-table {
  .ant-table-body {
    @extend .invoice-sub-table;
  }
}
.offer-bid-table {
  .ant-table-body {
    height: calc(100vh - 295px);
    max-height: 100% !important;
  }
}
.transport-deal-table {
  .ant-table-body {
    @extend .invoice-table;
  }
}
.driverlist-table {
  .ant-table-body {
    @extend .invoice-table;
  }
}
.fuel-management-table {
  .ant-table-body {
    @extend .new-customize-table;
  }
}
.vehiclelist-table {
  .ant-table-body {
    @extend .invoice-table;
  }
}

.kyc-table {
  .ant-table-body {
    height: calc(100vh - 210px);
    max-height: 100% !important;
  }
}
.registered-user-table {
  .ant-table-body {
    height: calc(100vh - 210px);
    max-height: 100% !important;
  }
}
.platform-charges-report {
  .ant-table-body {
    height: calc(100vh - 229px);
    max-height: 100% !important;
  }
}
.discount-plan-table {
  .ant-table-body {
    @extend .invoice-table;
  }
}
.quality-service-table {
  .ant-table-body {
    height: calc(100vh - 210px);
    max-height: 100% !important;
  }
}
.plan-table {
  .ant-table-body {
    height: calc(100vh - 280px);
    max-height: 100% !important;
  }
}
.data-setup-table {
  .ant-table-body {
    height: calc(100vh - 270px);
    max-height: 100% !important;
  }
}
.preferred-table {
  .ant-table-body {
    height: calc(100vh - 215px);
    max-height: 100% !important;
  }
}
.blocked-table {
  .ant-table-body {
    height: calc(100vh - 215px);
    max-height: 100% !important;
  }
}
.auction__deals-table-body {
  .ant-table-body {
    @extend .invoice-table;
    // height: calc(100vh - 245px);
    // max-height: 100% !important;
  }
}
.auction__buy-table {
  .ant-table-content {
    overflow: scroll !important;
    height: calc(100vh - 300px);
  }
}
.platform-charges-table {
  .ant-table-body {
    height: calc(100vh - 270px);
    max-height: 100% !important;
  }
}
.configuration-table {
  .ant-table-body {
    @extend .invoice-table;
  }
}
.transport-table {
  .ant-table-body {
    height: calc(100vh - 380px);
    max-height: 100% !important;
  }
}
.finance-invoice-table {
  .ant-table-body {
    height: calc(100vh - 310px);
    max-height: 100% !important;
  }
}
.finance-deals-table {
  .ant-table-body {
    height: calc(100vh - 310px);
    max-height: 100% !important;
  }
}
.tab-body-table {
  .ant-table-body {
    height: calc(100vh - 210px);
    max-height: 100% !important;
  }
}
.borg-finance-deals-table {
  .ant-table-body {
    height: calc(100vh - 310px);
    max-height: 100% !important;
  }
}
.plan-list {
  .ant-table-body {
    height: calc(100vh - 228px);
    max-height: 100% !important;
  }
  .storage-datepicker {
    .ant-picker-dropdown{
      top:-20px !important;
    }
  }
}
.ledger-table {
  .ant-table-body {
    height: calc(100vh - 341px);
    max-height: 100% !important;
    scroll-behavior: smooth;
    .ant-table-thead {
      .ant-table-cell {
        z-index: 0;
      }
    }
  }
  .amount_to_pay {
    input {
      text-align: center;
    }
  }
}

.storage-list {
  .ant-table-body {
    height: calc(100vh - 282px);
    max-height: 100% !important;
  }
  .plan-list {
    .storage-datepicker {
      .ant-picker-dropdown{
        top:-20px !important;
      }
    }
  }
}
@media only screen and (max-width: 776px) {
  .aggregation-table {
    .ant-table-body {
      height: calc(100vh - 295px) !important;
      max-height: 100% !important;
    }
  }
}
@media only screen and (min-width: 777px) and (max-width: 991px) {
  .aggregation-table {
    .ant-table-body {
      height: calc(100vh - 295px);
      max-height: 100% !important;
    }
  }
}
@media only screen and (min-width: 1677px) {
  .delivery-table {
    .ant-table-body {
      height: calc(100vh - 312px);
      max-height: 100% !important;
    }
  }
  .offer-bid-table {
    .ant-table-body {
      height: calc(100vh - 305px);
      max-height: 100% !important;
    }
  }
  .deals-sub-table {
    .ant-table-body {
      height: calc(100vh - 305px);
      max-height: 100% !important;
    }
  }
  .kyc-table {
    .ant-table-body {
      height: calc(100vh - 220px);
      max-height: 100% !important;
    }
  }
  .plan-table {
    .ant-table-body {
      height: calc(100vh - 290px);
      max-height: 100% !important;
    }
  }
  .data-setup-table {
    .ant-table-body {
      height: calc(100vh - 280px);
      max-height: 100% !important;
    }
  }
  .delivery-sub-table {
    .ant-table-body {
      height: calc(100vh - 310px);
      max-height: 100% !important;
    }
  }
  .invoice-sub-table {
    .ant-table-body {
      height: calc(100vh - 310px);
      max-height: 100% !important;
    }
  }
  .payments-sub-table {
    .ant-table-body {
      height: calc(100vh - 340px);
      max-height: 100% !important;
    }
  }
  .auction__buy-table {
    .ant-table-content {
      overflow: scroll !important;
      height: calc(100vh - 280px);
    }
  }
  .platform-charges-table {
    .ant-table-body {
      height: calc(100vh - 280px);
      max-height: 100% !important;
    }
  }
  .tab-body-table {
    .ant-table-body {
      height: calc(100vh - 220px);
      max-height: 100% !important;
    }
  }
  .cfollow-reference-table {
    .ant-table-body {
      height: calc(100vh - 220px);
      max-height: 100% !important;
    }
  }
}
.ant-table {
  font-family: var(--unnamed-font-segoeUI) !important;
  color: var(--unnamed-color-000) !important;
  font-size: var(--fs-base__three) !important;
}
.ant-btn-link {
  color: var(--unnamed-color-1b62ab) !important;
}
.pagination-skeleton {
  // width: 350px;
  .ant-skeleton-content {
    .ant-skeleton-title {
      height: 35px !important;
    }
  }
}
.button-section {
  padding: 0px 4px;
  .ant-btn {
    font-size: var(--fs-base__three);
  }
}
.report-btn {
  margin-left: 10px;
}
.paganation-btn {
  // margin-top: 10px;
}
.bottom-pagination {
  padding: 10px !important;
}
.ant-table-thead .ant-table-cell {
  font-weight: bold;
  font-family: var(--unnamed-font-segoeUI) !important;
  text-align: center;
  font-size: var(--fs-base__three);
}
.ant-table-tbody .ant-table-cell {
  .bfc-table-list-components {
    font-size: var(--fs-base__three);
  }
}
.ant-child-table-row {
  background-color: #f7fcff;
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
}
// .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
// .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
//   margin: -16px -16px -16px 70px !important;
// }
.expand-table {
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: #f7fcff;
    :hover {
      background-color: #fafafa;
    }
  }
  .ant-spin-nested-loading {
    background-color: #f7fcff;
    :hover {
      background-color: #fafafa;
    }
  }
}
.ant-table {
  .ant-table-expanded-row {
    .ant-table-cell {
      padding: 16px 8px !important;
    }
  }
}
.ant-tag {
  font-size: var(--fs-base__three);
}
.ant-dropdown {
  min-width: auto !important;
}
.ant-dropdown-menu {
  list-style-image: none;
  box-shadow: 0px 0px 10px 0px rgba(62, 28, 131, 0.1) !important;
}
.anticon {
  display: block !important;
  font-family: var(--unnamed-font-segoeUI);
}
.anticon-search {
  display: inline !important;
}
.ant-pagination-prev {
  font-family: var(--unnamed-font-segoeUI) !important;
}
.ant-pagination-next {
  font-family: var(--unnamed-font-segoeUI) !important;
}
// .ant-table-filter-dropdown {
//   width: 200px !important;
//   .anticon {
//     svg {
//       margin-top: -5px !important;
//     }
//   }
// }
.ant-form-item-control-input-content {
  flex: auto;
  max-width: fit-content !important;
}
.ant-input-number-lg input {
  height: 30px !important;
}
.ant-select {
  display: block !important;
  width: 110px;
  top: 50%;
}
// .ant-select-dropdown-placement-bottomLeft {
//   width: 185px !important;
// }
.ant-input-number-lg {
  width: 120px;
  height: 30px;
}
.action-text {
  margin-top: 5px;
  margin-left: 10px;
  .cancel-text {
    cursor: pointer;
    color: var(--unnamed-color-1b62ab);
  }
  .save-text {
    cursor: pointer;
    color: var(--unnamed-color-1b62ab);
    margin-left: 10px;
  }
}
.ant-pagination {
  .ant-pagination-total-text {
    font-size: var(--fs-base__three);
  }
  a {
    color: var(--unnamed-color-000) !important;
  }
  .ant-pagination-options {
    .ant-select-selector {
      .ant-select-selection-item {
        font-size: var(--fs-base__three);
      }
    }
  }
}
.ant-progress-circle {
  .ant-progress-text {
    font-weight: 600;
  }
}

.invoice_number_column {
  position: sticky !important;
  z-index: 3;
  left: 45px !important;
}

.loader {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
}
@keyframes load {
  50% {
    transform: rotatez(180deg) scale(0.2);
    border-color: #4ce1b6;
  }
  100% {
    transform: rotatez(360deg) scale(1);
  }
}
.loader1::before {
  content: '';
  color: white;
  height: 40px;
  width: 40px;
  background: transparent;
  border-radius: 45%;
  border: 13px dotted #328fef;
  animation: load 1s infinite ease-in-out;
}

.pickup-offer-table {
  .ant-table-body {
    height: calc(100vh - 314px);
    max-height: 100% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .aggregation-table .ant-table-body {
    height: calc(100vh - 300px) !important;
    // max-height: 100% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .delivery-table .ant-table-body {
    height: calc(97vh - 300px) !important;
    // max-height: 100% !important;
  }
}
