.list-view-header {
  display: flex;
  flex: 1;
  // background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 15px #0000001a;
  // border-radius: 10px 10px 0px 0px;

  // .tabs-primary {
  //   ul {
  //     li {
  //       a:after {
  //         bottom: -25px;
  //       }
  //     }
  //   }
  // }

  .right-content-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
    flex: 1;
    .switch-auctions{
      position: relative;
      bottom: 5px;
      .auctions-switch-label{
        margin-right: 10px;
        color: #599906;
        margin-bottom: 0px;
        .MuiSwitch-root{
          margin: 0px!important;
        }
        .MuiTypography-body1{
          font-size: var(--fs-base__four)!important;
        }
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
          background-color: #599906!important;
        }
      }  
    }
    .text-link {
      text-align: left;
      text-decoration: underline;
      letter-spacing: 0px;
      color: var(--unnamed-color-1b62ab);
      font-size: var(--fs-base__four);
      white-space: nowrap;
      padding: 0 10px;
    }
    .dropdown-button-group{
      .right-toggle{
        width: 0px;
        min-width: 0px;
        .icon{
          margin-left: -12px;
        }
      }
    }
    .search-box {
      display: none;
      padding: 0 10px;
      input {
        border: 1px solid #b5b5b5;
        padding: 10px 15px;
      }
    }
    .btn {
      min-width: 60px;
      padding: 5px 25px;
    }
    .header-location-field{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      .header-location-subfield{
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        .location__width{
          width: 100%;
          margin-left: 20px;
          margin-right: 20px;
          max-width: 20vw;
          .location-field-6{
            display: contents;
            .MuiInputLabel-root{
              color: #599906;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 991px) and (max-width: 1150px) {
  .bfc-header{
    .list-view-header {
      .right-content-box{
       .switch-auctions{
          .auctions-switch-label{
            // margin-top: 10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .bfc-header{
    .list-view-header {
      display: block;
      .left-content-box{
        .tabs-primary{
          display: flex;
          justify-content: center;
        }  
      }
      .right-content-box{
        display: block;
        line-height: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
        .switch-auctions{
          .auctions-switch-label{
            display: flex;
            justify-content: center;
            margin-right: 0px;
            margin-top: 10px;
          }
        }
        .text-link{
          text-align: center;
          margin-bottom: 10px;
        }    
        .dropdown-btn-group{
          display: flex;
          justify-content: center;
        }  
        .dropdown{
          display: flex;
          justify-content: center;
          .btn-group{
            margin-right: 0px;
          }
          .dropdown__menu{
            width: auto;
            min-width: 0px;
            padding: 0px;
            margin-top: 10px;
          }
        }
        .header-location-field{
          .header-location-subfield{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .location__width{
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 991px) {
  .bfc-header{
    .list-view-header {
      .right-content-box{
        display: flex;
        // padding-bottom: 10px;
        .switch-auctions{
          display: flex;
          justify-content: end;
          .auctions-switch-label{
            display: flex;
            justify-content: end;
            margin-right: 0px;
          }  
        }
        .text-link{
          text-align: end;
          margin-bottom: 10px;
        } 
        .dropdown-btn-group{
          display: flex;
          justify-content: end;
        } 
        .dropdown{
          display: flex;
          justify-content: end;
          .btn-group{
            margin-right: 0px;
          }
          .dropdown__menu{
            width: auto;
            min-width: 0px;
            padding: 0px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1150px) {
  .bfc-header{
    .list-view-header {
      .right-content-box{
        display: flex;
        padding-right: 0;
        .auctions-switch-label{
          display: flex;
          justify-content: end;
          margin-right: 10px;
          // margin-top: 10px;
        }
        .text-link{
          text-align: end;
          margin-bottom: 0px;
        } 
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .bfc-header{
    .list-view-header {
      .right-content-box{
        .header-location-field{
          .header-location-subfield{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .location__width{
              max-width: 40vw;
            }
          }
        }
      }
    }
  }
}
