$color-white: #ffffff;
$text-color: var(--unnamed-color-1b62ab);
$color-black: black;
$color-grey: #b8b8b8;
$bg-blue: #1b62ab;
$font-weight: 600;

@mixin SubscribeDetailsblock {
  .list {
    line-height: 25px;
    padding: 0px 0px;
    .blue_bullet-text {
      color: $text-color;
      font-size: var(--fs-base__two);
    }
    .green_bullet-text{
      color: var(--unnamed-color-00bfa5);
      font-size: var(--fs-base__two);
    }
    .fa-li {
      color: #4fb635;
    }
    .tooltip_text {
      color: $text-color;
      font-size: var(--fs-base__three);
      font-weight: normal;
      font-family: var(--unnamed-font-segoeUI) !important;
    }
    .point-subtitle {
      color: #b8b8b8;
      line-height: 20px;
      font-size: var(--fs-base__two);
      margin: 0px;
      width: 100%;
    }
  }
  // .item {
  //   .list {
  //     line-height: 40px;
  //     li {
  //       color: $text-color;
  //     }
  //     .fa-li {
  //       color: green;
  //     }
  //   }
  //   select {
  //     font-size: .875px !important;
  //   }
  // }
}
// Media query
@mixin device($breakpoint) {
  @if $breakpoint == tab-land {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint == tab-mobile {
    @media (max-width: 769px) {
      @content;
    }
  }
  @if $breakpoint == tab-small-mobile {
    @media (max-width: 340px) {
      @content;
    }
  }
}
.details {
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  .plan_name{
    font-size: var(--fs-base__zero);
    color: var(--unnamed-color-1b62ab);
    margin: 0;
  }
  .other_plan{
    @extend .plan_name;
    color: var(--unnamed-color-00bfa5);
  }
  .plan_header--text{
    font-size: var(--fs-base__two);
    margin: 0;
    color: var(--unnamed-color-afafaf);
  }
  .bfc__checkout-page {
    padding: 15px;
    margin-top: 15px;
    .item {
      @include SubscribeDetailsblock;
      .content__oneline {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 15px;
      }
      .top-up__amount{
        .ant-input-affix-wrapper{
          padding: 0px;
          text-align: right;
          .ant-input-prefix{
            margin: 0px;
            background-color: lightgrey;
            padding: 5px;
          }
          .ant-input{
            text-align: right;
            font-weight: 900;
            font-size: 20px;
            color: #1b62ab;
          }
        }
      }
      .caption {
        // padding: 0px !important;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        .icons {
          height: 5vh !important;
          margin: 0px 0px;
        }
        .headline {
          color: $bg-blue;
          font-size: var(--fs-base__zero);
          margin: 10px 15px;
          font-weight: bold;
        }
      }
      .subscribe-headline {
        padding: 0px 52px;
        text-align: left;
        font-size: var(--fs-base__three);
        color: var(--unnamed-color-afafaf);
        line-height: 20px;
      }
      .point-title {
        line-height: 30px;
        font-size: var(--fs-base__two);
        margin: 0px;
        color: $text-color;
      }
      .green-point-title{
        @extend .point-title;
        color: var(--unnamed-color-00bfa5);
      }
      .cross-icon {
        height: 3vh;
        margin-right: 5px;
        margin-top: -2px;
      }
      .option-box {
        width: 60px;
        height: 30px;
        font-size: var(--fs-base__one);
        padding: 2px;
      }
      .apply-coupon {
        cursor: pointer;
        font-weight: bold;
        // border-bottom: 1px dashed #ececec;
        // border-bottom: 2px dashed #1b62ab;
        margin-top: -5px;
        margin-bottom: 5px;
      }
      .point-subtitle {
        @extend .point-title;
        font-size: .625rem;
        color: $color-grey;
        margin: 0px 35px !important;
      }
      .gst-tag {
        font-size: .625rem;
        color: $color-grey;
      }
      .fa-li {
        color: green;
      }
    }
    .view-tranportation-cost {
      .view-transport-anchor {
        @extend .subscribe-headline;
        color: var(--unnamed-color-1b62ab) !important;
        text-decoration: underline;
        font-weight: normal;
        font-size: 1;
      }
    }
    .second-column-details {
      margin-top: -20px;
    }
    select {
      flex: 1;
      cursor: pointer;
      font-size: var(--fs-base__two);
      // margin: 0px 15px;
    }
    .item-title {
      font-size: var(--fs-base__three);
      color: $text-color;
      font-weight: $font-weight;
    }
    .item-price {
      display: flex;
      float: right;
      font-size: var(--fs-base__zero);
      font-weight: bold;
      text-align: right !important;
      font-family: var(--unnamed-font-segoeUI) !important;
      @extend .item-title;
      .price-sub__content {
        position: absolute; 
        right: -50px; 
        bottom: 15px;
      }
      @include device(tab-mobile) {
        .price-sub__content {
          position: absolute;
          right: 15px;
          bottom: 0px;
        }
      }
    }
    .green-item-price{
      @extend .item-price;
      color: var(--unnamed-color-00bfa5);
    }
    .discount-price-label {
      color: var(--unnamed-color-00bfa5);
    }
    .remove-label {
      color: #ab1b1b;
      font-size: var(--fs-base__three);
      cursor: pointer;
    }
    .final-price {
      font-size: var(--fs-base__zero);
      font-weight: bold;
      text-align: right !important;
      font-family: var(--unnamed-font-segoeUI) !important;
      @extend .item-price;
    }
    .green-final-price{
      @extend .final-price;
      color: var(--unnamed-color-00bfa5);
    }
    .subscribe-hr {
      border: 1px solid #f1f0f1 !important;
      width: 100%;
      float: left;
    }
    .border__left {
      border-left: 1px solid $color-grey;
      padding: 0px 50px 0px 50px;
    }
    .total-price {
      float: right;
      margin-bottom: 20px;
      display: inline-block;
      .apply-coupon-btn__blue {
        height: 38px;
        // width: 100%;
        width: max-content;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: var(--unnamed-color-1b62ab);
        border: 1px solid var(--unnamed-color-1b62ab);
        color: var(--unnamed-color-ffffff);
        font-weight: 100;
        font-size: var(--fs-base__two);
      }
      .apply-coupon-btn__green{
        @extend .apply-coupon-btn__blue;
        background-color: var(--unnamed-color-00bfa5);
        border: 1px solid var(--unnamed-color-00bfa5);
      }
    }
    .button-area {
      margin-top: 40px !important;
      .agree {
        font-size: var(--fs-base__three);
        color: $color-grey;
        text-align: center;
        font-weight: $font-weight;
        margin: 15px;
      }
      .blue-btn-biofuel {
        padding: 5px 25px;
        font-size: var(--fs-base__two);
        background-color: var(--unnamed-color-1b62ab);
        width: 100%;
        font-family: var(--unnamed-font-segoeUI) !important;
        font-weight: bold;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        color: $color-white;
        &:hover {
          padding: 10px;
          background-color: darken($bg-blue, 5%);
          color: $bg-blue;
          cursor: pointer;
        }
      }
      button:focus,
      button:active {
        outline: none;
      }
      .green-btn-biofuel{
        @extend .blue-btn-biofuel;
        background-color: var(--unnamed-color-00bfa5);
        &:hover {
          padding: 10px;
          background-color: var(--unnamed-color-00bfa5);
          color: var(--unnamed-color-00bfa5);
          cursor: pointer;
        }
      }
    }
    @include device(tab-land) {
      .content__oneline {
        display: flex;
        flex-wrap: nowrap;
      }
    }
    @include device(tab-mobile) {
      .border__left {
        border-left: none;
        padding: 0;
        // padding: 10px 50px 0px 50px;
      }
    }
    .tooltip_text .tooltip-hover {
      visibility: hidden;
      width: fit-content;
      margin: 0px 50px;
      color: var(--unnamed-color-1b62ab);
      text-align: left;
      padding: 5px;
      position: absolute;
      z-index: 99 !important;
      background: #fefefe 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 5px 2px rgba(151, 142, 142, 0.2);
      border: 1px solid #e9e9e9;
      border-radius: 3px;
      opacity: 1;
    }
    .tooltip_list {
      padding: 0px 0px;
      line-height: 20px;
    }
    .tooltip_text:hover .tooltip-hover {
      visibility: visible;
    }
    .trueIcons {
      // height: 2vh;
      width: 20px;
      height: 15px;
      margin: 0px 10px;
      margin-left: 0px;
    }

  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .details {
    margin-left: 5%;
    margin-right: 5%;
  }
}
// @media only screen and (max-width: 769px) {
//   .details {
//     margin-left: 3%;
//     margin-right: 3%;
//   }
// }
