.commodity-wrapper {
  .row1 {
    padding-top: 20px;
    padding-right: 20px;
  }
  .row2 {
    // padding-top: 20px;
    padding-right: 20px;
  }
}

.custom-styling .ant-modal-close-x {
  margin-top: 15px;
}
