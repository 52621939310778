.theme-light.ltr-support {
  //borderded table
  .bfc-table-box {
    .add-new-btn-box {
      text-align: right;
      margin-bottom: 20px;
      a {
        border: 1px solid #1b62ab;
        border-radius: 8px;
        font-size: var(--fs-base__two);
        padding: 7px 25px 8px;
        display: inline-block;
        box-shadow: 0px 0px 10px #0000001a;
        font-family: var(--unnamed-font-segoeUI);
        line-height: 1;
        .icon {
          display: inline-block;
          margin-right: 5px;
        }
      }

      .add-new-btn {
        border: 1px solid #1b62ab;
        border-radius: 8px;
        font-size: var(--fs-base__two);
        // padding: 7px 25px 8px;
        padding: 10px 25px!important;
        display: inline-block;
        box-shadow: 0px 0px 10px #0000001a;
        font-family: var(--unnamed-font-segoeUI);
        line-height: 1;
        .icon {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    .bfc-table {
      // overflow-wrap: break-word;
      // word-wrap: break-word;
      border: 1px solid #b0b0b0;
      width: 100%;
      border-spacing: 0 !important;
      border-collapse: collapse !important;
      table-layout: fixed !important;
      margin: 0 auto !important;
      table {
        width: 100%;
        border-spacing: 0 !important;
        border-collapse: collapse !important;
        table-layout: fixed !important;
        margin: 0 auto !important;
      }
      .b-0 {
        border: none;
      }
      .p-0 {
        padding: 0;
      }

      .border-all {
        border: 1px solid #b0b0b0;
      }
      .border-r {
        border-right: 1px solid #b0b0b0;
      }
      .border-b {
        border-bottom: 1px solid #b0b0b0;
      }
      .border-l {
        border-left: 1px solid #b0b0b0;
      }
      .border-t {
        border-top: 1px solid #b0b0b0;
      }

      .border-r0 {
        border-right: none;
      }
      .border-b0 {
        border-bottom: none;
      }
      .border-l0 {
        border-left: none;
      }
      .border-t0 {
        border-top: none;
      }
      ////
      thead {
        background-color: var(--unnamed-color-f2f2f2);
        th {
          border: 1px solid #b0b0b0;
          // white-space: nowrap;
          vertical-align: middle;
          text-align: center;
          font-family: var(--unnamed-font-segoeUI);
          // color: var(--unnamed-color-000);
          color: var(--unnamed-color-707070);
          font-size: var(--fs-base__two);
        }
        .th-farm {
          width: 20%;
        }
        .th-address {
          width: 30%;
        }
        .th-crops {
          width: 40%;
        }
        .th-action {
          width: 80px;
        }

        ////
        .th-radio {
          width: 60px;
        }
      }
      tbody {
        tr {
          td {
            border: 1px solid #b0b0b0;
            vertical-align: top;
            text-align: center;
            // font-family: var(--unnamed-font-segoeUI);
            // color: var(--unnamed-color-000);
            font-size: var(--fs-base__two);
            color: var(--unnamed-color-707070);
            font-family: var(--unnamed-font-segoeUI) !important;

            &.td-iconwrapper {
              vertical-align: middle;
              a {
                display: inline-block;
                &:not(:last-child) {
                  margin-right: 10px;
                }
              }
            }

            &.td-radio {
              text-align: center;
              vertical-align: middle;
              .radio-color-btn {
                padding-left: 19px;
                margin-right: 0;
                min-height: 20px;
                margin-top: 3px;
              }
            }
          }
        }
      }

      .td-iconwrapper {
        .icon-group {
          display: flex;
          justify-content: space-around;
          .icon {
            cursor: pointer;
            color: var(--unnamed-color-1b62ab);
          }
        }
      }
    }
  }

  //

  .bfc-list-data-grid {
    .react-grid-Container {
      .react-grid-Toolbar {
        border-color: transparent;
        button {
          font-family: var(--unnamed-font-segoeUI);
          border-color: var(--unnamed-color-4ce1b6);
          letter-spacing: 0.3px;
        }
      }
      //
      .react-grid-Main {
        .react-grid-Grid {
          .react-grid-Header {
            .react-grid-HeaderCell {
              font-family: var(--unnamed-font-segoeUI);
            }
          }
          .react-grid-Viewport {
            .react-grid-Row {
              .react-grid-Cell {
                font-family: var(--unnamed-font-segoeUI);
                button {
                  padding: 5px 15px;
                  font-size: var(--fs-base__three);
                }
                //
                div[title='Published'] {
                  font-size: var(--fs-base__four);
                  color: var(--unnamed-color-32a836);
                  background-color: #eff9f0;
                  border-radius: 3px;
                  padding: 3px 6px;
                  text-transform: uppercase;
                }
                div[title='Closed'] {
                  font-size: var(--fs-base__four);
                  color: #720404;
                  background-color: #fdf5f5;
                  border-radius: 3px;
                  padding: 3px 6px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }

    //
    .grid-pagination {
      ul {
        li {
          &.round-btn {
            a {
              span {
                font-size: var(--fs-base__zero);
                position: relative;
                top: -1px;
              }
            }
            &.disabled {
              a {
                span {
                  opacity: 0.5;
                }
              }
            }
          }
          &.page-item {
            a {
            }
            &.active {
              a {
              }
            }
          }
        }
      }
      .grid-pagination-text {
        span {
          color: #b5b5b5;
        }
      }
    }
  }
}
