.theme-light.ltr-support {
  .form-group-approx-time {
    font-style: italic;
    font-size: var(--fs-base__three);
    color: var(--unnamed-color-1b62ab);
    font-weight: 700;
    margin-top: -10px;
  }

  .no-bids {
    color: var(--unnamed-color-1b62ab);
    font-size: var(--fs-base__two);
    border-radius: 25px;
    font-family: var(--unnamed-font-segoeUI);
    font-weight: 600;
  }

  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: var(--unnamed-color-ffffff);
    color: var(--unnamed-color-000);
    border-radius: 50%;
    height: 25px;
    width: 25px;
    padding: 2px;
    font-size: var(--fs-base__zero);
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '\00d7';
  }

  .btn-center {
    margin-left: 150px;
  }

  .notes_box {
    width: 93%;
    // background-color: #f0f2f5;
  }

  .topbar__button {
    width: 55px;
  }

  .bfc-list-data-grid {
    .react-datepicker__input-container {
      input {
        width: 100%;
        height: 38px;
      }
      .react-datepicker__close-icon::after {
        cursor: pointer;
        background-color: var(--unnamed-color---unnamed-color-ffffff);
        color: var(--unnamed-color-000);
        border-radius: 50%;
        height: 25px;
        width: 25px;
        padding: 2px;
        font-size: var(--fs-base__zero);
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: '\00d7';
      }
    }
  }

  .required {
    color: red;
  }

  .at-css-box {
    .bfc-postBox {
      // background-color:white;
      display: 'flex';
      border-radius: 10px;
      // border: 1px solid grey;
      // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0000001a;
      border: 1px solid #c9c9c9;
      opacity: 1;

      .bfc-editor-trigger {
        height: 100%;

        .placehoder-wrapper {
          width: 100%;
          align-self: center;
          border: 1px solid #dbdbdb;
          border-radius: 34px;
          height: 40px;
          display: flex;
          align-items: center;
          padding: 10px 20px;
          margin-right: 20px;
          background: #f0f2f5;
          cursor: pointer;
          color: var(--unnamed-color-707070);
          font-size: var(--fs-base__four);
          &:hover {
            background: #d5d6d8;
            // TODO : add auto prefixer
            -webkit-transition: background-color 1000ms linear;
            -ms-transition: background-color 1000ms linear;
            transition: background-color 300ms linear;
          }
        }
      }
    }

    .bfc-modal {
      text-align: left;
      padding-left: 30%;
    }

    .bfc-feedAccountIcon {
      // margin-left: 4%;
      // margin-top: 2%;
      // margin-right: 2%;
      // height: 45px;
      margin-left: 25px;
      margin-top: 15px;
      margin-right: 1%;
      width: auto;

      > span {
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    .bfc-postbox-user-info {
      // color: "grey";
      // margin-left: 4%;
      //  margin-top: 2%;
      // height: 70%;
      // width: 8%;
      padding: 10px;
    }

    .bfc-postbox-editor {
      // padding-left: 10px;
      .md-RichEditor-root {
        padding: 5px;
      }
      .md-side-toolbar {
        right: 0;
        left: auto;
      }

      .editor-box {
      }
    }
    .bfc-postBoxCameraIcon {
      // position: absolute;
      // left : 85%;
      // margin-top: 1%;
      color: var(--unnamed-color-1b62ab);
    }

    .bfc-ownProfileCameraIcon {
      position: relative;
      left: -10px;
      top: -5px;
      height: 10px;
      width: 20px;
      color: var(--unnamed-color---unnamed-color-ffffff);
    }

    .bfc-ownProfilePicCameraIcon {
      //  position: absolute;
      //  left: 10px;
      //  top: 10px;
      // height: 10px;
      // width: 20px;
      //

      position: absolute;
      z-index: 999;
      top: 70%;
      // bottom: 10%;
      left: 7%;
      width: 20%;
      height: 40%;
      color: var(--unnamed-color---unnamed-color-ffffff);
    }

    .bfc-postBoxPostButton {
      color: var(--unnamed-color---unnamed-color-ffffff);
      margin-bottom: 2%;
      margin-left: 83%;
      width: 13%;
      height: 25px;
      // background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
    }

    .bfc-category {
      // margin-top: 0.5%;
      // margin-bottom: 1%;
    }

    .bfc-fields {
      background-color: #f0f0f0;
      width: calc((80%)/5);
      border: 1px solid grey;
      // border-radius: 30px;
      height: 70px;
      color: var(--unnamed-color-707070);
      text-align: center;
      margin: 2%;
      // background: transparent url('img/images (3).png') 0% 0% no-repeat padding-box;
      background: transparent 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 9px #0000001a;
      border-radius: 20px;
      opacity: 1;
    }

    .bfc-feed {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      color: var(--unnamed-color-707070);
      margin-top: 2%;
      box-shadow: 0px 0px 10px #0000001a;

      .bfc-feed-carousal {
        // height: 100px;
        .slick-slider {
          margin-bottom: 0px;
          font-size: 50px;
        }
      }
    }

    .slick-slider .slick-arrow:before {
      color: #dddddd;
      font-weight: 500;
      position: absolute;
      top: calc(50% - 15px);
      font-size: 50px;
      line-height: 30px;
      font-family: inherit;
      width: 30px;
      transition: all 0.3s;
    }

    .bfc-uploadImage {
      height: 200px;
      width: 80%;
    }

    .bfc-feedInfo {
      // padding-left: 3%;
      // padding-right: 3%;
      // color: var(--unnamed-color-707070);
      padding: 15px;
      font: normal normal normal 18px/24px Segoe UI;
      letter-spacing: 0px;
      color: var(--unnamed-color-707070);
    }

    .bfc-feed-img {
      margin-left: 8%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .bfc-socialfeedAction {
      // background-color: var(--unnamed-color---unnamed-color-ffffff);
      margin-left: 15px;
      margin-right: 15px;
    }

    .bfc-actionWidth {
      margin-top: 1%;
      margin-bottom: 1%;
      width: calc((100%)/4);
      text-align: center;
    }

    .bfc-LikeButton {
      background-color: var(--unnamed-color-ffffff);
      border: 0px solid #dbdbdb;
      color: var(--unnamed-color-707070);
    }

    .bfc-LikeButtonLiked {
      background-color: var(--unnamed-color-ffffff);
      border: 0px solid #dbdbdb;
      color: var(--unnamed-color-1b62ab);
      font-weight: bold;
    }

    .bfc-action {
      // width: 14.8vw;
      // width: 100%;
      // border: 1px solid grey;
      height: 30px;
      color: var(--unnamed-color-707070);
      text-align: center;
      padding-top: 1%;
    }

    .bfc-socialfeedCommentBox {
      width: 95%;
      height: 30px;
      margin-top: 1%;
      margin-left: 3%;
      color: lightgray;
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    }

    .bfc-socialfeedUserComments {
      margin-left: 1%;
    }

    .bfc-socialfeedMoreComments {
      text-align: center;
      text-decoration-line: underline;
    }

    .bfc-socialfeed-offer {
      background-color: var(--unnamed-color---unnamed-color-ffffff);
      border: 1px solid grey;
      border-radius: 10px;
      margin-top: 2%;
      padding-top: 2%;
    }

    .bfc-offerBanner {
      background-color: var(--unnamed-color---unnamed-color-ffffff);
      border: 1px solid #e0e0e0;
      text-align: center;
      height: 220px;
      font-size: 28px;
      padding-top: 6%;
      margin-bottom: 2%;
    }

    .bfc-socialfeedStats {
      border: 1px solid #e1e1e1;
      margin-bottom: 2%;
    }

    .bfc-cardMember {
      border-radius: 10px;
      border: 1px solid #c9c9c9;
      opacity: 1;
    }

    .bfc-cardPadding {
      padding-left: 3%;
      padding-right: 2%;
    }

    .bfc-type {
      background-color: var(--unnamed-color---unnamed-color-ffffff);
      margin-bottom: 2%;
      margin-top: 2%;
      width: 300px;
    }

    .bfc-suggestions-heading {
      padding-top: 8%;
      padding-bottom: 10%;
      font-size: var(--fs-base__one);
      text-align: center;
      background: #f4f7f6 0% 0% no-repeat padding-box;
      border-radius: 10px 10px 0px 0px;
      opacity: 1;
      color: var(--unnamed-color-1b62ab);
    }

    .bfc-socialfeedButton {
      color: var(--unnamed-color---unnamed-color-ffffff);
      margin-top: 2%;
      padding: 0px 10px 0px 10px;
      width: auto;
      height: 25px;
      background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 10%;
    }

    .bfc-viewMore {
      background-color: var(--unnamed-color-ccc);;
      border: 1px solid var(--unnamed-color-ccc);;
      color: var(--unnamed-color---unnamed-color-ffffff);
      border-radius: 25px;
      margin-top: 2%;
      margin-bottom: 2%;
      margin-left: 52%;
      font-size: var(--fs-base__one);
      width: 85px;
      height: 25px;
    }

    .bfc-socialfeedMoreIcon {
      margin-top: 2%;
      position: absolute;
      right: 2%;
    }

    .bfc-whats-happening-text {
      font-size: 90%;
    }

    .bfc-sidebar-container {
      // background-color:white;
      border: 1px solid var(--unnamed-color-ccc);;
      // height: 400px;
      height: 100%;
      margin-bottom: 10%;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 20px #0000001a;
      border-radius: 10px;
      opacity: 1;
    }

    .bfc-sidebar-heading {
      // margin-left: 10%;
      // margin-right: 10%;
      text-align: center;
      // padding-top: 10%;
    }
    .bfc-sidebarIcon {
      height: 100px;
      width: 100px;
    }

    .bfc-sidebar-titles {
      margin-top: 40px;
    }

    .bfc-sidebar-hr {
      width: 85%;
    }

    .bfc-postBoxSearch {
      width: 70%;
      height: 34px;
      border-radius: 18px;
      border: 1px solid #dbdbdb;
      font-size: var(--fs-base__four);
      line-height: 1.33;
      transition: 0.3s;
      margin-left: 2.5%;

      ///Check
      padding-left: 15px;
      padding-right: 65px;
      color: var(--unnamed-color-707070);

      &:focus,
      &:hover,
      &:active {
        border-color: var(--unnamed-color-4ce1b6);
        outline: none;
        box-shadow: none;
      }
    }

    .bfc-userProfile-name {
      color: red;
      opacity: 1;
    }

    .bfc-userProfileImage {
      width: 25px;
      min-width: 25px;
      height: 25px;
      overflow: hidden;
      border-radius: 50%;
      ///Check
      margin-right: 10px;

      img {
        height: 100%;
        min-width: 100%;
      }
    }

    .bfc-socialfeed-categoryImage {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      position: relative;
      // z-index: -1;
    }

    .bfc-socialfeed-categoryName {
      position: relative;
    }

    .bfc-socialfeed-categoryText {
      position: absolute;
      left: 20%;
      bottom: 5%;
      color: var(--unnamed-color---unnamed-color-ffffff);
    }

    h4 {
      margin-top: 30px;
      text-align: center;
    }

    h4.no-span {
      display: table;
      color: var(--unnamed-color-1b62ab);
      margin-top: 2%;
      width: 96%;
      margin-left: 2%;
      &:before,
      &:after {
        border-top: 1px solid var(--unnamed-color-1b62ab);
        margin: 0 auto;
        content: '';
        display: table-cell;
        position: relative;
        top: 0.5em;
        width: 50%;
      }
      &:before {
        right: 1.5%;
      }
      &:after {
        left: 1.5%;
      }
    }

    .bfc-sidebar-image {
      border-radius: 10px 10px 0px 0px;
      position: relative;
      //  z-index: -1;
    }

    .bfc-sidebar-profileText {
      color: var(--unnamed-color-ffffff);
    }

    .bfc-sidebar-profileName {
      position: absolute;
      top: 10%;
      color: var(--unnamed-color---unnamed-color-ffffff);
      left: 38%;
      text-align: left;
      font-size: var(--fs-base__four);
    }

    .bfc-socialfeed-profilePicture {
      position: absolute;
      top: 13%;
      left: 10%;
      width: 20%;
      height: 12%;
      border-radius: 50%;
    }

    .bfc-moreVertIcon {
      padding-left: 10px;
      padding-right: 0px;
      padding-top: 6px;
      width: auto;
      height: 30px;
      //  background: #F4F7F6 0% 0% no-repeat padding-box;
    }

    .bfc-socialfeedDropdownButton {
      background-color: var(--unnamed-color-ffffff);
      border: 0px solid #dbdbdb;
      color: var(--unnamed-color-707070);
    }

    .bfc-collapse-content {
      width: 120px;
    }

    .bfc-userProfileFollowButton {
      color: var(--unnamed-color---unnamed-color-ffffff);
      margin-bottom: 2%;
      width: auto;
      height: 25px;
      background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 3%;
    }

    .bfc-eventsMember {
      width: 90px;
      min-width: 25px;
      height: 50px;
      overflow: hidden;
      border: 1px solid #ffffff;
      border-radius: 10px;
      opacity: 1;

      ///Check
      margin-right: 10px;

      img {
        height: 100%;
        min-width: 100%;
      }
    }

    .bfc-eventHeading {
      color: var(--unnamed-color-1b62ab);
      margin-bottom: 2%;
      padding: 0px 5px 0px 5px;
      border: 1px solid var(--unnamed-color-1b62ab);
      border-radius: 5px;
      opacity: 1;
    }

    .topbar__collapse-content {
      right: 10px;
      z-index: 10;
    }

    .MuiAutocomplete-option {
      font-size: var(--fs-base__four);
    }

    .MuiAutocomplete-popper {
      font-size: 12rem;
    }

    .blurred-bg-container .content h1 {
      margin: 0;
    }

    .blurred-bg-container .content {
      width: 100%; /* =cw */
      // height: 35%; /* =ch */
      border-radius: 0px 0px 10px 10px;
      // box-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
      // box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      position: absolute;
      overflow: hidden;
      bottom: 0;
    }

    .blurred-bg-container .text {
      // padding: 10px 0px 0px 0px;
      height: 100%;
      box-sizing: border-box; /* so that padding is included in height */
      position: relative;
      border-top: 1px solid var(--unnamed-color-ffffff);
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      opacity: 1;
      backdrop-filter: blur(9px);
      -webkit-backdrop-filter: blur(9px);
    }

    .bfc-otherUsers-profilePicture {
      position: absolute;
      z-index: 1;
      max-width: 100px;

      bottom: 20%;
      left: 3%;

      border-radius: 50%;
      border: 5px solid var(--unnamed-color-ffffff);
    }

    .bfc-otherUsersFollowButton {
      color: var(--unnamed-color---unnamed-color-ffffff);
      padding: 0px 10px 0px 10px;
      width: auto;
      height: 25px;
      background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      border: 2px solid var(--unnamed-color-ffffff);
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 8%;
      top: 10%;
    }

    .bfc-ownUsersEditCoverPicture {
      color: var(--unnamed-color---unnamed-color-ffffff);
      padding: 0px 10px 0px 30px;
      width: 150px;
      height: 30px;
      background-color: transparent;
      // background: #1B62AB 0% 0% no-repeat padding-box;
      border: 2px solid #ffffff;
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 2%;
      top: 10%;
    }

    .bfc-ownUsersEditProfile {
      color: var(--unnamed-color---unnamed-color-ffffff);
      padding: 0px 10px 0px 10px;
      width: auto;
      height: 25px;
      background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      border: 2px solid var(--unnamed-color-ffffff);
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 2%;
      top: 45%;
    }

    .bfc-otherUserMoreIcon {
      top: 10%;
      position: absolute;
      right: 2%;
      color: var(--unnamed-color---unnamed-color-ffffff);
    }

    .bfc-ProfilePicInfo {
      padding-left: 20%;
    }

    .bfc-ProfilePicActions {
      position: relative;
      top: 8%;
      padding-top: 3px;
      opacity: 1;
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
      background: #ffffff40 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0000001a;
      opacity: 1;
    }

    .public-DraftStyleDefault-ltr {
      // width: 20vh;
      border-radius: 18px;
    }

    .public-DraftStyleDefault-block {
      margin: 0px;
    }

    .bfc-socialfeedProfile-image {
      height: 100%;
      border-radius: 10px 10px 0px 0px;
    }

    .bfc-socialfeed-blurred-bg-container {
      background: url(../../shared/img/farm.jpg);
      height: 132px;
      background-size: cover;
    }

    .bfc-socialfeed-blurred-bg-container .content {
      width: 88%;
      height: 11%;
      position: absolute;
      top: 26%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }

    .bfc-socialfeed-blurred-bg-container .text {
      // padding: 10px 0px 0px 0px;
      height: 100%;
      box-sizing: border-box; /* so that padding is included in height */
      position: relative;
      border-top: 1px solid var(--unnamed-color-ffffff);
      // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      opacity: 1;
      backdrop-filter: blur(9px);
      -webkit-backdrop-filter: blur(9px);
    }

    .bfc-socialfeed-profilePicture {
      position: absolute;
      z-index: 1;
      top: 16%;
      left: 12%;
      width: 20%;
      height: 13%;
      border-radius: 50%;
      border: 2px solid var(--unnamed-color-ffffff);
    }

    .bfc-socialfeed-post-camera {
      position: absolute;
      right: 25%;
    }

    ////temporary

    .btn {
      margin-right: 0px;
    }

    .text-editor .DraftEditor-root {
      border: solid 0px #f2f4f7;
    }

    .form input,
    .form textarea {
      border: 2px solid #f2f4f7;
      color: var(--unnamed-color-00bfa5);
    }

    .form input,
    .form textarea {
      border-radius: 0px;
      width: 60%;
    }

    .form__form-group-input-wrap {
      width: 60%;
    }

    .bfc-form-divider {
      background-color: #f4f7f6;
      width: 150%;
      margin-bottom: 20px;
      margin-left: -15%;
      margin-right: -15%;
      padding-left: 10%;
    }

    .bfc-button-next {
      width: 240px;
      height: 38px;
    }

    .bfc-button-anotherAddress {
      width: auto;
      height: 30px;
      background-color: var(--unnamed-color-ffffff);
      margin-left: 75%;
      font-size: var(--fs-base__four);
      padding: 5px;
    }

    .bfc-profile-form-HorizontalLIne {
      width: 70%;
    }

    .bfc-profile-form-tabs {
      margin: 10px;
    }

    .bfc-form-group-label {
      margin-left: 40px;
      margin-top: 5px;
      width: 20%;
      color: var(--unnamed-color-00bfa5);
    }

    .bfc-form-group-label1 {
      width: 60%;
      margin-left: 15px;
    }

    .bfc-form-link {
      margin-left: 40px;
      text-decoration: underline;
    }

    .banner-box {
      .bfc-follow-btn {
        position: absolute;
        // top: 0;
        // right: 0;
        // padding: 15px;
        // button {
        //   box-shadow: 0 0 0px rgba(0, 0, 0, 0.5);
        //   &:hover {
        //     box-shadow: 0 0 3px rgba(0, 0, 0, 0.5) !important;
        //   }
        // }
        &.follow {
          button {
            margin-bottom: 0;
            color: var(--unnamed-color-1b62ab);
            border-color: var(--unnamed-color-1b62ab);
            background: transparent !important;

            // &:focus,
            // &:active {
            //   background: unset;
            //   color: unset;
            // }
          }
        }

        &.unfollow {
          button {
            margin-bottom: 0;
            background: var(--unnamed-color-1b62ab) !important;
            color: var(--unnamed-color-ffffff)!important;
            // &:hover {
            //   background: transparent;
            //   color: var(--unnamed-color-1b62ab);
            // }

            // &:focus,
            // &:active {
            //   background: unset;
            //   color: unset;
            // }
          }
        }
      }
    }

    .transaction-post {
      position: relative;
      .buy-sell-btn {
        position: absolute;
        right: 22px;
        top: 10px;
      }
      .bfc-feedInfo {
        .bfc-feedInfo-row {
          .key {
            padding: 5px;
          }

          .val {
            padding: 5px;
          }
        }
      }
    }

    .social-sidebar {
      .social-sidebar-box {
        .sidebar__block {
          padding-top: 0;
          padding-bottom: 0;
          .sidebar__link {
            p {
              left: calc(50% - 20px);
              color: var(--unnamed-color-1b62ab);
            }

            .bfc-sidebar-hr {
              width: 100%;
            }
          }
        }
      }
    }
  }
}