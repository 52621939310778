/* custom.css */
.custom-phone-input {
  text-indent: -16px; /* Move the text out of the viewable area */
  margin-left: 90px; /* Adjust padding to create space for the pseudo-element */
  position: relative;
  width: calc(100% - 97px);
  padding-top: 6.5px;
  padding-bottom: 6.52px;
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.custom-phone-input::placeholder {
  text-indent: 0px;
  color: lightgray;
  font-size: 15px;
}

.custom-phone-input:hover {
  border: 1px solid black !important;
  border-radius: 3px;
  outline: none;
}

.custom-phone-input:focus-visible {
  border: 2px solid #1b62ab !important;
  border-radius: 3px;
  outline: none;
}

.error-msg__bordered:focus-visible {
  border: 1px solid #f44336 !important;
  border-radius: 3px;
  outline: none;
}

.custom-phone-input::before {
  content: attr(data-country-code); /* Use the data attribute to show the country code */
  position: absolute;
  left: 0px; /* Adjust this value to position the country code */
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px; /* Add space between the country code and the phone number */
  text-indent: 0; /* Reset text-indent for the pseudo-element */
  display: block; /* Ensure it displays as a block element */
}
