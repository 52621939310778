.card_container {
  /* border: 1px solid black; */
  margin: 0px 10%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.card {
  border: 1px solid rgb(150, 150, 150);
  border-radius: 25px;
  height: auto;
  padding: 5px;
  box-shadow: 5px 5px 4px rgb(183, 183, 183);
}

.card_status p {
  font-size: 20px;
  color: black !important;
}

.row_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row_2 {
  display: flex;
  justify-content: end;
}

.row_3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_amount p {
  font-size: 20px;
  color: black !important;
}

.card_amount_date p {
  font-size: 20px;
  color: black !important;
}

.invoice_hyperlinks {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.invoice_hyperlinks_total {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 13px;
}

.invo_hyper {
  color: blue;
  text-decoration: underline;
}

.invoice_hyperlinks .invo_hyper:hover {
  cursor: pointer;
}
