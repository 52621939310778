.theme-light.ltr-support {
  .bfc-social-feed-wrapper {
    .bfc-social-postbox {
      .box__sticky {
        .slider-filter-1 {
          .slick-slider {
            width: 100%;
            margin-bottom: 0px;
            .slick-list {
              box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
              // width: 480px;
              // width: 525px;
              // margin: 16px 20px 10px 20px;
              margin: 5px 0px;
              border-radius: 10px;
              border: 1px solid #c9c9c9;
              .slick-track {
                display: flex;
                justify-content: space-between;
                .slick-slide {
                  // width: auto!important;
                  padding-right: 0px;
                  width: 100% !important;
                  .envira-icon {
                    height: 20px!important;
                    width: 20px!important;
                  }
                }
              }
            }
            .slick-prev {
              left: -35px;
            }
            .slick-next {
              right: -15px;
            }
          }
          .prev {
            left: -30px;
          }
          .next {
            right: -30px;
          }
          .new-icon {
            position: absolute;
            left: -20px;
            top: -10px;
            .new-pack-icon {
              height: 50px;
            }
          }
        }
        .slider-filter-wholesale {
          .slick-slider {
            width: 100%;
            margin-bottom: 0px;
            .slick-list {
              box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
              // width: 480px;
              // width: 525px;
              // margin: 16px 20px 10px 20px;
              margin: 5px 0px;
              border-radius: 10px;
              border: 1px solid #c9c9c9;
              .slick-track {
                display: flex;
                justify-content: space-between;
                .slick-slide {
                  // width: auto!important;
                  padding-right: 0px;
                  width: 100% !important;
                  .envira-icon {
                    height: 20px!important;
                    width: 20px!important;
                  }
                }
              }
            }
            .slick-prev {
              left: -35px;
            }
            .slick-next {
              right: -15px;
            }
          }
          .prev {
            left: -30px;
          }
          .next {
            right: -30px;
          }
          .new-icon {
            position: absolute;
            left: -20px;
            top: -10px;
            .new-pack-icon {
              height: 50px;
            }
          }
        }
        .slider-filter-1-4 {
          @extend .slider-filter-1;
          .slick-slide {
            width: auto!important;
            padding-right: 0px;
            // width: 130px!important;
          }
        }
        .slider-filter-2 {
          position: relative;
          box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          border-radius: 10px;
          border: 1px solid #c9c9c9;
          .ant-btn {
            :hover {
              color: #1b62ab;
              border-color: #1b62ab;
            }
          }
          .slick-slider {
            margin-bottom: 0px;
            .slick-list {
              // width: 480px;
              // width: 370px;
              width: 100%;
              // margin: 16px 30px 10px 20px;
              // margin-left: auto;
              .slick-track {
                .slick-slide {
                  // margin-top: 10px;
                  // margin-bottom: 10px;
                  // width: 100px !important;
                  // padding-right: 14px;
                  // margin: 10px;
                  // New changes
                  height: 55px;
                  padding-right: 0px;
                  .ant-btn {
                    width: 70%;
                    height: 55px;
                    border: none;
                    padding: 0px 10px;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    display: inline-flex;
                  }
                }
              }
            }
            .slick-prev {
              position: absolute;
              top: 25px;
              left: -10px;
            }
            .slick-next {
              position: absolute;
              top: 25px;
              right: 10px;
            }
          }
          .prev {
            position: absolute;
            // left: -50px;
            left: -30px;
            top: 15px;
            border: 0px;
            background-color: transparent;
            // margin-left: -145px;
          }
          .next {
            position: absolute;
            // right: 20px;
            right: 15px;
            top: 15px;
            border: 0px;
            background-color: transparent;
          }
        }
      }
    }
    .bfc-post-list-container {
      position: relative;
      min-height: 300px;

      .panel__refresh {
        max-height: calc(100vh - 120px);
      }
      .infinite-scroll-component__outerdiv {
        .infinite-scroll-component {
          overflow: unset !important;
        }
      }
    }
    .right-side-banner-link {
      cursor: pointer;
    }
    .social-feed-banner {
      position: fixed;
      width: 220px;
      box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.11);
      .banner-edit-icon {
        position: absolute;
        padding: 4px;
        background-color: var(--unnamed-color-ffffff);
        right: 1px;
        top: 1px;
        border-radius: 2px 20px 2px 2px;
      }
    }
    margin-top: 10px;
    .social-feed-sticky {
      position: sticky;
      top: 60px;
      z-index: 1;
    }
    .bfc-postBox {
      // background-color:white;
      display: 'flex';
      border-radius: 10px;
      // border: 1px solid grey;
      // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0000001a;
      border: 1px solid #c9c9c9;
      opacity: 1;

      .bfc-editor-trigger {
        height: 100%;

        .placehoder-wrapper {
          width: 100%;
          align-self: center;
          border: 1px solid #dbdbdb;
          border-radius: 34px;
          height: 40px;
          display: flex;
          align-items: center;
          padding: 10px 20px;
          margin-right: 20px;
          background: var(--unnamed-color-f2f2f2);
          cursor: pointer;
          color: var(--unnamed-color-707070);
          font-size: var(--fs-base__two)!important;
          &:hover {
            background: var(--unnamed-color-ccc);
            // TODO : add auto prefixer
            -webkit-transition: background-color 1000ms linear;
            -ms-transition: background-color 1000ms linear;
            transition: background-color 300ms linear;
          }
        }
      }
    }

    .bfc-modal {
      text-align: left;
      padding-left: 30%;
    }

    .bfc-feedAccountIcon {
      // margin-left: 4%;
      // margin-top: 2%;
      // margin-right: 2%;
      // height: 45px;
      margin-left: 25px;
      margin-top: 15px;
      margin-right: 1%;
      height: 45px;
      width: auto;
      > span {
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      .ant-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-title {
            width: 40px;
            height: 40px!important;
            border-radius: 25px;
            display: table-cell;
          }
        }
      }
    }
    // All skeleton here
    .bfc-feedAccountIcon-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          border-radius: 50px;
          height: 50px!important;
          bottom: auto !important;
          top: 5px !important;
          width: 50px !important;
          left: 10px;
          object-fit: cover;
          position: absolute;
        }
      }
    }
    .bfc-feed__profile-info-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          width: 38%;
          left: 70px;
          position: absolute;
          height: 20px!important;
          margin-top: 5px!important;
        }
        .ant-skeleton-paragraph {
          margin-top: 30px;
          margin-bottom: 15px;
          margin-left: -3px;
          li {
            margin-left: 59px;
            margin-top: 0px;
            margin-bottom: 5px;
            height: 15px;
            width: 70%;
          }
          li:last-child {
            display: none;
          }
        }
      }
    }
    .bfc-feed__caption-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          width: 94%;
          left: 15px;
          position: absolute;
          height: 20px!important;
          margin-top: 5px!important;
        }
        .ant-skeleton-paragraph {
          margin-top: 30px;
          margin-bottom: 15px;
          li {
            margin-left: 14px;
            margin-top: 0px;
            margin-bottom: 5px;
            height: 15px;
            width: 95%;
          }
          li:last-child {
            display: none;
          }
        }
      }
    }
    .bfc-feed-carousal-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 350px !important;
          width: 100%!important;
          border-radius: 0;
        }
      }
    }
    .bfc-feed-pdf-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 700px !important;
          width: 100%!important;
          border-radius: 0;
        }
      }
    }
    .bfc-feed-likecount-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          width: 38%;
          left: 15%;
          height: 20px!important;
          margin-bottom: 0!important;
        }
      }
    }
    .bfc-feed-commentcount-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          width: 38%;
          left: 15%;
          height: 20px!important;
          margin-bottom: 0!important;
          float: right;
        }
      }
    }
    // End skeleton
    .bfc-feed__profile-info {
      margin-top: 10px;
      margin-bottom: 0px !important;
      .bfc-post-name {
        color: var(--unnamed-color-1b62ab);
        font-size: var(--fs-base__two);
      }
      .bfc-post-datetime {
        font-size: var(--fs-base__four);
      }
      .ant-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-paragraph {
            li {
              height: 20px!important;
            }
            // li:last-child {
            //   display: none;
            // }
          }
        }
      }
    }
    .bfc-postbox-user-info {
      padding: 10px;
      .postbox-user-info {
        width: 45px;
        height: 45px;
        border-radius: 22px;
        object-fit: cover;
      }
    }

    .bfc-postbox-editor {
      // padding-left: 10px;
      .md-RichEditor-root {
        padding: 5px;
      }
      .md-side-toolbar {
        right: 0;
        left: auto;
      }

      .editor-box {
      }
    }
    .bfc-postBoxCameraIcon {
      // position: absolute;
      // left : 85%;
      // margin-top: 1%;
      color: var(--unnamed-color-1b62ab);
    }

    .bfc-ownProfileCameraIcon {
      position: relative;
      left: -10px;
      top: -5px;
      height: 10px;
      width: 20px;
      color: var(--unnamed-color-ffffff);
    }

    .bfc-ownProfilePicCameraIcon {
      //  position: absolute;
      //  left: 10px;
      //  top: 10px;
      // height: 10px;
      // width: 20px;
      //

      position: absolute;
      z-index: 999;
      top: 70%;
      // bottom: 10%;
      left: 7%;
      width: 20%;
      height: 40%;
      color: var(--unnamed-color-ffffff);
    }

    .bfc-postBoxPostButton {
      color: var(--unnamed-color-ffffff);
      margin-bottom: 2%;
      margin-left: 83%;
      width: 13%;
      height: 25px;
      // background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
    }

    .bfc-category {
      // margin-top: 0.5%;
      // margin-bottom: 1%;
    }

    .bfc-fields {
      background-color: var(--unnamed-color-f2f2f2);
      width: calc((80%)/5);
      border: 1px solid var(--unnamed-color-707070);
      // border-radius: 30px;
      height: 70px;
      color: var(--unnamed-color-707070);
      text-align: center;
      margin: 2%;
      // background: transparent url('img/images (3).png') 0% 0% no-repeat padding-box;
      background: transparent 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 9px #0000001a;
      border-radius: 20px;
      opacity: 1;
    }

    .bfc-feed {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      color: var(--unnamed-color-707070);
      margin-top: 2%;
      box-shadow: 0px 0px 10px #0000001a;

      .bfc-feed-carousal {
        // height: 100px;
        .basic-carsousel-img {
          width: 100%;
          height: 300px;
          object-fit: contain;
          align-self: center;
          margin-top: 0px;
          margin-bottom: 15px;
        }
        .slick-slider {
          margin-bottom: 0px;
          width: calc(100% + 0px);
          ::before {
            top: calc(50% - 50px);
            line-height: 50px;
            right: 0px;
            border-radius: 5px;
            text-shadow: 0px 0px 4px black;
            font-size: 52px !important;
          }
          .slick-slide {
            padding-right: 0px;
          }
          .slick-arrow.slick-prev:before {
            left: 15px;
          }
        }
      }

      .bfc-post-pdf {
        padding-bottom: 0;
        .react-pdf__Document {
          position: relative;
          height: 400px !important;
          .react-pdf__Page {
            position: absolute!important;
            max-height: 400px;
            width: 100%;
            .react-pdf__Page__canvas {
              width: 100% !important;
              height: 750px !important;
              object-fit: fill;
              border: 1px solid #f7f7f7;
              border-radius: 5px;
            }
            .prevPage {
              position: absolute !important;
              z-index: 100;
            }
            .react-pdf__Page__textContent{
              display: none;
              span{
                display: none;
              }
            }
          }
        }
        .bfc-pdf-actions {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 365px;
          margin-bottom: 10px;
          .p-btn {
            border: none;
            background-color: transparent;
            color: var(--unnamed-color-1b62ab);
            cursor: pointer;
            padding: 3px 5px;
            &:hover{
              background-color: var(--unnamed-color-f2f2f2);
              border-radius: 25px;
              padding: 3px 5px;
            }
          }
          .n-btn {
            @extend .p-btn;
          }
          p {
            text-align: center;
            // box-shadow: 0 0px 5px 0px rgba(0, 0, 0, .1) !important;
            // padding: 5px 15px;
            border-radius: 5px;
            margin-bottom: 0px;
            font-size: var(--fs-base__three);
          }
        }
      }
    }

    .bfc-uploadImage {
      height: 200px;
      width: 80%;
    }

    .bfc-feedInfo {
      // padding-left: 3%;
      // padding-right: 3%;
      // color: var(--unnamed-color-707070);
      padding: 15px;
      font: normal normal normal 18px/24px Segoe UI;
      letter-spacing: 0px;
      color: var(--unnamed-color-707070);
      p {
        font-size: var(--fs-base__two);
      }
      .codex-editor {
        .codex-editor__redactor {
          padding-bottom: 0px !important;
        }
      }
    }

    .bfc-feed-img {
      margin-left: 8%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .bfc-socialfeedAction {
      // background-color: var(--unnamed-color-ffffff);
      margin-left: 15px;
      margin-right: 15px;
      .actions-section {
        .bfc-feed-action-skeleton {
          .ant-skeleton-content {
            .ant-skeleton-title {
              width: 80%!important;
              height: 20px!important;
              border-radius: 0;
            }
          }
        }
      }
      .bfc-feed-action-dropdown-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-title {
            width: 80%!important;
            height: 20px!important;
            border-radius: 0;
          }
        }
      }
    }
    .post-comments-wrapper {
      .post-comment {
        .comments-section-skeleton {
          background-color: rgb(240, 242, 245);
          border-radius: 0px 10px 10px;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 5px 0px;
          padding: 10px;
          margin-left: 0px;
          .bfc-user-thumbnail-skeleton {
            .ant-skeleton-content {
              .ant-skeleton-paragraph {
                li:nth-child(2) {
                  margin-top: 5px;
                }
                li:last-child {
                  margin-top: 5px!important;
                }
              }
            }
          }
        }
      }
    }

    .bfc-actionWidth {
      margin-top: 1%;
      margin-bottom: 1%;
      width: calc((100%)/3);
      text-align: center;
    }

    .bfc-LikeButton {
      background-color: var(--unnamed-color-ffffff);
      border: 0px solid #dbdbdb;
      color: var(--unnamed-color-ccc);
    }

    .bfc-LikeButtonLiked {
      background-color: var(--unnamed-color-ffffff);
      border: 0px solid #dbdbdb;
      color: var(--unnamed-color-1b62ab);
      font-weight: bold;
    }

    .bfc-action {
      // width: 14.8vw;
      // width: 100%;
      // border: 1px solid grey;
      // height: 35px;
      color: var(--unnamed-color-707070);
      text-align: center;
      font-size: var(--fs-base__two);

      // added
      padding: 4px 10px 0px 10px;
      // &:hover {
      //   background-color: #f0f2f5;
      //   border-radius: 3px;
      // }
    }

    .bfc-socialfeedCommentBox {
      width: 95%;
      height: 30px;
      margin-top: 1%;
      margin-left: 3%;
      color: var(--unnamed-color-ccc);
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    }

    .bfc-socialfeedUserComments {
      margin-left: 1%;
    }

    .bfc-socialfeedMoreComments {
      text-align: center;
      text-decoration-line: underline;
    }

    .bfc-socialfeed-offer {
      background-color: var(--unnamed-color-ffffff);
      border: 1px solid var(--unnamed-color-707070);
      border-radius: 10px;
      margin-top: 2%;
      padding-top: 2%;
    }

    .bfc-offerBanner {
      background-color: var(--unnamed-color-ffffff);
      border: 1px solid #e0e0e0;
      text-align: center;
      height: 220px;
      font-size: 1.875rem;
      padding-top: 6%;
      margin-bottom: 2%;
    }

    .bfc-socialfeedStats {
      border: 1px solid #e1e1e1;
      margin-bottom: 2%;
    }

    .bfc-cardMember {
      border-radius: 10px;
      border: 1px solid #c9c9c9;
      opacity: 1;
    }

    .bfc-cardPadding {
      padding-left: 3%;
      padding-right: 2%;
    }

    .bfc-type {
      background-color: var(--unnamed-color-ffffff);
      margin-bottom: 2%;
      margin-top: 2%;
      width: 300px;
    }

    .bfc-suggestions-heading {
      padding-top: 8%;
      padding-bottom: 10%;
      font-size: var(--fs-base__one);
      text-align: center;
      background: var(--unnamed-color-f2f2f2) 0% 0% no-repeat padding-box;
      border-radius: 10px 10px 0px 0px;
      opacity: 1;
      color: var(--unnamed-color-1b62ab);
    }

    .bfc-socialfeedButton {
      color: var(--unnamed-color-ffffff);
      margin-top: 2%;
      // font-size: 18;
      padding: 0px 10px 0px 10px;
      width: auto;
      height: 25px;
      // background: #1b62ab 0% 0% no-repeat padding-box;
      background-color: transparent !important;
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 10%;
    }

    .bfc-viewMore {
      background-color: var(--unnamed-color-ccc);
      border: 1px solid var(--unnamed-color-ccc);
      color: var(--unnamed-color-ffffff);
      border-radius: 25px;
      margin-top: 2%;
      margin-bottom: 2%;
      margin-left: 52%;
      font-size: var(--fs-base__one);
      width: 85px;
      height: 25px;
    }

    .bfc-socialfeedMoreIcon {
      margin-top: 2%;
      position: absolute;
      right: 2%;
    }

    .bfc-whats-happening-text {
      font-size: 90%;
    }

    .bfc-sidebar-container {
      // background-color:white;
      border: 1px solid var(--unnamed-color-ccc);
      width: 270px;
      // height: 400px;
      height: 100%;
      margin-bottom: 10%;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 20px #0000001a;
      border-radius: 10px;
      opacity: 1;
      .ant-collapse {
        .ant-collapse-item {
          .ant-collapse-header {
            font-weight: 700;
            .ant-collapse-arrow {
              right: 0;
              position: absolute;
              top: 15px;
            }
          }
        }
      }
    }

    .bfc-sidebar-heading {
      // margin-left: 10%;
      // margin-right: 10%;
      text-align: center;
      // padding-top: 10%;
    }

    .bfc-sidebar-fields {
      // margin: 10%;
    }

    .bfc-sidebarIcon {
      height: 100px;
      width: 100px;
    }

    .bfc-sidebar-titles {
      margin-top: 40px;
    }

    .bfc-sidebar-hr {
      width: 85%;
    }

    .bfc-postBoxSearch {
      width: 70%;
      height: 34px;
      border-radius: 18px;
      border: 1px solid #dbdbdb;
      font-size: .5rem;
      line-height: 1.33;
      transition: 0.3s;
      margin-left: 2.5%;

      //TODO : review
      // @include directify($directions) {
      //   #{directed('padding-left')}: 15px;
      //   #{directed('padding-right')}: 65px;
      // }

      color: var(--unnamed-color-707070);

      &:focus,
      &:hover,
      &:active {
        //TODO : reveiw
        border-color: var(--unnamed-color-4ce1b6);
        outline: none;
        box-shadow: none;
      }
    }

    .bfc-userProfile-name {
      color: red;
      opacity: 1;
    }

    .bfc-userProfileImage {
      width: 25px;
      min-width: 25px;
      height: 25px;
      overflow: hidden;
      border-radius: 50%;

      //TODO : review
      // @include directify($directions) {
      //   #{directed('margin-right')}: 10px;
      // }

      img {
        height: 100%;
        min-width: 100%;
      }
    }

    .bfc-socialfeed-categoryImage {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      position: relative;
      // z-index: -1;
    }

    .bfc-socialfeed-categoryName {
      position: relative;
    }

    .bfc-socialfeed-categoryText {
      position: absolute;
      left: 20%;
      bottom: 5%;
      color: var(--unnamed-color-ffffff);
    }

    h4 {
      margin-top: 30px;
      text-align: center;
    }

    h4.no-span {
      display: table;
      color: var(--unnamed-color-1b62ab);
      margin-top: 2%;
      width: 96%;
      margin-left: 2%;
      &:before,
      &:after {
        border-top: 1px solid var(--unnamed-color-1b62ab);
        margin: 0 auto;
        content: '';
        display: table-cell;
        position: relative;
        top: 0.5em;
        width: 50%;
      }
      &:before {
        right: 1.5%;
      }
      &:after {
        left: 1.5%;
      }
    }

    .bfc-sidebar-image {
      border-radius: 10px 10px 0px 0px;
      position: relative;
      //  z-index: -1;
    }

    .bfc-sidebar-profileText {
      color: var(--unnamed-color-ffffff);
    }

    .bfc-sidebar-profileName {
      position: absolute;
      top: 10%;
      color: var(--unnamed-color-ffffff);
      left: 38%;
      text-align: left;
      font-size: var(--fs-base__four);
    }

    .bfc-socialfeed-profilePicture {
      position: absolute;
      top: 13%;
      left: 10%;
      width: 20%;
      height: 12%;
      border-radius: 50%;
    }

    .bfc-moreVertIcon {
      padding-left: 0px !important;
      padding-right: 0px;
      padding-top: 6px;
      width: auto;
      height: 30px;
      //  background: #F4F7F6 0% 0% no-repeat padding-box;
    }

    .bfc-socialfeedDropdownButton {
      background-color: transparent !important;
      border: 0px solid #dbdbdb;
      color: var(--unnamed-color-707070);
    }

    .bfc-collapse-content {
      width: 120px;
      // z-index: 10;
    }

    .bfc-userProfileFollowButton {
      color: var(--unnamed-color-ffffff);
      margin-bottom: 2%;
      width: auto;
      height: 25px;
      background: var(--unnamed-color-1b62ab) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 3%;
    }

    .bfc-eventsMember {
      width: 90px;
      min-width: 25px;
      height: 50px;
      overflow: hidden;
      border: 1px solid #ffffff;
      border-radius: 10px;
      opacity: 1;

      //TODO : review
      // @include directify($directions) {
      //   #{directed('margin-right')}: 10px;
      // }

      img {
        height: 100%;
        min-width: 100%;
      }
    }

    .bfc-eventHeading {
      color: var(--unnamed-color-1b62ab);
      margin-bottom: 2%;
      padding: 0px 5px 0px 5px;
      border: 1px solid var(--unnamed-color-1b62ab);
      border-radius: 5px;
      opacity: 1;
    }

    .btn.btn-outline-secondary,
    .btn.btn-outline-secondary p {
      font-size: .625rem;
    }

    .topbar__collapse-content {
      right: 10px;
    }

    .bfc-comment-content {
      .topbar__collapse-item {
        &:hover {
          background-color: transparent;
        }
      }
    }

    .MuiAutocomplete-option {
      font-size: var(--fs-base__four);
      color: var(--unnamed-color-1b62ab);
    }

    .MuiAutocomplete-popper {
      font-size: 12rem;
    }

    .blurred-bg-container .content h1 {
      margin: 0;
    }

    .blurred-bg-container .text {
      // padding: 10px 0px 0px 0px;
      height: 100%;
      box-sizing: border-box; /* so that padding is included in height */
      position: relative;
      border-top: 1px solid var(--unnamed-color-ffffff);
      // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      opacity: 1;
      backdrop-filter: blur(9px);
      -webkit-backdrop-filter: blur(9px);
      .profile-tab__action {
        margin: 10px;
        display: flex;
        justify-content: space-between;
        .quality-profile__link {
          display: flex;
          align-items: center;
          margin-right: 5px;
          font-size: var(--fs-base__two);
          color: #28bdc2;
          text-decoration: underline;
          text-align: right;
          margin-top: 15px;
          margin-right: 10px;
        }
      }
    }
    .blurred-bg-container .follow-icon {
      // border-radius: 25px;
      // border: 2px solid #707070;
      // padding: 10px;
      cursor: pointer;
      // &:hover {
      //   background-color: #c8d3de;
      //   color: white;
      // }
    }

    // .bfc-otherUsersFollowButton{
    //   color: var(--unnamed-color-ffffff);
    //   padding: 0px 10px 0px 10px;
    //   width: auto;
    //   height: 25px;
    //   background: #1B62AB 0% 0% no-repeat padding-box;
    //   border: 2px solid #FFFFFF;
    //   border-radius: 10px;
    //   opacity: 1;
    //   position: absolute;
    //   right:8%;
    //   top: 10%;
    // }

    .bfc-ownUsersEditCoverPicture {
      color: var(--unnamed-color-ffffff);
      padding: 0px 10px 0px 30px;
      width: 150px;
      height: 30px;
      background-color: transparent;
      // background: #1B62AB 0% 0% no-repeat padding-box;
      border: 2px solid var(--unnamed-color-ffffff);
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 2%;
      top: 10%;
    }

    .bfc-ownUsersEditProfile {
      color: var(--unnamed-color-ffffff);
      padding: 0px 10px 0px 10px;
      width: auto;
      height: 25px;
      background: var(--unnamed-color-707070) 0% 0% no-repeat padding-box;
      border: 2px solid var(--unnamed-color-ffffff);
      border-radius: 10px;
      opacity: 1;
      position: absolute;
      right: 2%;
      top: 45%;
    }

    .bfc-otherUserMoreIcon {
      top: 10%;
      position: absolute;
      right: 2%;
      color: var(--unnamed-color-ffffff);
    }

    .bfc-ProfilePicInfo {
      padding-left: 30%;
    }

    .bfc-ProfilePicActions {
      position: relative;
      top: 8%;
      padding-top: 3px;
      opacity: 1;
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
      background: #ffffff40 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0000001a;
      opacity: 1;
    }

    .public-DraftStyleDefault-ltr {
      // width: 20vh;
      border-radius: 18px;
    }

    .public-DraftStyleDefault-block {
      margin: 0px;
    }

    .bfc-socialfeedProfile-image {
      height: 100%;
      border-radius: 10px 10px 0px 0px;
    }

    .bfc-socialfeed-blurred-bg-container {
      background: url(../../images/placeholders/farm.jpg);
      height: 132px;
      background-size: cover;
    }

    .bfc-socialfeed-blurred-bg-container .content {
      width: 88%;
      height: 11%;
      position: absolute;
      top: 26%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }

    .bfc-socialfeed-blurred-bg-container .text {
      // padding: 10px 0px 0px 0px;
      height: 100%;
      box-sizing: border-box; /* so that padding is included in height */
      position: relative;
      border-top: 1px solid var(--unnamed-color-ffffff);
      // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      opacity: 1;
      backdrop-filter: blur(9px);
      -webkit-backdrop-filter: blur(9px);
    }

    .bfc-socialfeed-profilePicture {
      position: absolute;
      z-index: 1;
      top: 16%;
      left: 12%;
      width: 20%;
      height: 13%;
      border-radius: 50%;
      border: 2px solid var(--unnamed-color-ffffff);
    }

    .bfc-socialfeed-post-camera {
      position: absolute;
      right: 25%;
    }

    ////temporary

    .btn {
      margin-right: 0px;
    }

    .text-editor .DraftEditor-root {
      border: solid 0px #f2f4f7;
    }

    .bfc-leftMargin {
      padding-left: 30px;
      // background-color: red;
      // width: 20px;
    }
    ////temporary
    .form input,
    .form textarea {
      border: 2px solid #f2f4f7;
      color: var(--unnamed-color-00bfa5);
    }

    .form input,
    .form textarea {
      border-radius: 0px;
      width: 60%;
    }

    .form__form-group-input-wrap {
      width: 60%;
    }

    .bfc-form-divider {
      background-color: #f4f7f6;
      width: 150%;
      margin-bottom: 20px;
      margin-left: -15%;
      margin-right: -15%;
      padding-left: 10%;
    }

    .bfc-button-next {
      width: 240px;
      height: 38px;
    }

    .bfc-button-anotherAddress {
      width: auto;
      height: 30px;
      background-color: var(--unnamed-color-ffffff);
      margin-left: 75%;
      font-size: var(--fs-base__four);
      padding: 5px;
    }

    .bfc-profile-form-HorizontalLIne {
      width: 70%;
    }

    .bfc-profile-form-tabs {
      margin: 10px;
    }

    .bfc-form-group-label {
      margin-left: 40px;
      margin-top: 5px;
      width: 20%;
      color: var(--unnamed-color-00bfa5);
    }

    .bfc-form-group-label1 {
      width: 60%;
      margin-left: 15px;
    }

    .bfc-form-link {
      margin-left: 40px;
      text-decoration: underline;
    }
  }
}

.bfc-socialfeed-container .banner-box {
  height: auto !important;
}
.bfc-socialfeed-container {
  .about-tab-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title {
        height: 500px!important;
      }
    }
  }
}
.profile-banner {
  .banner-box {
    // background-color: var(--unnamed-color-1b62ab);
    background-size: cover;
    height: 300px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    .banner-pic-area {
      height: 220px;
      width: 100%;
      position: relative;
      padding: 0;
      overflow: hidden;
      border-radius: 12px 12px 0 0;
      .banner-pic {
        width: 100%;
        height: 100%;
        object-fit: cover;
        .ant-skeleton-content {
          .ant-skeleton-title {
            height: 220px!important;
            margin-top: 0px!important;
          }
        }
      }
    }
    .bfc-follow-btn {
      position: absolute;
      right: 0 !important;
      padding: 0px;
      margin-right: 15px;
      &.follow {
        button {
          margin-bottom: 0;
          color: var(--unnamed-color-1b62ab);
          border-color: var(--unnamed-color-1b62ab);
          background: transparent !important;
        }
      }

      &.unfollow {
        button {
          margin-bottom: 0;
          background: var(--unnamed-color-1b62ab) !important;
          color: var(--unnamed-color-ffffff)!important;
        }
      }
    }
  }
}
.blurred-bg-container.bfc-otherUsersProfile-image {
  margin-top: 200px;
  position: relative;
  .bfc-otherUsers-profilePicture {
    bottom: auto !important;
    top: -50px !important;
    width: 100px !important;
    height: 100px;
    bottom: 40%;
    object-fit: cover;
    box-shadow: 0px 0px 10px 0px rgba(62, 28, 131, 0.1);
    .ant-skeleton-content {
      .ant-skeleton-title {
        border-radius: 50px;
        height: 100px!important;
        bottom: auto !important;
        top: -15px !important;
        width: 100px !important;
        left: -5px;
        bottom: 40%;
        object-fit: cover;
        position: absolute;
      }
    }
  }
  .content {
    .text {
      .bfc-ProfilePicInfo-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-title {
            width: 38%;
            left: 25%;
            height: 15px!important;
            position: absolute;
            margin: 5px 0px!important;
          }
          .ant-skeleton-paragraph {
            margin-top: 30px;
            margin-bottom: 15px;
            li {
              margin-top: 5px;
              height: 15px;
              width: 60%;
              margin-left: 25%;
            }
            li:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
  .bfc-otherUsers-profilePicture__rating {
    display: grid;
    text-align: center;
    margin-top: -5px!important;
    // display: flex;
    // background-color: var(--unnamed-color-1b62ab);
    // border: 3px solid #1b62ab;
    align-items: center;
    gap: 0px;
    padding: 5px 5px;
    // margin-top: 3px;
    border-radius: 10px;
    color: var(--unnamed-color-ffffff);
    // margin-top: 10px;
    // margin-left: 10px;
    // position: absolute;
    max-width: 100%;
    z-index: 1;
    left: 5%;
    box-shadow: none;
    height: auto;
    .rating-count-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          width: 20px;
          height: 17px !important;
          margin-top: 5px !important;
          margin-left: 15px;
          margin-bottom: 5px!important;
        }
      }
    }
    .rating-span-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 15px !important;
          margin-top: 0px !important;
          width: 50px;
          margin-bottom: 0px!important;
        }
      }
    }
  }
  .verification__follow-count {
    margin-top: 5px;
    .bfc-otherUsers-profilePicture__verification {
      @extend .bfc-otherUsers-profilePicture__rating;
      background-color: white;
      border: none;
      cursor: pointer;
      padding: 7px 5px;
      // padding: 4px 5px;
      margin-top: 0px;
    }
  }
}
.theme-light.ltr-support .at-css-box .blurred-bg-container .content {
  position: relative !important;
  bottom: auto !important;
}
.theme-light.ltr-support .at-css-box .bfc-ProfilePicInfo {
  padding-right: 10px !important;
  height: 100px;   //Don't remove it
  padding-left: 24% !important;
  h3 {
    color: var(--unnamed-color-707070)!important;
    // color: #000000 !important;
    // width: 60%;
    width: 100%;
    font-size: var(--fs-base__zero);
  }
  h5 {
    color: var(--unnamed-color-707070);
    font-weight: 100;
    margin-top: -5px;
    font-size: var(--fs-base__three);
  }
  .profile-headline {
    color: var(--unnamed-color-707070);
    .headline-btn {
      padding: 5px 10px!important;
      font-weight: 100!important;
      column-gap: 5px;
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
      }
    }
  }
}
.profile-follow-connects {
  display: flex;
  .connect-follow_buttons {
    .connect-skeleton {
      margin-right: 15px;
      width: auto!important;
      .ant-skeleton-content {
        .ant-skeleton-title {
          border-radius: 50px;
          height: 40px!important;
          width: 40px !important;
          object-fit: cover;
          margin: 0px!important;
        }
      }
    }
    .connect-follow-skeleton {
      width: auto;
      .ant-skeleton-content {
        .ant-skeleton-title {
          border-radius: 50px;
          height: 40px!important;
          width: 40px !important;
          object-fit: cover;
          margin: 0px!important;
        }
      }
    }
  }
}
.follow-section {
  padding: 5px;
  margin-right: 25px;
  .follow-count {
    color: var(--unnamed-color-1b62ab);
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: center;
    font-size: var(--fs-base__two);
  }
  span{
    font-size: var(--fs-base__three);
    color: var(--unnamed-color-000);
  }
  .follow-count-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title {
        width: 20px;
        height: 17px !important;
        margin-top: 0px !important;
        margin-left: 15px;
        margin-bottom: 5px!important;
      }
    }
  }
  .span-skeleton {
    .ant-skeleton-content {
      .ant-skeleton-title {
        height: 15px !important;
        margin-top: 0px !important;
        width: 50px;
        margin-bottom: 0px!important;
      }
    }
  }
}
.bfc-ProfilePicInfo + div {
  h3 {
    font-size: var(--fs-base__two);
    // padding: 10px 0px 10px 10px;
  }
  svg {
    width: 20px;
  }
}

// this is 100% zoom
@media screen and (max-width: 1900px) {
  .socialfeed-container {
    max-width: 1150px;
  }
  .first-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .second-col {
    flex: 0 0 56%;
    max-width: 56%;
  }
  .third-col {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

// this is 90% zoom
@media screen and (min-width: 1600px) {
  .socialfeed-container {
    max-width: 1250px;
  }
  .first-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .second-col {
    flex: 0 0 57%;
    max-width: 57%;
  }
  .third-col {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
// @media screen and (min-width: 1199px) {
//   .profile-follow-connects {
//     flex-wrap: wrap;
//     justify-content: space-between;
//     grid-gap: 15px;
//     gap: 15px;
//     width: 100%;
//     .follow-area {
//       justify-content: space-between;
//       width: 100%;
//     }
//     .connect-follow_buttons {
//       margin-left: 20px!important;
//     }
//   }
// }
@media screen and (min-width: 1199px) and (max-width: 1366px) {
  .bfc-social-feed-wrapper {
    .bfc-social-postbox {
      .box__sticky {
        // top: 110px !important;
        top: 61px !important;
        .slider-filter-2 {
          .slick-slider {
            .slick-list {
              // width: 370px;
              width: 350px!important;
              // width: 100%!important;
              .slick-slide {
                .ant-btn {
                  background-color: transparent;
                  width: 80%!important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  display: inline-flex;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .bfc-social-feed-wrapper {
    .bfc-social-postbox {
      .box__sticky {
        // top: 110px !important;
        top: 61px !important;
        .slider-filter-2 {
          .slick-slider {
            .slick-list {
              // width: 370px;
              width: 400px!important;
              // width: 100%!important;
              .slick-slide {
                .ant-btn {
                  background-color: transparent;
                  width: 80%!important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  display: inline-flex;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .bfc-otherUsers-profilePicture__rating {
    // padding: 8px 5px!important;
    padding: 0px 5px!important;
  }
  .verification__follow-count {
    // margin-top: -7px!important;
    .bfc-otherUsers-profilePicture__verification {
      margin-top: 2px;
    }
  }
}
@media screen and (max-width: 991px) {
  .bfc-social-feed-wrapper {
    .bfc-social-postbox {
      .box__sticky {
        .slider-filter-2 {
          .slick-slider {
            .slick-list {
              // width: 250px;
              .slick-slide {
                .ant-btn {
                  background-color: transparent;
                  width: 80%!important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  display: inline-flex;
                }
              }
            }
          }
          .prev {
            left: -30px!important;
          }
        }
      }
    }
  }
  .deals-search-content {
    .row-field {
      margin-bottom: 15px;
    }
  }
  // .profile-follow-connects {
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  //   grid-gap: 15px;
  //   gap: 15px;
  //   width: 100%;
  //   .follow-area {
  //     justify-content: space-between;
  //     width: 100%;
  //   }
  //   .connect-follow_buttons {
  //     margin-left: 20px!important;
  //   }
  // }
}
@media screen and (max-width: 769px) {
  .bfc-social-feed-wrapper {
    .bfc-social-postbox {
      .box__sticky {
        top: 110px !important;
        .slider-filter-2 {
          .slick-slider {
            .slick-list {
              // width: 370px;
              // width: 100%!important;
              .slick-slide {
                .ant-btn {
                  background-color: transparent;
                  width: 80%!important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  display: inline-flex;
                }
              }
            }
          }
          .prev {
            // left: -10px!important;

          }
          .next {
            // right: 20px;
          }
        }
      }
    }
  }
  .post-actions-wrapper {
    .bfc-socialfeedAction {
      .actions-section {
        display: flex;
        white-space: nowrap;
        justify-content: space-between;
        flex-flow: wrap;
        .bfc-action {
          line-height: 1;
          padding: 0px;
          height: auto;
          white-space: nowrap;
          display: contents;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .bfc-socialfeed-container {
    .profile-banner {
      .content {
        .text {
          .profile-follow-connects {
            display: block;
            text-align: center;
            align-items: center;
            .follow-area {
              justify-content: center;
            }
            .bfc-follow-btn {
              justify-content: center;
              position: relative;
              margin-right: 0;
              margin-top: 10px;
            }
          }
          .connect-follow_buttons {
            margin-top: 10px !important;
            margin-right: 0px !important;
            margin-bottom: 10px;
            justify-content: center!important;
          }
        }
      }
    }
    .post-actions-wrapper {
      .bfc-socialfeedAction {
        .action-section {
          display: block;
        }
      }
    }
  }
  .box__sticky {
    .slider-filter-1 {
      .prev {
        left: 0px;
        z-index: 1;
      }
      .next {
        right: 0px;
      }
    }
  }
}
@media screen and (min-width: 480px) {
  .bfc-socialfeed-container {
    .bfc-post-list-container {
      .bfc-feed {
        .bfc-feedInfo {
          .transctional__card-list {
            .row {
              display: flex;
              flex-flow: nowrap;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .socialfeed-container {
    padding-left: 0;
    padding-right: 0;
    .second-col {
      .container-fluid {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .post-actions-wrapper {
    .bfc-socialfeedAction {
      .actions-section {
        .bfc-action {
          padding: 0px !important;
        }
      }
    }
  }
  .bfc-socialfeed-container {
    .profile-banner {
      .content {
        .text {
          .bfc-ProfilePicInfo {
            padding-left: 33% !important;
          }
        }
      }
    }
  }
  .profile-tab__action {
    display: block !important;
    .quality-profile__link {
      justify-content: center;
      margin: 15px;
      margin-right: 0px !important;
    }
  }
}
@media screen and (min-width: 410px) and (max-width: 576px) {
  .socialfeed-container__row {
    margin: -10px !important;
  }
}
@media screen and (min-width: 410px) and (max-width: 576px) {
  .bfc-social-feed-wrapper {
    .bfc-social-postbox {
      .box__sticky {
        // top: 118px !important;
        top: 120px !important;
        .slider-filter-1{
          .prev {
            z-index: 5;
            left: 0px!important;
          }
          .next {
            right: 0px!important;
          }
        }
        .slider-filter-2 {
          .slick-slider {
            .slick-list {
              width: 100%!important;
              // max-width: 130px;
              .slick-slide {
                .ant-btn {
                  background-color: transparent;
                  width: 80%!important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
          .prev {
            left: -40px!important;
          }
          .next {
            right: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 410px) {
  .bfc-social-feed-wrapper {
    .bfc-social-postbox {
      .box__sticky {
        top: 110px !important;
        .slider-filter-2 {
          .slick-slider {
            .slick-list {
              width: 100%!important;
              // max-width: 130px;
              .slick-slide {
                .ant-btn {
                  background-color: transparent;
                  width: 80%!important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
          .prev {
            left: -30px!important;
          }
          .next {
            right: 15px!important;
          }
        }
      }
    }
  }
}
