.small-btn{
  padding: 5px 10px!important;
  font-size: 12px!important;
}
.btn {
  font-size: var(--fs-base__two);
  border-radius: 25px;
  @include Font-Semibold;
  margin: 0;
}
.btn.btn-primary {
  background-color: var(--unnamed-color-1b62ab);
  color: var(--unnamed-color-ffffff)!important;
  // border: 2px solid #1B62AB;
  &:before {
    background-color: var(--unnamed-color-ffffff);
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--unnamed-color-1b62ab)!important;
    border-color: var(--unnamed-color-1b62ab);
  }
}
.btn.btn-secondary {
  background-color: transparent;
  border: 1px solid var(--unnamed-color-1b62ab);
  color: var(--unnamed-color-1b62ab);
  &:before {
    background-color: var(--unnamed-color-1b62ab);
  }
  &:hover,
  &:focus,
  &:active {
    color: var(--unnamed-color-ffffff)!important;
    // border-color: var(--unnamed-color-1b62ab);
  }
}
.btn.btn-link {
  text-decoration: underline;
  color: var(--unnamed-color-1b62ab);
  &:before {
    background-color: transparent;
  }
  &:hover,
  &:focus,
  &:active {
    color: darken($color: #1b62ab, $amount: 10%);
  }
}
