.bfc-html {
  img {
    max-width: 100%;
    width: auto;
  }
  .bfc-modalform {
    background-color: var(--unnamed-color-ffffff);
    width: 528px;
    position: absolute;
    right: 0;
    top: -20px;
    box-shadow: -5px 0px 15px #00000033;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 7px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    .bfc-modalform-header {
      display: flex;
      justify-content: space-between;
      padding: 15px 15px 15px 30px;
      box-shadow: 0px 0px 10px #00000033;
      .icons-wrapper {
        display: flex;
        align-items: center;
        img {
          margin-right: 15px;
        }
        .title {
          @include rem(font-size, 30);
          @include Font-Bold;
          color: var(--unnamed-color-1b62ab);
        }
      }
      .close-wrapper {
        a {
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
    .modalform-body {
      padding: 40px 40px 20px;
      form {
        .form__form-group {
          margin-bottom: 20px;
          .form__form-group-field {
            display: flex;
            align-items: center;
            .extention {
              @include Font-Bold;
              margin-left: 7px;
              min-width: 30px;
              font-size: var(--fs-base__one);
            }
          }
        }

        .addanother-farm {
          margin: 0 -40px;
          padding: 10px 40px;
          background-color: var(--unnamed-color-ffffff);
          box-shadow: 0px 0px 10px #00000033;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            font-size: 1.5rem;
            color: var(--unnamed-color-1b62ab);
            font-family: var(--unnamed-font-segoeUI);
          }
          button {
            border-radius: 8px;
            font-size: var(--fs-base__two);
            padding: 7px 25px 8px;
            display: inline-block;
            box-shadow: 0px 0px 10px #0000001a;
            font-family: var(--unnamed-font-segoeUI);
            line-height: 1;
          }
        }

        .btn-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          border-top: 1px solid #e4e4e4;
          padding-top: 20px;
          .terms-block {
            position: relative;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            span {
              @include Font-Regular;
              color: var(--unnamed-color-afafaf);
              font-size: var(--fs-base__four);
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
