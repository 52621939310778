.equipment-information-container {
  width: 104%;
  display: flex;
  margin-bottom: 5px;
  gap: 10px;
}
.equipment-image {
  width: 50%;
}
.equipment-details {
  width: 50%;
}

.equipment-details-tractor,
.equipment-details-person {
  margin-bottom: 20px;
}

.equipment-details-tractor p,
.equipment-details-person p {
  margin-bottom: 10px;
}

.rental-id-container {
  margin-top: 10px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
}

.rental-id-label {
  width: 50%;
}
.rental-id-value {
  display: flex;
  gap: 10px;
  align-items: center;
}

.info-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.info-left-side {
  width: 45%;
  margin-right: 10px;
}

.info-right-side {
  width: 45%;
  margin-left: 10px;
}

.info-container p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #333;
}
